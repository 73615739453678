//popup-error success
.popup-success{
  background-color: #45ce75;
  display: table;
  position: absolute;
  z-index: 99999;
  width: 316px;
  height: auto;
  top: 0;
  right: 0;
  text-align: left;
  color: #fff;
  .icon {
    width: 18%;
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    background-color: #2eb268;
    padding: 22px 17px;
    img {
      width: 22px;
      float: left;
    }
  }
  .msg-container {
    display: table-cell;
    vertical-align: middle;
    width: 72%;
    padding: 17px;
    span{
      width: 200px;
      font-size: 16px;
      float: left;
    }
    .message {
      font-size: 12px;
      word-wrap: break-word;
      float: left;
    }
  }
  a{
    cursor: pointer;
    color: #e5e5e5;
    font-size: 18px;
    display: table-cell;
    width: 10%;
    vertical-align: middle;
    &:hover{
      color: #ffffff;
    }
  }
}
.popup-error{
  background-color: #ef4b4b;
  display: table;
  position: absolute;
  z-index: 99999;
  width: 456px;
  height: auto;
  top: 0;
  right: 0;
  text-align: left;
  color: #fff;
  .icon {
    width: 13%;
    display: table-cell;
    vertical-align: middle;
    height: 100%;
    background-color: #c93c2d;
    padding: 22px 17px;
    img {
      width: 22px;
      float: left;
    }
  }
  .msg-container {
    display: table-cell;
    vertical-align: middle;
    width: 72%;
    padding: 17px;
    span{
      width: 300px;
      font-size: 16px;
      float: left;
    }
    .message {
      font-size: 12px;
      word-wrap: break-word;
      float: left;
    }
  }
  a{
    cursor: pointer;
    color: #e5e5e5;
    font-size: 18px;
    display: table-cell;
    width: 10%;
    vertical-align: middle;
    &:hover{
      color: #ffffff;
    }
  }
}
