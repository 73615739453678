@mixin finance-status{
  display: inline-block;
  padding: 3px 6px;
  font-size: 12px;
  color: #ffffff ;
  float: left;
}

@mixin order-status{
  display: inline-block;
  padding: 4px 16px;
  font-size: 12px;
  color: #ffffff ;
  float: left;
}

.finance-default{
  @include finance-status;
  margin-left: 16px;
  min-width: 64px;
  text-align: center;
}

.order-default{
  @include order-status;
  margin-left: 16px;
  font-weight: 500;
  min-width: 64px;
  padding: 5px 6px;
  text-align: center;
}

.under-way{
  @include finance-status;
  background-color: $kb-color31;
  margin-left: 30px;
}
.already-finish{
  @include finance-status;
  background-color: $kb-color32;
  margin-left: 30px;
}
.balance-due{
  @include finance-status;
  background-color: $kb-color33;
  margin-left: 30px;
}
.send-yet{
  @include finance-status;
  background-color: #73bb4b;
  margin-left: 16px;
  color: #ffffff !important;
}
.not-send{
  @include finance-status;
  background-color: #dd5a55;
  margin-left: 16px;
  color: #ffffff !important;
}
.finance-detail{
  margin-bottom: 60px;
  &:last-child{
    margin-top: 60px;
    margin-bottom: 0;
  }
  header{
    font-size: 16px;
    color: #666666;
    font-weight: 500;
  }
  textarea{
    margin-top: 15px;
    width: 100%;
    height: 120px;
    padding: 10px;
    border: 1px solid $kb-color3;
    &:focus{
      border: 1px solid #A0A0A0;
      @include default_transition(border-color,0.15s,linear);
    }
  }
}

// 财务详情页的凭证样式
.invoice-certificate {
  margin-top: 15px;
  padding: 20px 20px 20px 15px;
  border: solid 1px #e0e7f1;
  position: relative;
  .invoice-cert-img {
    width: 120px;
    height: 120px;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 2px;
    }
    .hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 2px;
      cursor: pointer;
      img {
        display: none;
        width: 30%;
        height: 30%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.5);
        img {
          display: inline-block;
        }
      }
    }
    .delete {
      position: absolute;
      top: 5px;
      right: 5px;
      &:hover {
        .icon-close {
          font-weight: 800;
        }
      }
    }
  }
}

.invoice-certification-form {
  .payment-types {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    margin: 9px 0 8px;
    .payment {
      display: flex;
      cursor: pointer;
      .radio {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color: #fff;
        border: 1px solid #aabac6;
        border-radius: 50%;
        margin-right: 5px;
      }
      .radio-active {
        border: 7px solid #2ea1f8;
      }
    }
  }

  .upload-container {
    display: flex;
    display: -webkit-flex;
    .img-add, .img-upload-success {
      display: inline-block;
      width: 120px;
      height: 120px;
      border-radius: 2px;
      background-color: #ffffff;
      border: solid 1px #e0e7f1;
      position: relative;
      color: #e0e7f1;
      font-size: 30px;
      margin-right: 16px;
      cursor: pointer;
      .icon-add, .icon-Uploadsuccess {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%)
      }
      >.text {
        position: absolute;
        top: 75%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 120px;
        font-size: 17px;
        text-align: center;
      }
      &:hover {
        border: 1px solid #2ea1f8;
        color: #2ea1f8;
      }
    }
    .img-upload-success {
      border: 1px solid #2ea1f8;
      color: #2ea1f8;
      cursor: default;
    }
    .img-show {
      display: inline-block;
      width: 120px;
      height: 120px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: 2px;
        border: solid 1px #e0e7f1;
      }
      .delete {
        display: none;
        position: absolute;
        right: 5px;
        bottom: 5px;
        cursor: pointer;
        &:hover {
          font-weight: 800;
        }
      }
      &:hover {
        .delete {
          display: inline-block;
        }
      }
    }
  }
  .money-left {
    font-weight: bold;
    color: #2ea1f8;
    border: 1px solid #2ea1f8;
    border-radius: 4px;
    padding: 7px;
    display: inline-block;
    cursor: pointer;
    margin-left: 20px;
  }
  .payment-type-choice {
    display: inline-block;
    color: #2ea1f8;
    border: 1px solid #2ea1f8;
	border-radius: 4px;
    padding: 5px;
    margin: 5px;
    margin-left: 0;
    cursor: pointer;
    &:hover {
      color: #fff;
      background-color: #2ea1f8;
    }
  }
  .payment-type-choice-active {
    color: #fff;
    background-color: #2ea1f8;
  }
}

.bill-email{
  margin-bottom: 20px;
  header{
    font-size: 16px;
    color: #666666;
    font-weight: 500;
  }
  input{
    margin-top: 15px;
    width: 100%;
    padding: 10px;
    border: 1px solid $kb-color3;
    &:focus{
      border: 1px solid #A0A0A0;
      @include default_transition(border-color,0.15s,linear);
    }
  }
}
.finance-detail-footer{
  @include clear_fix;
  margin-top: 10px;
  span{
    font-size: 14px;
    color: #999999;
  }
}

//公司搜索
.team-search{
  display: inline-block;
  @include text-over(48px)
}

.team-search-full{
  display: inline-block;
  @include text-over(100%)
}

.team-search-max{
  display: inline-block;
  @include text-over-noi(200px)
}
//合同编号截取
.contract-serial{
  display: inline-block;
  @include text-over(200px);
}

//账单列表展开表格
.invoice-table-upon + table{
  border-collapse: inherit !important;
  td{
    border-bottom: 1px solid $kb-color3;
  }
}
.expand-tr{
  &:last-child td{
    border-bottom: none !important;
  }
  td{
    background-color: #f0f2f3;
    color: #999;
    font-size: 12px;
    padding: 0 0 10px 0;
    a {
      color: #999 !important;
    }
  }
}
.expand-th{
  td{
    background-color: #f0f2f3;
    font-size: 12px;
    padding: 10px 0;
  }
}
.upon-tbody + tbody{
  & > tr:first-child >td{
    border-top: 1px solid $kb-color3;
  }
}

//发送账单表单收件人列表
.recipients-list{
  margin-bottom: 20px;
  li{
    margin-bottom: 10px;
    .recipient-name{
      display: inline-block;
      width: 60px;
    }
    span{
      margin-right: 10px;
    }
  }
}

.recipient-comment{
  width: 200px;
  padding: 5px;
  border: 1px solid $kb-color3;
  &:focus{
    border-color: #A0A0A0;
  }
}

.r1_radios {
  .r1_radio {
    margin-right: 20px;
    >div, >label {
      cursor: pointer;
    }
    .radio-btn {
      display: inline-block;
      width: 18px;
      height: 18px;
      background-color: #fff;
      border: 1px solid #e6e6e6;
      border-radius: 50%;
      margin: 9px 5px 8px 0;
    }
    .radio-btn-active {
      border: 7px solid #2ea1f8;
    }
  }
}

.invoice-application-state {
  display: inline-block;
  min-width: 64px;
  text-align: center;
  font-size: 12px;
  padding: 1px 6px;
  border: 1px solid;
}

.invoice-application-state-pending {
  color: $kb-color45;
  border-color: $kb-color45;
}

.invoice-application-state-process {
  color: $kb-color46;
  border-color: $kb-color46;
}

.invoice-application-state-issued {
  color: $kb-color47;
  border-color: $kb-color47;
}

.invoice-application-state-aborted {
	color: $kb-color53;
	border-color: $kb-color53;
}

.invoice-application-state-none {
  color: $kb-color24;
  border-color: $kb-color24;
}

.invoice-application-list-btn {
  display: inline-block;
  text-align: center;
  font-size: 12px;
  padding: 3px 5px;
  border: 1px solid;
}
.application-list-btn-edit {
  color: #2ea1f8;
  border-color: #2ea1f8;
  &:hover {
    color: #fff;
    background-color: #2ea1f8;
  }
}
.application-list-btn-process {
  color: #ffa81c;
  border-color: #ffa81c;
  &:hover {
    color: #fff;
    background-color: #ffa81c;
  }
}
.application-list-btn-issued {
  color: #00cc90;
  border-color: #00cc90;
  &:hover {
    color: #fff;
    background-color: #00cc90;
  }
}
