.product-categories{
  padding: 0 30px;
  @include clear_fix;
  >div{
    margin-right: 22px;
    margin-bottom: 20px;
    &:nth-child(9n){
      margin-right: 0;
    }
  }
  .category-block{
    width: 100px;
    height: 100px;
    border: 1px solid #2ea1f8;
    color: #2ea1f8;
    background-color: rgba(97, 167, 233, .2);
    float: left;
    text-align: center;
    line-height: 100px;
    font-size: 20px;
    border-radius: 5px;
    >span{
      display: inline-block;
      @include text-over(80px)
    }
  }
}

.shopping-block{
  padding: 20px 30px;
  border: 1px solid $kb-color3;
  display: inline-block;
  width: 330px;
  margin-right: 42px;
  margin-bottom: 42px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  &:nth-child(3n){
    margin-right: 0;
  }
  .icon-edit{
    position: absolute;
    top: 26px;
    right: 15px;
    cursor: pointer;
    &:hover{
      color: #2ea1f8;
    }
  }
  &:hover{
    border-color: #2ea1f8;
  }
}


.shopping-title{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px;
  .shopping-name{
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
    display: inline-block;
  }
  .shopping-type{
    font-size: 14px;
    margin-left: 15px;
    color: #fff;
    padding: 2px 7px;
    border-radius: 4px;
    vertical-align: text-bottom;
  }
  .shopping-type_online{
    background:#2ea1f8;
  }
  .shopping-type_offline{
    background:#02C862;
  }
}

.payment-type{
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  .type{

    display: flex;
    align-items: center;
    >img{
      margin-left: 20px;
      width: 14px;
      margin-right: 10px;
    }
    .type-off{
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: #ffffff;
      margin-right: 10px;
      border-radius: 2px;
    }
  }
}
.shop-statistics{
  display: flex;
  justify-content: space-between;
  align-items: center;
  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    >span:first-child{
      font-size: 24px;
      margin-bottom: 2px;
    }
    >span:last-child{
      font-size: 14px;
      color: #999;
    }
  }
}

.inventory-number {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  background-color: #f12c20;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  margin: 15px 0 0 15px;
  transform: translate(-50%, -50%);
}

.product-info{
  margin-left: 10px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  .title{
    display: flex;
    align-items: center;
    >span:first-child{
      font-size: 20px;
    }
    >span:nth-child(2){
      display: inline-block;
      width: 50px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      background-color: #dd5a55;
      color: #fff;
      font-size: 10px;
      margin-left: 5px;
    }
  }
  .product-category{
    >span{
      display: inline-block;
      padding: 2px 6px;
      border: 1px solid #2ea1f8;
      color: #2ea1f8;
      background-color: #ffffff;
      font-size: 10px;
      border-radius: 2px;
    }
  }
}

.product-search{
  margin-left: 20px;
  display: inline-block;
  vertical-align: middle;
  .Select{
    width: 160px;
  }
  .is-focused:not(.is-open)>.Select-control {
    border-color: #A0A0A0;
  }
}
.content-table{
  .report_default{
    font-size:12px;
    color: #fff;
    .report_active{
      display: inline-block;
      background: #73bb4b;
      padding: 6px 8px;
    }
    .report_failed{
      display: inline-block;
      background: #999999;
      padding: 6px 8px;
    }
    .report_pending{
      display: inline-block;
      background: #2ea1f8;
      padding: 6px 8px;
    }
    .report_uploading{
      display: inline-block;
      background: #2ea1f8;
      padding: 6px 8px;
    }


  }
}
.templete{
  .templete-download{
    display: inline-block;
    background-image: url("https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/download02@3x.png");
    background-repeat:no-repeat;
    background-size: 14px 14px;
    width: 14px;
    height:14px;
    margin-right: 20px;
    vertical-align: text-top;
    &:hover{
      background-image: url("https://cncaoren017.blob.core.chinacloudapi.cn/cncaoren006/static/img_icon_down@3x.png");
    }
  }
  .templete-empty{
    display: inline-block;
    background-size: 14px 14px;
    width: 14px;
    height:14px;
    margin-right: 20px;
    vertical-align: text-top;
  }
  .icon-edit{
    color:#6e6e6e;
    &:hover{
      color:#2ea1f8;
    }
  }
  .icon-delete{
    color:#6e6e6e;
    &:hover{
      color:#2ea1f8;
    }
  }
}


.crm_info{
  width:100%;
  margin-bottom: 22px;
  .crm_info_single{
    display: inline-block;
    width: 50%;
    .crm_info_title{
      display: inline-block;
      width: 85px;
      color: #999999;
    }
  }
}
