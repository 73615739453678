.nav-member{
  width: 225px;
  float: left;
  background-color: white;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  margin-bottom: 30px;
  >header{
    height: 124px;
    position: relative;
    border-bottom: 1px solid $kb-color3;
    background: url("#{$dev-url}member_info.jpg");
  }
  .member-img{
    display: inline-block;
    position: absolute;
    left: 50%;
    top: 50px;
    @include transform-translateX(-50%);
  }
  .member-introduce{
    padding: 30px;
    span{
      display: block;
      padding-bottom: 15px;
      font-size: 16px;
      color: #666666;
      font-weight: 500;
    }
  }
  .section-title{
    float: left;
  }
}

.meet-device li{
  display: block;
  float: left;
  margin-right: 20px;
}

.meet-device li video{
  display: block;
  margin-top: -15px;
  padding-bottom: 10px;

}

.meet-device li img{
  display: block;
  margin-right: 20px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.meet-device li video::-internal-media-controls-download-button {
    display:none;
}

.meet-device li video::-webkit-media-controls-enclosure {
    overflow:hidden;
}

.meet-device li video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
}

.membership-type_list{
  margin-bottom: 10px;
  >span:first-child{
    display: inline-block;
    width: 100px;
  }
  >span:last-child{
    color: #999999;
    margin-left: 10px;
  }
}

// 会员详情页
.member-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  border-bottom: 1px solid $kb-color3;
  .member-name{
    display: flex;
    align-items: center;
    >span{
      font-size: 22px;
      margin-left: 15px;
    }
  }
}
.member-box{
  text-align:center;
  margin-top: 10px;
  .member_name{
    display:block;
    text-align:center;
    padding-top: 5px;
    width:100%;
  }
}

.member-notes {
    span {
        display: inline-block;
        margin-bottom: 5px;
    }
    div {
        word-wrap: break-word;
    }
}

.member-message{
  display: flex;
  .message-list{
    width: 50%;
    text-align: left;
    padding-bottom: 10px;
    padding-top: 20px;
    // border-bottom: 1px solid $kb-color3;
    >li{
      display: flex;
      align-items: center;
      padding: 5px 0;
      font-size: 14px;
      >span:nth-child(1){
        display: block;
        width: 70px;
        margin-right: 5px;
      }
    }
  }
}

.member-credits{
  padding-top: 22px;
  .member-notes {
    word-break: break-all;
  }
  .credit-message{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #DFE2E5;
    background-color: #FAFBFC;
    padding: 20px 30px;
		border-radius: 4px;
    .star-icon {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(to bottom right, #F9CC27, #F16911);
        margin-right: 16px;
        .icon-star2 {
            font-size: 16px;
            margin: 0;
        }
    }
    .point-con {
        font-size: 12px;
        color: #7f8fa4;
        .point-num {
            font-size: 14px;
            font-weight: 500;
            color: #354052;
        }
    }
    .recharge-point{
      display: flex;
      align-items: center;
      >div{
        width: 44px;
        height: 20px;
        font-size: 10px;
        border-radius: 100px;
        background-color: #2ea1f8;
        color: #ffffff;
        line-height: 20px;
        text-align: center;
        cursor: pointer;
        margin-left: 10px;
      }
    }
  }
}

.member-user-point{
  display: flex;
  .user-point{
    float: left;
    margin-top: 20px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    height: 37px;
    color: #cccccc;
    p:nth-of-type(2){
      padding: 0 15px;
    }
    span{
      color: #666666;
    }
  }
}

.kb-tip-warning {
    width: 100%;
    height: 40px;
    padding: 10px 12px;
    background-color: #FFA345;
    border-radius: 4px;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
    .icon {
        font-size: 16px;
        opacity: 0.7;
        margin-right: 12px;
    }
    .msg {
        font-size: 13px;
    }
}


.space-role-ctn {
    border: 1px solid #DFE2E5;
    .ctn-title {
        padding: 20px 30px;
        .icon-img {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(to bottom right, #2EA1F8, #1990EA);
            margin-right: 16px;
            .icon-company {
                font-size: 16px;
                margin: 0;
            }
        }
        .content {
            font-size: 12px;
            color: #999;
            .title {
                font-size: 15px;
                font-weight: 500;
                color: #279BF3;
            }
        }
      .icon-edit, .icon-delete {
        cursor: pointer;
        &:hover{
          color: #2ea1f8;
        }
      }
    }
    .ctn-content {
        padding: 25px 30px;
        background-color: #FAFBFC;
        border-top: 1px solid #DFE2E5;
        .space-role {
            font-size: 13px;
            line-height: 21px;
            margin-bottom: 14px;
            &:last-child {
                margin-bottom: 0;
            }
            .role-name {
                color: #354052;
            }
            .role-des {
                color: #999;
            }
        }
    }
}

.location-role-block {
    border: 1px solid #DFE2E5;
    margin-bottom: 22px;
    height: 80px;
    .loc-info {
        width: 250px;
        padding: 20px 30px;
        .loc-img {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            margin-right: 16px;
            background-size: cover;
            background-position: center;
        }
        .content {
            font-size: 12px;
            color: #999;
            .title {
                font-size: 15px;
                font-weight: 500;
                color: #279BF3;
            }
        }
    }
    .roles {
        width: calc(100% - 250px);
        padding: 15px 30px;
        background-color: #FAFBFC;
        border-left: 1px solid #DFE2E5;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        .loc-role {
            width: 33%;
            font-size: 13px;
            line-height: 21px;
            margin-bottom: 14px;
            .role-name {
                color: #354052;
            }
        }
    }
}

.org-role {
    height: 16px;
    line-height: 16px;
    padding: 0 6px;
    border-radius: 3px;
    color: #fff;
    font-size: 10px;
    text-align: center;
    margin-left: 6px;
    white-space: nowrap;
}
.org-role-employer {
    background-color: #1991EB;
}
.org-role-finance {
    background-color: #EE8418;
}
.org-role-employee {
    background-color: #C2CAD4;
}

.member-skills {
    display: flex;
    flex-wrap: wrap;
    .skill-block {
        height: 16px;
        line-height: 16px;
        padding: 0 6px;
        border-radius: 3px;
        color: #54667A;
        font-size: 10px;
        background-color: #DFE1E5;
        text-align: center;
        white-space: nowrap;
        margin-right: 4px;
        margin-bottom: 4px;
    }
}
/*
  全局公司详情页
*/
.org-loc-block {
    width: 256px;
    height: 209px;
    margin-top: 22px;
    margin-right: 21px;
    float: left;
    border: 1px solid #DFE2E5;
    &:nth-child(3) {
        margin-right: 0;
    }
    .loc-img {
        height: 163px;
        background-position: center;
        background-size: cover;
    }
    .loc-title {
        height: calc(100% - 163px);
        color: #354052;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 500;
        text-align: left;
        padding: 0 13px;
        display: flex;
        align-items: center;
    }
}
.org-loc-block-a {
    display: block;
    cursor: pointer;
    &:hover {
        border-color: #5fa7ec;
    }
}
