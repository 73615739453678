.network-problem{
  text-align: center;
  margin-top: 150px;
  img{
    width: 88px;
    height: 70px;
  }
  span{
    display: block;
    margin-top: 20px;
    font-size: 14px;
    color: #999999;
  }
}
