.guarantee-content{
  border: none;
  vertical-align: middle;
}
.guarantee-position{
  display: inline-block;
  margin-right: 20px;
  .icon-position{
    margin-right: 2px;
  }
}
.guarantee-task-status{
  display: inline-block;
  position: relative;
  .task-status-title{
    cursor: pointer;
    .icon-radio_button_unchecked{
      color: #c8174b;
      margin-right: 2px;
    }
    span{
      color: #c8174b;
      font-size: 14px;
    }
    &:hover{
      .icon-radio_button_unchecked,.icon-position,span{
        color: #2ea1f8 !important;
      }
    }
  }

  .task-status-title-normal{
    cursor: pointer;
    .icon-radio_button_unchecked{
      margin-right: 2px;
    }
    span{
      font-size: 14px;
    }
    &:hover{
      .icon-radio_button_unchecked,.icon-position,span{
        color: #2ea1f8 !important;
      }
    }
  }
  .task-type{
    li{
      text-align: center;
      padding-left: 5px;
      .icon-radio_button_unchecked{
        margin-right: 5px;
      }
    }
  }
}
.task-type{
  background-color: #ffffff;
  max-height: 220px;
  overflow-x: hidden;
  overflow-y: auto;
  li{
    width: 134px;
    padding: 10px 15px;
    text-align: left;
    cursor: pointer;
	color: #354052;
	border-bottom: 1px solid #e8ecf3;
	&:last-child {
		border-bottom: none;
	}
    &:hover{
		background-color: #f2f4f7;
		color: #2ea1f8;
		text-decoration: none;
    }
    &:nth-child(2){
      .icon-radio_button_unchecked{
        color: #c8174b;
      }
    }
    .icon-radio_button_unchecked{
      margin-right: 2px;
    }
  }
}
.task-deg{
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  display: inline-block;
  border-width: 5px 5px 0;
  border-top-color: #979797;
  margin-left: 5px;
  margin-bottom: 2px;
  cursor: pointer;
}
.update-box{
  border: 1px solid $kb-color3;
  padding: 10px;
  .upload-photo{
    margin-left: 0;
    margin-right: 14px;
    position: relative;
    .icon-close{
      display: none;
      position: absolute;
      top: 4px;
      right: 4px;
      color: #ffffff;
    }
    &:hover{
      .icon-close{
        display: block;
      }
    }
    &:nth-child(4n){
      margin-right: 0;
    }
  }
  textarea{
    width: 100%;
    min-height: 38px;
    border: none;
  }
  .add-photo{
    text-align: center;
    line-height: 80px;
    .icon-add{
      font-size: 18px;
      font-weight: 700;
    }
    &:hover{
      border-color: #2ea1f8;
      .icon-add{
        color: #2ea1f8;
      }
    }
  }
}
.add-status-record{
  @include clear_fix;
}
.date-setting{
  cursor: pointer;
  display: inline-block;
  margin-left: 20px;
  .date-title{
    display: inline-block;
    margin-right: 2px;
  }
}

.dc-mr-smail {
  > div {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.select-progress{
  margin-bottom: 8px;
  margin-right: 20px;
  display: inline-block;
  >span:first-child{
    margin-right: 8px;
  }
  .receipt-status{
    cursor: pointer;
    margin-right: 8px;
    &:hover{
      background-color: #2ea1f8;
      color: #ffffff;
    }
  }
  .color-show{
    width: 23px;
    height: 24px;
    text-align: center;
    border: 1px solid #e6e6e6;
    margin-right: 2px;
    @include d-flex;
    justify-content: center;
    align-items: center;
    float: left;
    span{
      display: inline-block;
      width: 15px;
      height: 15px;
      background: #0a4b3e;
    }
  }
}
.select_wrap{
  font-size:13px;
  .select_title{
    display:inline-block;
    margin-right:20px;
    color:#999;
  }
  .crm_select{
    display:inline-block;
    margin-right:30px;
    color:#354052;
    &:hover{
      cursor: pointer;
      color: #2ea1f8;
    }
  }
  .select_active{
    color: #2ea1f8;
    font-weight: 600;
  }
  .selected{
    border: 1px solid #DBDBDB;
    display: inline-block;
    padding: 5px 8px;
    margin-right: 10px;
    &:hover{
      cursor: pointer;
      border: 1px solid #F95C4B;
      background: #FFEEE0;
      color: #F95C4B;
    }
  }
  .clearFilters{
    margin-left: 20px;
    color: #333;
    display: inline-block;
    &:hover{
      cursor: pointer;
    }
  }
}
// .clear-criteria{
//   cursor: pointer;
//   display: inline-block;
//   padding: 5px 15px;
//   border: 1px solid #CA5C5C;
//   @include radius(100px);
//   *{
//     color: #CA5C5C;
//   }
//   &:hover{
//     *{
//       color: #ca2929;
//       border-color: #ca2929;
//     }
//   }
// }
.td-position{
  img{
    width: 20px;
    height: 20px;
    @include radius(50%);
    float: left;
    margin-right: 5px;
  }
  span:nth-of-type(2){
    font-size: 12px;
    vertical-align: middle;
  }
  >div{
    display: inline-block;
    margin-right: 2px;
  }
  @include d-flex;
  align-items: center;
}
.degree-important{
  span{
    color: #c8174b;
    &:nth-of-type(2){
      font-size: 12px;
    }
  }
}

.search-argument {
  position: relative;
  display: inline-block;
}

.kb-radio-box {
  overflow: hidden;
  display: inline-block;
  padding: 8px 10px;
  > div{
    float: left;
    margin-right: 25px;
    > input {
      margin-right: 10px;
    }
  }
}
