$color1: #DFE3E9;//input边框颜色 placeholder颜色;
$color2: #EBEBEB;//登录注册头部下边框颜色;
$color3: #666666;//登录注册字体;
$color4: #39b54a;//发送验证码按钮;button按钮颜色;
$color5: #FFFFFF;//button字体颜色;头部尾部背景色;
$color6: #E03641;//服务器出错提示;
$color7: #3FBF89;//解锁Email已发送;
$color8: #e8737c;//input请求(required)颜色;
$color9: #333333;//员工分店管理头部背景;
$color10:#787878;//员工分店管理字体;
$color11:#F7F7F7;//员工分店管理背景色;
$color12:#48B993;//员工绿色状态;
$color13:#E4E4E4;//分店地址下边框颜色;
$color14:#5FA7EC;//分店地址名称背景色;
$color15:#FFFFFF;//分店地址背景色;
$color16:#808080;//员工分店管理列表默认颜色;
$color17:#A3ACB3;//弹出框取消按钮;
$color18:#ECF5FC;//搜索框背景色;
$color19:#ED6775;//员工红色状态;
$color20:#f4f6f7;//公司设置(部门树)颜色;
$color21:#F5F5F5;//模拟搜索框背景色;
$color22:#999999;//desk2-nav-list默认字体;
$color23:#83ba48;//工位号
$color24:#e5e5e5;//下划线1

$cancel-btn-color: #d6d6d6;

//颜色
$kb-color1: #EFF3F6; //分店员工管理背景色;
$kb-color2: #F3F7F9; //input框右侧部分背景色;
$kb-color3: #DFE2E5; //描边专用颜色;
$kb-color4: #D0E5F8; //鼠标经过导航时背景色;
$kb-color5: #5FA7EC; //主色调/按钮/可点击;
$kb-color6: #808080; //添加新地点;
$kb-color7: #000000; //遮罩层 透明度0.5;
$kb-color8: #273142; //酷办头部背景色;
$kb-color9: #48B993; //员工绿色状态;
$kb-color10:#ED6775; //员工红色状态;
$kb-color11:#333333; //sub-nav-list 字体颜色;


$kb-color12:#354052; //表格td字体颜色;
$kb-color13:#17c8a1; //表格状态(跟进中);

$kb-color14:#39B54A; //有边框按钮 normal状态时边框颜色;
$kb-color15:#FFFFFF; //有边框按钮 normal状态时背景颜色;

$kb-color16:#249533; //有边框按钮 hover状态时边框颜色;
$kb-color17:#eef7ff; //有边框按钮 hover状态时背景颜色;

$kb-color18:#238c31; //有边框按钮 click状态时边框颜色;
$kb-color19:#d3e6f6; //有边框按钮 click状态时背景颜色;

$kb-color20:#999999; //有边框按钮 disabled状态时边框颜色;
$kb-color21:#FFFFFF; //有边框按钮 disabled状态时背景颜色;

$kb-color22:#444444; //sub-nav-list hover字体颜色;
$kb-color23:#f4f4f4; //头部搜索框背景颜色;
$kb-color24:#bbb; //员工分店管理字体颜色; 发票状态 未申请颜色
$kb-color25:#FFFFFF; //员工分店管理hover字体颜色;
$kb-color26:#333333; //nav-container-list 字体默认时颜色;
$kb-color27:#ffffff; //nav-container-list 字体hover时颜色;
$kb-color28:#2ea1f8; //nav-container-list 字体hover时背景颜色;
$kb-color29:#c8174b; //popup-error字体颜色;
$kb-color30:#f9d83b; //负责人标志颜色;
$kb-color31:#2ea1f8; //收款状态 进行中颜色; 客户状态  初步接洽；报修状态  已接单; 服务商状态 已接单
$kb-color32:#73bb4b; //收款状态 已完成颜色; 合同状态 已签颜色； 客户状态  赢单颜色；报修状态 已完成
$kb-color33:#dd5a55; //收款状态 待退款颜色; 合同状态 审批拒绝颜色； 报修状态 已逾期；服务商状态 已拒绝
$kb-color34:#e6eaee; //分页边框颜色;
$kb-color35:#e47622; //header-title 联系中字体颜色;
$kb-color36:#444444; //城市列表头部颜色(北京);
$kb-color37:#83add3; //评论列表前进度显示 progress-bar背景色;
$kb-color38:#c8174b; //表格中重要级别客户字体颜色;
$kb-color39:#97c740; //表格中保修状态新建背景色;
$kb-color40:#9ac7f0; //会议室格子选中背景色;
$kb-color41:#f4f6f7; //desk-ul列表li划过时背景色;
$kb-color42:#0AC862; //收款状态  确认核销颜色； 客户状态  已入驻颜色
$kb-color43:#e6e6e6; //收款状态  已逾期颜色; 合同状态  已取消颜色；客户状态  已毕业颜色； 服务商状态 已取消
$kb-color44:#f2a73d; //收款状态  待支付颜色
$kb-color45:#60A6EC; //发票状态  申请开票颜色； 合同状态  审批中颜色； 报修状态  进行中
$kb-color46:#ECBC23; //发票状态  开票中颜色； 合同状态  待审批颜色；报修状态  审核中
$kb-color47:#73BB4B; //发票状态  开票完成颜色；服务商状态  已完成
$kb-color53:#dd5a55; //发票状态  发票废弃颜色；
$kb-color48:#9288e1; //客户状态  需求确定颜色； 报修状态 已提交；服务商状态 已提交
$kb-color49:#FFDFDF; //合同状态  已结束颜色
$kb-color50:#D0668F; //合同状态  待签字颜色；客户状态  方案报价颜色；报修状态  重新打开
$kb-color51:#CB962E; //客户状态  搁置颜色
$kb-color52:#ff0000; //客户状态  流失颜色

//间距
$kb-value1: 30px; //左右间距;
$kb-value2: 20px; //内容区域头部上下间距;

$kb-value3: 20px; //input框上下间距;

$screen-xs:                   480px !default;
$screen-xs-min:               $screen-xs !default;
$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;
$screen-sm:                   768px !default;
$screen-sm-min:               $screen-sm !default;
$screen-lg:                   1200px !default;
$screen-lg-min:               $screen-lg !default;
$screen-md:                   $screen-sm !default;
$screen-md-min:               $screen-md !default;

$screen-xs-max:               ($screen-sm-min - 1) !default;
$screen-sm-max:               ($screen-md-min - 1) !default;
$screen-md-max:               ($screen-lg-min - 1) !default;

//图片引用
$create-space-success-url1:         $dev-url+"create-success.png";
$unlock-account-success-url2:       $dev-url+"email-success.png";
$lr-bg-url3:                        $dev-url+"bg.png";
$create-locations-add-btn1:         $dev-url+"add-locations-default.png";
$create-locations-add-btn2:         $dev-url+"add-locations-hover.png";
$add-team-search-url1:              $dev-url+"add-team-search.png";
