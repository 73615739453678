.kb-more-file{
  margin: 45px 0 0 0;
  padding-left: 0 !important;
  padding-top: 20px;
  li{
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 15px;
    position: relative;
    vertical-align: middle;
    &:hover{
      .delete{
        display: block;
      }
    }
  }
  .file-show {
    text-align: center;
    border: 1px solid #2ea1f8;
    .img-file {
      width: 25px;
      margin-top: 25px;
    }
    .text {
      display: block;
      color: #2ea1f8;
    }
  }
  .delete{
    position: absolute;
    text-align: center;
    display: none;
    line-height: 16px;
    top: -6px;
    right: -6px;
    width: 16px;
    height: 16px;
    @include radius(50%);
    border: 1px solid $kb-color3;
    background-color: #ffffff;
    cursor: pointer;
    i{
      font-size: 10px;
      margin-right: 0;
      color: #999;
    }
  }
}
.sort-image{
  cursor: move;
  .delete{
    position: absolute;
    text-align: center;
    display: none;
    line-height: 16px;
    top: -6px;
    right: -6px;
    width: 16px;
    height: 16px;
    @include radius(50%);
    border: 1px solid $kb-color3;
    background-color: #ffffff;
    cursor: pointer;
    z-index: 10;
    i{
      font-size: 10px;
      margin-right: 0;
      color: #999;
    }
  }
  .sort-handle{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
