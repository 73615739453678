.login-forget{
  a,span {
    font-size: 14px;
    vertical-align: middle;
    color: #999999;
  }
  a:nth-of-type(1){
    color: $color1;
    margin-left: 5px;
  }
  a:nth-of-type(2){
    color: $color4;
  }
  input {
    margin-right: 5px;
    width: auto;
    vertical-align: middle;
  }
}
.login-error{
  padding: 10px 30px;
  background-color: $color6;
  p,a{
    color: $color5;
    font-size: 12px;
  }
  a{
    display: block;
    text-decoration: underline;
  }
}
.kb-record{
  line-height: 57px;
}
// .lr-certain{
//   button{
//     &:hover {
//       background-color: $kb-color16;
//     }
//     &:active{
//       background-color: $kb-color18;
//     }
//   }
// }
.lr-out-box{
  position: absolute;
  top: 58px;
  bottom: 58px;
  height: auto;
  width: 100%;
  overflow-y: auto;
  .comment-box1{
    margin-left: auto;
    margin-right: auto;
  }
}
