.create-success{
  height: 90px;
  i{
    margin: 0 auto;
    display: block;
    width: 90px;
    height: 90px;
    @include default_bgimg($create-space-success-url1);
  }
}
