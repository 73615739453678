.nav-content-1{
  width: 800px;
}
.nav-content-1-md{
  width: 545px;
}

.nav-content-l {
  width: 846px;
  padding: 20px;
  background-color: #FFF;
}

.nav-content-r {
  width: 246px;
  padding: 20px;
  background-color: #FFF;
}

.nav-content-row {
  width: 270px;
  padding: 20px;
  background-color: #FFF;
  padding-top: 0;
}

.nav-content-2{
  width: 246px;
  .nav-container-content{
    box-shadow: none;
  }
}
.comment-padding{
  padding-left: 60px;
}
.check-all-staff{
  margin-bottom: 30px;
  button{
    width: 100%;
    padding: 8px;
    font-size: 14px;
    color: $kb-color14;
    background-color: $kb-color15;
    border: solid 1px $kb-color14;
    &:hover{
      color: $kb-color16;
      background-color: $kb-color17;
      border: solid 1px $kb-color16;
    }
    &:active{
      color: $kb-color18;
      background-color: $kb-color19;
      border: solid 1px $kb-color18;
    }
  }
}
.add-market-record{
  margin-bottom: 30px;
  @include clear_fix;
  textarea{
    width: 100%;
    height: 86px;
    border: 1px solid $kb-form-color5;
    color: $kb-form-color1;
    padding: 10px;
    &:focus{
      border: 1px solid #A0A0A0;
    }
  }
  .drop-down-select{
    margin-top: 6px;
  }
}
.textarea-box{
  position: relative;
  border: 1px solid $kb-color3;
  textarea{
    padding: 10px 10px 30px 10px;
  }
}

.nav-comment-container{
  margin-bottom: 60px;
  >.nav-comment-content:last-child{
    border-bottom-color: #ffffff !important;
  }
}
.nav-comment-content{
  position: relative;
  padding-bottom: 30px;
  .communicate{
    margin: 16px 0;
    text-align: left;
    span{
      display: inline-block;
      &:nth-child(1){
        font-size: 12px;
        color: #ffffff;
        padding: 3px 6px;
      }
      &:nth-of-type(2){
        vertical-align: middle;
        color: #999999;
        margin-top: 2px;
        a{
          &:hover{
            color: #2ea1f8 !important;
          }
        }
      }
    }
    .icon-account_circle{
      font-size: 16px;
      vertical-align: middle;
      margin-left: 15px;
      margin-right: 2px;
      color: #999999;
      @include transform-translateY(0);
    }
  }
  .nav-comment-text{
    .flow{
      font-size: 12px;
      color: #999999;
      margin-left: 10px;
    }
  }
}
.border-bottom-lg{
  border-bottom: 10px solid #f5f5f5;
  margin-bottom: 30px;
}

//更多消息
.more-message{
  text-align: center;
  padding: 11px 0;
  font-size: 14px;
  color: #333333;
  background-color: #ffffff;
  border: solid 1px $kb-color3;
  cursor: pointer;
  margin-top: 30px;
  border-radius: 100px;
  i{
    font-size: 1px;
    margin-right: 4px;
  }
  &:hover{
    color: #2ea1f8;
    border: solid 1px #2ea1f8;
  }
  &:active{
    border: solid 1px #2ea1f8;
    color: #2ea1f8;
  }
}

.progress-bar{
  position: absolute;
  left: 0;
  top: 4px;
  width: 30px;
  height: 30px;
  background-color: $kb-color37;
  @include radius(50%);
}
//
.comment-portrait{
  float: left;
  img{
    width: 36px;
    height: 36px;
    @include radius(50%);
  }
}
.comment-name{
  float: left;
  margin-left: 10px;
  .comment-time{
    display: block;
    font-size: 12px;
    color: #999999;
  }
  .comment-people{
    font-size: 16px;
    color: #2ea1f8;
  }
  .comment-reply{
    font-size: 16px;
    color: #999999;
    margin: 0 10px;
  }
}
.nav-comment-text{
  padding-left: 48px;
  margin-top: 10px;
  p{
    font-size: 14px;
    color: #666666;
  }
}
.under-line{
  padding-bottom: 12px;
  border-bottom: 1px solid $kb-color3;
}
.comment-reply-box{
  padding-left: 50px;
  @include d-flex;
  align-items: center;
  img{
    width: 36px;
    height: 36px;
    @include radius(50%);
    vertical-align: middle;
  }
  input{
    width: calc(100% - 90px);
    padding: 5px 10px;
	border: 1px solid $kb-color3;
	border-radius: 4px;
    margin-left: 8px;
    &:focus{
      border: 1px solid #A0A0A0;
    }
  }
  .user-reply-img{
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
  }
}
.nav-comment-children{
  padding-left: 50px;
}
.reply-btn-box{
  float: left;
  margin-left: 10px;
  cursor: pointer;
  .reply-btn-icon{
    font-size: 18px;
    color: #999999;
    cursor: pointer;
    margin-top: 2px;
    display: inline-block;
  }
}
.reply-btn{
  display: inline-block;
  position: relative;
  padding: 0 5px;
  bottom: 2px;
}
.reply-arrow{
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 50%;
  left: 1px;
  margin-top: -6px;
  border-width: 5px 5px 5px 0;
  border-right-color: #444444;
}
.reply-inner{
  padding: 6px 18px;
  color: #fff;
  border-radius: 2px;
  background-color: #444444;
}
//企业概况评论区
.nav-publish-text{
  margin-bottom: 10px;
  .content {
    display: inline-block;
    padding: 7px 0;
    font-size: 14px;
    line-height: 1;
    color: #333333;
    position: absolute;
    top: 100px;
    margin-top: 20px;
  }
}
.publish-portrait{
  float: left;
  margin-right: 10px;
  img{
    width: 20px;
    height: 20px;
    @include radius(50%);
  }
}
.publish-name{
  padding: 5px 0 5px 10px;
  font-size: 14px;
  color: #404040;
}
.publish-time{
  margin-bottom: 10px;
  display: inline-block;
  padding: 8px 4px 5px 4px;
  font-size: 12px;
  line-height: 1;
  color: #333333;

}
//时间线
.comment-timeline{
  &:before{
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: calc(100% - 30px);
    background-color: #d8d8d8;
    bottom: 0;
    left: 13px;
  }
  &:last-child{
    &:before{
      height: 0;
    }
  }
}
.time-line-icon{
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #2ea1f8;
  @include radius(50%);
  top: 0;
  left: 0;
  text-align: center;
  line-height: 30px;
  i{
    margin-right: 0;
    color: #ffffff;
  }
}

//crmIndex filter styles
.average-box{
  @include d-flex;
  justify-content: space-between;
  >.kb_dropdown{
    width: 149px;
    &:first-of-type{
      width: 190px;
    }
    .task-status-title{
      text-align: center;
    }
  }
  > .KB-select_box{
    .kb_dropdown{
      width: 149px;
      text-align: center;
    }
  }
}

.team-located{
  display: inline-block;
  margin-left: 20px;
  font-size: 14px;
  color: #ffffff;
  padding: 2px 5px;
  background-color: #999999;
}
//公司logo上传
.org-logo-box{
  @include clear_fix;
  float: left;
  cursor: pointer;
  margin-right: 20px;
  position: relative;
  &:hover{
    .mask{
      display: block;
    }
  }
  .mask{
    display: none;
    position: absolute;
    width: 60px;
    height: 60px;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3);
    line-height: 60px;
    text-align: center;
    font-size: 26px;
    color: rgba(235,237,238,0.8);
  }
}

.org-recharge-btn{
  position: absolute;
  right: 0;
  bottom: 8px;
  padding: 3px 10px;
  background-color: #2ea1f8;
  color: #ffffff;
  font-size: 10px;
  border-radius: 100px;
}


.select-drop_down{
  height: 32px;
  border: 1px solid #dfe3e9;
  border-radius: 4px;
  background: #fcfcfc;
  background: linear-gradient(180deg,#fcfcfc,#eceff3);
  color: #354052;
  display: flex;
  align-items: center;
  &:hover {
	background: #fafafa;
    background: linear-gradient(180deg,#fafafa,#e9ecf1);
    color: #354052;
  }
  .select-dropDown_left{
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    color: #354052;
    border-right: 1px solid #dfe3e9;
    display: flex;
    align-items: center;
  }
  .select-dropDown_right{
    height: 100%;
    padding-left: 5px;
	padding-right: 3px;
	font-size: 12px;
    color: #354052;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.flex_order{
  display:flex;
  justify-content: left;
  padding-top:25px;
}

.contract_continue{
  font-size:12px;
  width:95px;
  display:inline-block;
  padding:3px 4px;
  border-radius:2px;
  background-color: $kb-color31 !important;
  color: #ffffff !important;
}
.contract_approve{
  font-size:12px;
  width:95px;
  display:inline-block;
  padding:3px 4px;
  border-radius:2px;
  background-color: #dd5a55 !important;
  color: #ffffff !important;
}
.licence_container{
  padding:10px 0 0 15px;
  div{
    padding:20px 0 0 0;
  }
}
.org_summary_pad{
  margin-bottom: 60px;
}
.org_wrapper_box{
  border-bottom:1px solid #e6e6e6;
  padding-bottom:15px;
  &:nth-last-child(1){
    border-bottom:none;
    padding-bottom:0;
  }
}
.team_hover{
  &:hover{
    background:#F4F6F7;
  }
}
.org_summary_header{
  font-size: 20px;
  color: #333333;
}
.org_summary_more{
    height: 24px;
    font-size: 12px;
    cursor: pointer;
    color:  $kb-color14;
    display: block;
    float: right;
    padding: 0px 10px;
    line-height: 24px;
    border: 1px solid $kb-color14;
    background-color: #ffffff;
    @include transform-translateY(2px);
    &:hover{
      color: #ffffff;
      background-color: $kb-color14;
  }
}
.org_summary_title{
  font-size: 14px;
  color: #666666;
  font-weight:600;
}
.org_summary_content{
  font-size: 14px;
  color: #666666;
}

.areas_new{
  width:232px;
  height:88px;
  background:#87a8ff;
  color:#fff;
  float:left;
  margin-left:30px;
  border-radius: 2.2px;
  display: inline-block;
  margin-bottom:30px;
  &:nth-child(1){
    margin-left:25px;
  }
  &:nth-child(3n-2){
    margin-left:25px;
  }
  &:nth-last-child(1){
    margin-bottom:60px !important;
  }
  .area_names{
    font-size: 17px;
    color: #ffffff;
    padding:15px 0 15px 15px;
  }
  .area_details{
    font-size: 12px;
    padding:0 0 10px 15px;
  }
  &:hover{
    background:#5F8BFF
  }
}


.org_summary_pad{
  .content-table{
    .table_td{
      width:50%;
      // padding-bottom:0 !important;
    }
  }
}
