.application-logo{
  width: 120px !important;
  height: 120px !important;
  border: 1px solid $kb-color3;
  @include d-flex;
  justify-content: center;
  align-items: center;
  img{
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 10px;
  }
}

.download-icon-template {
  float: left;
  width: 100%;
  color: rgb(153, 153, 153);
  margin-bottom: 10px;
  >label {
    float: left;
    margin-right: 10px;
  }
  >div {
    float: left;
    >span {
      margin-right: 20px;
    }
  }
}
