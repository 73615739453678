.kb-mark{
  position: fixed;
  z-index: 2;
  top: 0;

  width: 100%;
  height: 100%;

  opacity: .5;
  background: black;

  filter: Alpha(opacity=50);
  -moz-opacity: .5;
}
