.service-tr{
  .linkman-tel{
    display: none;
    margin-top: 5px;
  }
  &:hover{
    .linkman-tel{
      display: block;
    }
  }
  .linkman{
    width: 100px;
  }
  &:hover{
    .drag-move{
      display: block;
    }
  }
}
.img-text{
  padding: 20px 0 20px 0;
  img{
    width: 440px;
    height: 260px;
    margin-top: 20px;
  }
}
.service-comment{
  padding-left: 20px;
  @include clear_fix;
  textarea{
    margin-top: 20px;
    width: 100%;
    height: 86px;
    padding: 10px;
    border: 1px solid $kb-color3;
    &:focus{
      border-color: #A0A0A0;
    }
  }
  button{
    float: right;
    margin-top: 20px;
  }
  p{
    padding: 30px 0;
  }
}

.comment-text{
  border: none !important;
  padding: 0 !important;
  height: auto !important;
}
.service-name{
  color: #999999;
  @include text-over(210px);
}

.service-setting{
  @include d-flex;
  align-items: center;
  .service-status{
    @include d-flex;
    align-items: center;
    padding: 0 15px;
    border-left: 1px solid $kb-color3;
    .slide-btn{
      @include d-flex;
      margin: 0 10px;
    }
  }
}
//一级分类 二级分类筛选
.select-tab{
  padding: 6px 6px;
  border-bottom: 1px solid $kb-color3;
  >span{
    position: relative;
    display: inline-block;
    padding: 0 4px;
    cursor: pointer;
    color: #999999;
    margin-right: 8px;
  }
  .active{
    color: #5fa7ec;
    &:after{
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background-color: #5fa7ec;
      bottom: -7px;
      left: 0;
    }
  }
}
.second-category{
  padding: 0 10px;
  >span{
    margin-right: 15px;
    display: inline-block;
    padding: 4px;
    color: #999999;
    cursor: pointer;
  }
  .active{
    background-color: #5fa7ec;
    color: #ffffff;
  }
}
//drag icon tr move
.drag-move{
  cursor: move;
  color: #999999;
  display: none;
}
//启用关闭服务 背景色
.close-service{
  background-color: #f4f6f7;
  .section-title{
    color: #999999 !important;
  }
}
