.org-header{
  padding: 40px 160px;
  display: flex;
  background-color: #FFFFFF;
  border: 1px solid #DFE2E5;
  border-radius: 4px;
  .org-header-step{
    flex: 1;
    position: relative;
    padding-bottom: 30px;
  }
  .org-header-num{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 2px solid #F3F3F3;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #90A4AE;
    font-size: 18px;
    cursor: pointer;
  }
  .org-header-line{
    flex: 1;
    height: 1px;
    background-color: #F3F3F3;
  }
  .org-header-title{
    width: 90px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: -20px;
    color: #848c98;
  }
  .org-step_active{
    .org-header-num{
      border: 2px solid #279BF3;
      color: #279BF3
    }
    .org-header-title{
      color: #279BF3
    }
  }
}

.org-filter{
  padding: 16px 20px;
  border: 1px solid #DFE2E5;
  background-color: #FAFBFC;
  border-radius: 4px;
}

.floor-group-orgs {
	position: relative;
	border-left: 1px solid #eee;
	margin-left: 90px;
	.top-left {
		position: absolute;
		left: 0;
    top: 0;
    background-color: #fff;
    font-size: 18px;
    transform: translate(calc(-100% + 17px), 10px);
		.iconfont {
			color: #999;
			font-size: 24px;
    	margin-left: 16px;
		}
	}
	.block-container {
		margin-left: 30px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		flex-wrap: wrap;
		.company-block {
			display: inline-block;
			width: 115px;
			text-align: center;
			margin-bottom: 24px;
			.logo {
				display: inline-block;
				width: 60px;
				height: 60px;
				border: 1px solid #eee;
				border-radius: 50%;
			}
			.name {
				margin-top: 5px;
				text-align: -webkit-center;
				overflow: hidden;
				text-overflow: ellipsis;
				height: 40px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				position: relative;
			}
		}
	}
}
