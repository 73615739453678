//tooltip
.tooltip{
  position: relative;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
  z-index: 1000;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}
.tooltip.left {
  padding: 0 5px;
  //margin-left: -3px;
}
.tooltip.top {
  padding: 5px 0;
  //margin-top: -3px;
}
.tooltip.bottom {
  padding: 5px 0;
  //margin-top: 3px;
}
.tooltip.right {
  padding: 0 5px;
  //margin-left: 3px;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}
.tooltip.top .tooltip-arrow {
  bottom: 1px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.bottom .tooltip-arrow {
  top: 1px;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 1px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
.triangle-up{
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  border-top-color: #666 !important;
  margin-left: 3px;
}
.triangle-down{
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 0 5px 5px;
  border-bottom-color: #666 !important;
  margin-left: 3px;
}
