
.unlock-account-email{
  text-align: center;
  i{
    display: block;
    margin:0 auto;
    width: 115px;
    height: 88px;
    @include default_bgimg($unlock-account-success-url2);
  }
}
.unlock-previous-step{
  margin-top: 40px;
}
