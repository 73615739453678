.kb-section{
  margin-top: 30px;
  header{
    border-bottom: 1px solid $kb-color3;
    margin-bottom: 15px;
    h2{
      font-size: 16px;
      color: #666666;
      font-weight: 500;
      padding-bottom: 10px;
    }
  }
  li{
    @include clear_fix;
    padding: 5px 0;
    i{
      margin-right: 15px;
      color: #999999;
      font-size: 18px;
      display: inline-block;
      @include transform-translateY(2px);
    }
    .section-title{
      font-size: 14px;
      color: #666666;
      margin-right: 10px;
      display: inline-block;
      width: 70px;
      float: left;
      text-align: right;
    }
    .section-title-content{
      display: inline-block;
      word-break: break-all;
      font-size: 14px;
      color: #999999;
    }
    .section-title-content-else{
      display: inline-block;
      width: calc(100% - 50px);
      font-size: 14px;
      color: #999999;
    }
    .section-text{
      width: calc(100% - 70px);
      float: right;
      background-color: #f4f4f4;
      padding: 8px 10px;
      word-wrap: break-word;
    }
  }
  .staff-personal-profile{
    p{
      color: #666666;
      font-size: 14px;
      text-indent: 14px;
    }
  }
}


.renewal-box{
  padding-left: 92px;
  position: relative;
  margin-top: 10px;
  &:before{
    content: "";
    display: block;
    width: 6px;
    height: 100%;
    background-color: #e6e6e6;
    position: absolute;
    top: 0;
    left: 94px;
  }
}
.photo-box{
  margin: 10px 0;
  @include clear_fix;
  .upload-photo{
    cursor: pointer;
    float: left;
    margin-left: 22px;
    margin-top: 10px;
    width: 120px;
    height: 80px;
    border: solid 1px #979797;
    img{
      @include img;
    }
  }
}
.status-box{
  @include clear_fix;
  .status-content{
    width: calc(100% - 30px);
    float: left;
    font-size: 14px;
    color: #666666;
    margin-left: 22px;
    line-height: 15px;
  }
}
.renewal-time{
  float: right;
  color: #999999;
  margin-top: 3px;
}
