.kb-chart{
  margin-bottom: 60px;
}
.chart-box{
  display: inline-block;
  position: relative;
  /*cursor: pointer;*/
  min-width: 120px;
  >a{
    display: inline-block;
    &:hover{
      i,span{
        color: #2ea1f8;
        cursor:pointer;
      }
    }
  }
}
.recharts-wrapper{
  position: relative;
  cursor: pointer !important;
}
.chart-content{
  position: absolute;
  max-width: 115px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  @include p-center;
  span{
    display: block;
    margin-bottom: 5px;
    &:nth-child(1){
      font-size: 28px;
      color: #2ea1f8;
    }
    &:nth-child(2){
      font-size: 14px;
      color: #666666;
    }
    &:nth-child(3){
      font-size: 14px;
      color: #999999;
    }
  }
}

.statistic-box{
  background-color: #ffffff;
  padding: 10px;
  display: inline-block;
  /*cursor: pointer;*/
  .header{
   margin-bottom: 5px;
  }
  .number{
    font-size: 28px;
    font-weight: 500;
    color: #4c4c4c;
  }
  .unit{
    font-size: 16px;
    color: #999;
    margin-left: 3px;
  }
  .title span{
    color: #999999;
    margin-bottom: 5px;
  }
  .name{
    width:100%;
    span{
      color: #999999;
      float:left;
      height:20px;
      line-height:20px;
    }
    div{
      float:left;
    }
  }
  .explain{
    margin-left: 5px;
    display: inline-block;
    width: 14px;
    height: 14px;
    background:  url("#{$dev-url}explain.png") no-repeat center;
    background-size: 100% 100%;
    transform: translateY(2px);
  }
}
