.nav-section-header-title {
  select {
    background: url("#{$dev-url}deg-bottom.png") no-repeat scroll calc(100% - 13px) transparent;
    appearance: none;
    -moz-appearance: none;
    border-radius: inherit;
    -webkit-appearance: none;
    border: 1px solid $kb-form-color5;
    padding: 10px 29px 10px 13px;
    font-size: 14px;
    margin-right: 30px;
    color: #999999;

    option {
      border-radius: inherit;
    }
  }
}

.record-title {
  font-size: 14px;
  color: #999999;
  margin-right: 10px;
}

.drop-down-select {
  display: inline-block;

  select {
    background: url("#{$dev-url}deg-bottom.png") no-repeat scroll calc(100% - 13px) transparent;
    appearance: none;
    -moz-appearance: none;
    border-radius: inherit;
    -webkit-appearance: none;
    border: 1px solid $kb-form-color5;
    padding: 10px 29px 10px 13px;
    font-size: 14px;
    margin-right: 30px;
    color: #999999;

    option {
      border-radius: inherit;
    }
  }
}

.company-logo-all {
  @include clear_fix;
  margin-top: 30px;

  .nav-section-action-bar {
    .action-bar-status {
      margin-left: 10px;
    }
  }
}

.company-message {
  width: 246px;
  height: 136px;
  float: left;
  background-color: #fff;
  margin-right: 29px;
  margin-bottom: 30px;
  border: 1px solid #E3ECF4;
  cursor: pointer;
  display: block;
  position: relative;
  border-radius: 6px;
  padding: 29px 30px;

  &:hover {
    border-color: #5fa7ec;
  }

  &:nth-child(4n) {
    margin-right: 0;
  }

  .tag-img {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
  }

  .company-logo-position {
    position: relative;

    .company-logo {
      margin-right: 11px;

      img {
        object-fit: contain;
        float: left;
        width: 52px;
        height: 52px;
        margin-bottom: 10px;
        border-radius: 50%;
      }
    }
  }

  .org_info {
    text-align: left;

    >div {
      width: 120px;
    }
  }

  .org_name {
    font-size: 15px;
    @include text-over(200px);
    display: block;
    margin-bottom: 3px;
  }

  .desk_info {
    font-size: 10px !important;
  }

  .industry_name {
    color: #999;
    font-size: 11px !important;
    margin-bottom: 8px;
  }

  .enter-status {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    padding: 2px 5px;
    font-size: 12px;
    background-color: #dd5a55;
    color: #ffffff;
  }
}

//拼音快捷搜索
.search-topic {

  .letter-str,
  .tag-str {
    color: #354052;
    font-size: 14px;
    margin-left: 15px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    text-align: center;
    line-height: 20px;
    position: relative;

    &:hover {
      color: #279BF3;
    }

    &:active {
      color: #279BF3;
    }
  }

  .letter-str {
    width: 20px;
    border-radius: 100%;
  }

  .tag-str {
    padding: 0 5px;
    border-radius: 3px;
  }

  .letter-str-all {
    color: #354052;
    font-size: 12px;
    margin-left: 15px;
    cursor: pointer;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    border-radius: 3px;
    padding: 0 6px;
  }
}

.topic-click,
.letter-str-all_active {
  background-color: #279BF3;
  color: #ffffff !important;
}