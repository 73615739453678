//头部
.lr-header{
  @include lr-header;
  .lr-logo{
    display: inline-block;
    height: 100%;
    img {
      height: 100%;
    }
  }
}
.box1-header{
  text-align: center;
  margin-bottom: 50px;
}
.input-6 .verification-code{
  border-right: none;
}
.input-6 .verification-code:focus{
  border-right: none;
}
.send-code{
  cursor: pointer;
  @include  a-block;
  width: 40%;
  padding: 10px;
  color: $color4;
  border: 1px solid $color4;
}
.register-agree{
  text-align: center;
  margin-bottom: -5px;
  input{
    width: 12px;
    height: 12px;
  }
  span{
    font-size: 12px;
    color: $color1;
  }
}
.register-exist-account{
  text-align: center;
  a{
    color: $color4;
  }
}
.lr-footer{
  @include lr-footer;
}
//错误提示
.server-error{
  padding: $p-value;
  background-color: $color6;
  span{
    color: $color5;
    font-size: 12px;
    margin-left: 10px;
  }
  a{
    position: absolute;
    color: $color5;
    right: 10px;
  }
}
.lr-error{
  color: $color6 !important;
  @include lr-error-p;
}
.lr-warning{
	color: #fd9a18 !important;
	font-size: 12px;
	@include lr-error-p;
  }
.comment-box1 .kb-input-error input{
  @include lr-error-input;
}
.comment-box1 .kb-input-required input{
  @include lr-required-input;
}
