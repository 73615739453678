.border-none{
  border: none !important;
}
.border{
  border: 1px solid $kb-color3;
}
.page-break{
  margin-top: 30px;
  //position: absolute;
  //bottom: 30px;
  ul{
    @include clear_fix;
  }
  .border-none{
	a {
		color: #354052 !important;
		border: 1px solid $kb-color34 !important;
	}
    // &:hover,&:active{
    //   background-color: #FFFFFF;
    //   a{
    //     color: #999999;
    //   }
    // }
    //&:active{
    //  background-color: #FFFFFF;
    //}
  }
  li{
    cursor: pointer;
    float: left;
    margin-left: -1px;
	border: 1px solid $kb-color34;
	&:first-child {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
	&:last-child {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
    a{
      display: block;
      padding: 7px 15px;
      font-size: 12px;
	  color: rgba(53,64,82,.5) ;
	  font-weight: 600;
    }
    &:hover{
      a{
        color: #354052;
      }
    //   background-color: $kb-color28;
    //   border: solid 1px $kb-color28;
    }
  }
  .previous-page,.next-page{
    a{
      display: block;
	  padding: 7px 15px !important;
	  font-weight: 600;
      i{
        font-weight: 500;
        transform: translateY(1px);
        display: inline-block;
      }
    }
    &:hover{
      a{
        color: #354052 ;
      }
    }
    a{
      color: #354052;
    }
  }
  .next-page{
    a{
      i{
        margin-left: 2px;
      }
    }
  }
  .previous-page{
    a{
      i{
        margin-right: 2px;
      }
    }
  }
  .disabled-page{
    border: solid 1px #e6e6e6;
    a{
	  color: #ddd;
	  cursor: not-allowed;
    }
    &:hover{
      border: solid 1px #e6e6e6;
      background-color: #FFFFFF;
      a{
        color: #ddd;
      }
    }
  }
  a{
    i{
      font-size: 12px;
    }
  }
}
