.activity-container{
  border-bottom: 1px solid $kb-color3;
  padding: 20px 0;
  display: flex;
  position: relative;
  >img{
    margin-right: 30px;
    width: 300px;
  }
  .activity-status{
    display: inline-block;
    padding: 0 6px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    border-radius: 2px;
  }
  .activity-status_future, .activity-status_active{
    background-color: #dafbc8;
    color: #03d20b;
  }
  .activity-status_upcoming{
    background-color: rgb(255, 238, 193);
    color: #ffba00;
  }
  .activity-status_in_progress{
    background-color: rgb(214, 231, 248);
    color: #2ea1f8;
  }
  .activity-status_ended, .activity-status_cancelled{
    background-color: #c8c8c8;
    color: #9b9b9b;
  }
  .activity-status_draft{
    background-color: rgb(255, 238, 193);
    color: #ffba00;
  }
  &:last-child{
    border-bottom: none;
  }
}

.activity-title{
  font-size: 20px;
  font-weight: 600;
  color: #404040;
  margin-bottom: 20px;
  max-width: 425px;
  cursor: pointer;
  &:hover{
    color: #2ea1f8;
  }
}
.activity-date{
  font-size: 15px;
  margin-right: 10px;
  vertical-align: middle;
}
.activity-date-status{
  margin-bottom: 16px;
  color: #646464;
}
.activity-price-capacity{
  position: absolute;
  top: 20px;
  right: 0;
  text-align: right;
  .activity-price{
    margin-bottom: 10px;
    color: #666666;
    span{
      color: #ff5a60;
      font-size: 24px;
      margin-left: 5px;
    }
  }
  .activity-capacity{
    color: #333333;
  }
}

.activity-tag{
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0 30px;
  >span{
    margin-right: 15px;
  }
  .tag-list{
    >span{
      display: inline-block;
      padding: 5px 10px;
      border-radius: 2px;
      color: #999999;
      border: 1px solid $kb-color3;
      text-align: center;
      margin-right: 15px;
      cursor: pointer;
      &:hover{
        background-color: #2ea1f8;
        color: #ffffff;
        border-color: #2ea1f8;
      }
    }
  }
  .tag-active{
    background-color: #2ea1f8 !important;
    color: #ffffff !important;
    border-color: #2ea1f8 !important;
  }
}

.activity-chart{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 150px;
  border-bottom: 1px solid $kb-color3;
}

.activity-space_between {
  margin-bottom: 18px;
  color: #969696;
}

.act-info-tag {
  font-size: 12px;
  padding: 5px 12px;
  background-color: #d6e7f8;
  color: #2ea1f8;
  border-radius: 4px;
  margin-right: 7px;
}

.activity-tickets-list{
  display: flex;
  align-items: center;
  color: #969696;
  .activity-ticket{
    margin-right: 30px;
    display: inline-block;
    >span:first-child{
      margin-right: 8px;
    }
  }
}

.activity-form_date{
 .task-deg{
   float: right;
   margin-top: 6px;
 }
}

.activity-stars{
  display: flex;
  align-items: center;
  >span{
    margin-right: 5px;
    color: #d8d8d8;
  }
  .star_active{
    color: #FFBA00;
  }
}

.activity-details{
  display: flex;
  align-items: center;
  .RichEditor-root, .fr-box{
    flex: 1;
  }
  .DraftEditor-root{
    height: 382px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .phone-show{
    padding: 30px;
    border: 1px solid $kb-color3;
    border-left: none;
    width: 375px;
    height: 439px;
    box-shadow: 0 1px 3px rgba(0,0,0,.12), 0 1px 1px 1px rgba(0,0,0,.16);
    margin-top: 1px;
    img{
      display: block;
      margin-left:  auto;
      margin-right: auto;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
      max-width: 315px;
    }
    figure{
      margin: 0;
      padding: 0;
    }
  }
}
.tag-title_display{
  >span:first-child{
    display: none;
  }
  .selected_tags {
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 36px;
    .tag-in-form {
      display: inline-block;
      position: relative;
      margin-right: 25px;
      // transition: width 0.2s linear;
      >span {
        width: auto;
        padding: 4px 6px;
        background-color: #d6e7f8;
        color: #2ea1f8;
        border-radius: 4px;
        cursor: pointer;
      }
      .icon-close {
        position: absolute;
        right: 0px;
        transform: translateY(-50%);
        top: 50%;
        z-index: -1;
        padding: 3px 6px 3px 0;
        background-color: #d6e7f8;
        color: #2ea1f8;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        opacity: 0;
        transition-property: opacity,right;
        transition-duration: 0.2s;
        transition-timing-function: linear;
        cursor: pointer;
      }
      &:hover {
        // width: 61px;
        .icon-close {
          opacity: 1;
          right: -22px;
          z-index: 10
        }
      }
    }
  }
  .add-tags {
    float: left;
  }
}

.guest-card{
  display: flex;
  position: relative;
  padding: 20px;
  width: 338px;
  margin-bottom: 20px;
  float: left;
  height: 170px;
  margin-right: 30px;
  border: 1px solid $kb-color3;
  overflow: hidden;
  border-radius: 4px;
  &:nth-child(3n){
    margin-right: 0;
  }
  .guest-message{
    flex: 1;
    margin-left: 20px;
    overflow: hidden;
  }
}
.activity-guests{
  .guest-card{
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    width: 253px;
    margin-bottom: 20px;
    float: left;
    height: 100px;
    margin-right: 30px;
    border: 1px solid $kb-color3;
    overflow: hidden;
    border-radius: 4px;
    &:nth-child(3n){
      margin-right: 0;
    }
    .guest-message{
      flex: 1;
      margin-left: 10px;
    }
  }
  .guest-box{
    margin-top:20px;
    &:nth-child(3n){
      margin-right: 0;
    }
    .guest-message{
      span{
        display:block;
        font-size: 12px;
        color: #666666;
        padding-bottom:5px;
        &:nth-child(1){
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
      }
    }
  }
}


.textThreeEllipsis {
  position: relative;
  height: 75px;
  overflow : hidden;
/*  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;*/
}

.textThreeEllipsis::after{
  content: '...';
  position: absolute;
  bottom: 2px;
  right: -1px;
}
.act_tips{
  position: absolute;
  top: 55px;
  display: inline-block;
  white-space: nowrap;
  color: #999;
}
.activity-main{
  display:flex;
  align-items: center;
  .act_main_img{
    img{
      width:342px;
    }
  }
  .activity-content{
    padding-left: 30px;
    margin-top: -20px;
  }
}
.edit_act_tag{
  span{
    display:block;
    color: rgb(153, 153, 153);
    font-size: 12px;
    margin-left:122px;
  }
}
.c_cursor{
  input{
    cursor:default;
  }
}

.activity-status{
  display: inline-block;
  padding: 0 6px;
  height: 18px;
  font-size: 12px;
  line-height: 18px;
  border-radius: 2px;
  margin-left:5px;
}
.activity-status_future, .activity-status_active{
  background-color: #dafbc8;
  color: #03d20b;
}
.activity-status_upcoming{
  background-color: rgb(255, 238, 193);
  color: #ffba00;
}
.activity-status_in_progress{
  background-color: rgb(214, 231, 248);
  color: #2ea1f8;
}
.activity-status_ended, .activity-status_cancelled{
  background-color: #c8c8c8;
  color: #9b9b9b;
}
.activity-status_draft{
  background-color: rgb(255, 238, 193);
  color: #ffba00;
}

.list-item {
  position: relative;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  &:hover {
    background-color: #F5F5F5;
  }
  img {
    width: 100px;
    height: 55px;
  }
  .item-message {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .item-title {
      color: #666;
      padding-bottom: 10px;
    }
    .item-time {
      color: #999;
      font-size: 12px;
      .icon-time {
        margin-right: 10px;
      }
    }
  }
}
