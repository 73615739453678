.station-all{
  @include clear_fix;
  padding-top: 20px;
  border-top: 1px solid $kb-color3;
  >div{
    float: left;
    margin-bottom: 16px;
    margin-right: 16px;
    &:nth-child(8n){
      margin-right: 0;
    }
  }
}
.desk-box{
  position: relative;
  .station-popup-box{
    display: none;
    left: 50%;
    @include transform-translateX(-50%);
  }
  &:hover{
    .station-popup-box{
      display: block;
    }
  }
}
.station-box{
  position: relative;
  display: block;
  width: 88px;
  height: 84px;
  cursor: pointer;
  text-align: center;
  border-radius: 4px;
  &:hover{
    box-shadow: 0 0 4px 0 rgba(0,0,0,.5);
  }
  .station-popup-box {
    display: none;
    span {
      color: initial;
    }
    i {
      color: initial;
    }
  }
  &:hover .station-popup-box {
    display: initial;
  }
  >span{
    font-size: 24px;
    margin-top: 18px;
    margin-bottom: 5px;
    display: inline-block;
    font-weight: 500;
  }
  .station-message{
    img{
      width: 16px;
      height: 16px;
      @include radius(50%);
      vertical-align: middle;
    }
    span{
      font-size: 12px;
    }
  }
  .station-user{
    display: none;
  }
  &:hover{
    .station-status{
      display: none;
    }
    .station-user{
      display: inline-block;
    }
  }
  .selected-mark {
	display: inline-block;
	position: absolute;
	top: 0;
	right: 0;
	width: 16px;
	height: 16px;
	border-bottom-left-radius: 4px;
	background-color: #354052;
	i {
		color: #fff;
		font-size: 12px;
		position: absolute;
		top: 0;
		right: 0;
	}
  }
}

//选中
.station-select{
  span,i{
    color: #ffffff;
  }
}

/*
free: （所有工位都）空闲,
partial_free: 部分工位空闲,
reserved: 已预定,
assigned: 已分配,
expiring: （租约）即将到期
*/

//占用assigned
.station-occupy{
  background-color: #9ED562 !important;
  span,i{
    color: #ffffff;
  }
  .station-message{
    span{
      color: #ffffff;
    }
  }
}

// 合同选中工位
.station-contract-select{
  background-color: #2ea1f8 !important;
  span,i{
    color: #666666 !important;
  }
}

//未使用free
.station-free{
  background-color: #acacac !important;
  color: #ffffff;
}
.station-empty{
  background-color: #ffffff !important;
  color: #354052 !important;
  border: 1px solid $kb-color3;
  span,i{
    color: #354052;
  }
  .station-message{
    span{
      color: #354052;
    }
  }
  .station-status{
    display: inline-block !important;
  }
}
//部分空闲partial_free
.station-partial_free{
  background-color: #ecbc23;
  color: #ffffff;
}
//预定reserved
.station-reserved{
  background-color: #8DA9FF !important;
  span,i{
    color: #ffffff;
  }
  .station-message{
    span{
      color: #ffffff;
    }
  }
}
// 已签订 approving
.station-approving{
  background-color: #ecbc23 !important;
  span,i{
    color: #ffffff;
  }
  .station-message{
    span{
      color: #ffffff;
    }
  }
}
//到期expiring
.station-expiring{
  background-color: #F66E73 !important;
  span,i{
    color: #ffffff;
  }
  .station-message {
    span {
      color: #ffffff;
    }
  }
}
//弹出
.station-popup-box{
  position: absolute;
  width: 400px;
  z-index: 10;
  left: 50%;
  @include transform-translateX(-50%);
  cursor: default;
  transition: all .3s linear;
}
.station-popup{
  background-color: #FFFFFF;
  border: 1px solid $kb-color3;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
  .basic-message{
    margin: 10px 0;
    li{
      @include clear_fix;
      margin-bottom: 15px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  .group{
    width: 50%;
    float: left;
    span{
      margin-left: 10px;
      font-weight: 500;
    }
  }
  .remark{
    textarea{
      width: 100%;
      max-height: 70px;
      border: none;
      padding: 10px 0;
    }
    p{
      margin: 10px 0;
    }
  }
  .footer{
    text-align: center;
  }
}

.station-box-selected {
	background-color: RGBA(53, 64, 82, 0.3) !important;
	color: #354052 !important;
	border: 3px solid #354052;
}


//工位数比例条
.proportion-bar{
  width: 100px;
  height: 6px;
  background-color: #e6e6e6;
  border-radius: 6px;
  margin-top: 10px;
  position: relative;
  .proportion-bar_show{
    position: absolute;
    height: 6px;
    top: 0;
    left: 0;
    border-radius: 6px;
  }
  .color-full{
    background-color: rgba(72,137,200,100);
  }
  .color-free{
    background-color: #73bb4b;
  }
}

.area-organization, .area-contract{
  a{
    color: #2ea1f8;
    cursor: pointer;
  }
}

// 分区面积列表展示
@mixin area-size_text(){
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.area-floor_name{
  font-family: "PingFang SC";
  font-size: 36px;
  color: #727272;
}

.area-floor_icon{
  font-family: "PingFang SC";
  font-size: 14px;
  color: #727272;
  margin-top: 5px;
}

.area-list_default{
  height: 100px;
  float: left;
  margin-bottom: 10px;
  margin-right: 5px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .area-size_name{
    font-weight: 500;
    font-size: 16px;
    @include area-size_text;
  }
  .area-size_data{
    font-size: 10px;
    @include area-size_text;
  }
  .area-size_company{
    font-size: 10px;
    @include area-size_text;
    margin-bottom: 5px;
  }
}

.area-list_disabled{
  background-color: #E6E6E6 !important;
  color: #666666 !important;
  border: 1px solid #E6E6E6 !important;
  &:hover{
    background-color: #E6E6E6 !important;
    color: #666666 !important;
  }
  &:active{
    background-color: #E6E6E6 !important;
    color: #666666 !important;
  }
}

.area-size_free{
  background-color: #ffffff;
  border: 1px solid #e0e7f1;
  color: #666;
  &:hover{
    background-color: #F1F6FC;
  }
  &:active{
    background-color: #DFE7F0;
  }
}

.area-size_reserved{
  background-color: #8DA9FF;
  color: #ffffff;
  &:hover{
    background-color: #6988EC;
  }
  &:active{
    background-color: #5C7AD9;
  }
}

.area-size_assigned{
  background-color: #9ED562;
  color: #ffffff;
  &:hover{
    background-color: #8BC34F;
  }
  &:active{
    background-color: #7AB23E;
  }
}

.area-size_expiring{
  background-color: #F66E73;
  color: #ffffff;
  &:hover{
    background-color: #E04C51;
  }
  &:active{
    background-color: #CE4348;
  }
}

.area-size_partial_free{
  background-color: #edffda;
  color: #666;
  &:hover{
    background-color: #C4F3AF;
  }
}

.area-type-tag-block {
  border-radius: 2px;
  padding: 2px 10px;
  display: inline-block;
  text-align: center;
  font-size: 10px;
  color: #fff;
  background-color: #009EFB;
}

.area-state-block {
  border-radius: 2px;
  padding: 2px 10px;
  display: inline-block;
  text-align: center;
  font-size: 10px;
  color: #333;
  background-color: #fff;
  border: 1px solid #333;
}
.area-state-color-free {
  color: #009EFB !important;
  border-color: #009EFB !important;
}
.area-state-color-assigned {
  color: #55CE6A !important;
  border-color: #55CE6A !important;
}
.area-state-color-reserved {
  color: #EE8418 !important;
  border-color: #EE8418 !important;
}
.area-state-color-approving {
  color: #ecbc23 !important;
  border-color: #ecbc23 !important;
}
.area-state-color-disabled {
  color: #999 !important;
  border-color: #999 !important;
}

.area-table-title {
  font-size: 11px;
  color: #7F8FA4;
}
.area-table-content {
  font-size: 13px;
  color: #354052;
  font-weight: 500;
}

// 车位相关样式 ===================================================
.filters-box {
	padding: 12px 20px;
	border: 1px solid #DFE2E5;
	border-radius: 4px;
	background-color: #FAFBFC;
	display: flex;
	align-items: center;
	.filter-label {
		font-size: 13px;
		color: #7F8FA4;
	}
	.single-filter {
		display: inline-block;
		padding: 4px 12px;
		border: 1px solid #D5DCE6;
		border-radius: 3px;
		background-color: #fff;
		color: #333C48;
		font-size: 13px;
		margin-left: 4px;
		i {
			margin-left: 10px;
			color: #C7D2E0;
		}
		&:hover {
			cursor: pointer;
			background-color: #eceff3;
			i {
				color: #E63B41;
			}
		}
	}
	.clear-filters {
		color: #54667A;
		margin-left: 10px;
		i {
			color: #C8D2DF;
		}
		&:hover {
			cursor: pointer;
			color: #E63B41;
			i {
				color: #E63B41;
			}
		}
	}
}

.parking-spaces-container {
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: flex-start;
	.parking-block {
		transition: box-shadow .3s linear;
		position: relative;
		display: inline-block;
		width: 84px;
		height: 117px;
		border: 1px solid #DFE2E5;
		background-color: #fff;
		border-radius: 4px;
		margin-right: 18px;
		margin-bottom: 27px;
		text-align: center;
		.parking-serial {
			margin-top: 28px;
			font-size: 22px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.parking-state {
			margin-top: 12px;
			font-size: 12px;
		}
		.selected-mark {
			display: inline-block;
			position: absolute;
			top: 0;
			right: 0;
			width: 16px;
			height: 16px;
			border-bottom-left-radius: 4px;
			background-color: #354052;
			i {
				color: #fff;
				font-size: 12px;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
		&:hover {
			cursor: pointer;
			box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
			.parking-popup-box {
				height: auto;
				overflow: inherit;
				opacity: 1;
				// transition-delay: .5s;
			}
		}
		.hover-transition {
			position: absolute;
			width: 100%;
			height: 20px;
			left: 0;
			top: -20px;
			z-index: 9;
		}
		// 弹出的 box
		.parking-popup-box {
			box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.35);
			background-color: #fff;
			border-radius: 4px;
			position: absolute;
			left: 50%;
			top: 0;
			transform: translate(-50%, calc(-100% - 20px));
			z-index: 99;
			width: 400px;
			height: 0px;
			overflow: hidden;
			opacity: 0;
			transition-property: opacity;
			transition-duration: 0.3s;
			transition-timing-function: linear;
			.box-arrow {
				width: 20px;
				height: 20px;
				box-shadow: -2px -2px 6px -2px rgba(0, 0, 0, 0.35);
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translate(-50%, 50%) rotate(225deg);
				background-color: #f0f3f8;
			}
		}
	}
	.parking-block-free {
		background-color: #fff;
		color: #354052;
		border-color: #DFE2E5;
	}
  .parking-block-approving {
    background-color: rgba(236, 188, 35, 0.8);
    color: #fff;
    border-color: rgba(236, 188, 35, 0.6);
  }
	.parking-block-reserved {
		background-color: rgba(141, 169, 255, 0.8);
		color: #fff;
		border-color: rgba(141, 169, 255, 0.8);
	}
	.parking-block-assigned {
		background-color: rgba(57,181,74,0.6);
		color: #fff;
		border-color: rgba(57,181,74,0.6);
	}
	.parking-block-expiring {
		background-color: rgba(230,59,65,0.6);
		color: #fff;
		border-color: rgba(230,59,65,0.6);
	}
	.parking-block-disabled {
		background-color: #EFF3F6;
		color: rgba(53,64,82,0.3);
		border-color: #EFF3F6;
	}
	.parking-block-active {
		border: 4px solid #354052 !important;
		background-color: rgba(53,64,82,0.3) !important;
	}
}

.popup-container {
	text-align: left;
	.parking-detail {
		padding: 20px 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #DFE2E5;
		.parking-title {
			font-size: 16px;
			color: #354052;
			.parking-type {
				display: inline-block;
				border-radius: 3px;
				color: #fff;
				background-color: #36AF47;
				font-size: 10px;
				padding: 3px 8px;
				margin-left: 10px;
			}
		}
		.parking-area {
			color: #7F8FA4;
			font-size: 12px;
			margin-top: 8px;
			>span {
				display: inline-block;
				padding-right: 10px;
				margin-right: 10px;
				border-right: 1px solid #D8D8D8;
				&:last-child {
					padding-right: 0;
					border-right: 0;
					border-right: none;
				}
			}
		}
		.icon-edit {
			color: #7F8FA4;
		}
	}
	.parking-state-info {
		padding: 15px 30px;
		color: #7F8FA4;
		font-size: 12px;
		border-bottom: 1px solid #DFE2E5;
		.info-line {
			margin-bottom: 8px;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.parking-operation {
		padding: 15px 30px;
		text-align: center;
		background-color: #F0F3F8;
	}
}

.parking-area-btn {
	width: 100%;
	height: 36px;
	border-radius: 4px;
	color: #fff;
	font-size: 13px;
	padding: 8px 10px;
	background-color: #279BF3;
	cursor: pointer;
	position: relative;
	.icon-close {
		display: none;
		width: 22px;
		height: 22px;
		position: absolute;
		right: 0;
		top: 0;
		-webkit-transform: translate(50%, -50%);
		-ms-transform: translate(50%, -50%);
		transform: translate(50%, -50%);
		background-color: #FF3131;
		color: #fff;
		border: 2px solid #fff;
		border-radius: 50%;
		font-size: 12px;
		-ms-flex-pack: center;
		justify-content: center;
		-ms-flex-align: center;
		align-items: center;
	}
	&:hover {
		.icon-close {
			display: inline-flex;
		}
	}
}

.parking-car-plate-add-btn {
	width: 100%;
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px dashed #C8D2DF;
	border-radius: 4px;
	color: #279BF3;
	font-size: 12px;
	&:hover {
		cursor: pointer;
		border: 1px solid #279BF3;
	}
}

.area-features {
	
}

.area-images {
	margin-top: 30px;
	display: flex;
	flex-wrap: wrap;
	>img {
		display: inline-block;
		width: 154px;
		height: 103px;
		margin-right: 10px;
		margin-top: 10px;
		&:nth-child(5n) {
			margin-right: 0;
		}
	}
}

.detail-info-container {
		padding: 25px 0;
		display: flex;
		flex-wrap: wrap;
		>.info-block {
			display: inline-block;
			width: 33%;
			height: 30px;
			line-height: 30px;
			font-size: 12px;
			>.label {
				display: inline-block;
				width: 25%;
				color: #7F8FA4;
			}
			>.value {
				color: #354052;
				font-weight: bold;
			}
		}
	}

.deletable-block {
	position: relative;
	display: inline-block;
	.icon-close {
		position: absolute;
		right: -16px;
		display: none;
	}
	&:hover {
		.icon-close {
			display: inline-block;
		}
	}
}