.lock-action{
  width: 40px;
  height: 40px;
  @include d-flex;
  align-items: center;
  justify-content: center;
  @include radius(50%);
  i{
    font-size: 20px;
    color: #ffffff;
    margin-right: 0;
  }
}
//开门记录热点图
.lock-hotspot{
  display: inline-block;
  width: 38px;
  height: 38px;
  margin-right: 2px;
  border-radius: 4px;
  &:last-child{
    margin-right: 0;
  }
}
.lock-filter-time{
  cursor: pointer;
  &:hover{
    background-color: rgb(6, 40, 170) !important;
  }
}

.lock-hotspot_line{
  margin-top: 2px;
  @include d-flex;
  align-items: center;
  .lock-date{
    margin-right: 5px;
    display: inline-block;
    width: 84px;
    text-align:center;
  }
}

.lock-time{
  padding-left: 86px;
  >span{
    display: inline-block;
    width: 38px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    margin-right: 2px;
    font-size: 12px;
    &:last-child{
      margin-right: 0;
    }
  }
}


.lock_title{
  font-size: 18px;
  padding: 10px;
  border-bottom: 1px solid #aabac6;
}

.member{
  border: 1px solid #e0e7f1;
  margin: 20px 0px;
  /*overflow: hidden;*/
  padding: 15px 0;
  overflow-y: scroll;
//   max-height: 200px;
}

.member_special{
  border: 1px solid #e0e7f1;
  margin: 20px 0;

  padding: 15px 0;
  max-height: 200px;
}

.lock{
  overflow: hidden;
  height: auto;
}

.lock_member{
  overflow-y: scroll;
  overflow: hidden;
  margin-bottom: 15px;
}

.member_name{
  overflow: hidden;
  padding: 0 15px;
  margin-bottom: 10px;
  float: left;
  div{
    border-radius: 15px;
    background: #f4f6f7;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    p{
      width: 20px;
      height: 20px;
      background: #aabac6;
      border-radius: 50%;
      margin-right: 5px;
    }
    .hidden{
      display: inline-block;
      width: 65px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      box-sizing: border-box;
    }
  }
}

.date{
  display: flex;
  align-items: center;
  margin-top: 20px;
  p{
    width: 50%;
    padding: 10px;
    span{
      margin-left: 5px;
    }
  }
}

.lock-group-block {
	position: relative;
	border: 1px solid #E6EAEE;
	border-radius: 4px;
	overflow: hidden;
	margin-bottom: 20px;
	&:hover {
		background-color: rgba(230,234,238,.3);
	}
	.status-bar {
		position: absolute;
		top: 0;
		left: 0;
		width: 4px;
		height: 100%;
		background-color: #1A91EB;
	}
	.status-bar-active {
		background-color: #39B54A;
	}
	.block-container {
		.group-summary {
			padding: 22px 30px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-align: left;
			.group-name {
				flex: 2;
			}
			.group-lock-num {
				flex: 1;
				text-align: left; 
			}
			.group-operations {
				flex: 1;
				text-align: right;
			}
			.value {
				font-size: 16px;
				font-weight: 500;
				color: #354052;
				line-height: 22px;
			}
			.title {
				font-size: 12px;
				color: #7F8FA4;
				line-height: 22px;
			}
		}
		.group-locks {
			border-top: 1px solid #DFE2E5;
			background-color: #FAFBFC;
			padding: 22px 30px 7px;
			.lock-block {
				width: 240px;
				height: 50px;
				border: 1px solid #DFE2E5;
				background-color: #fff;
				border-radius: 4px;
				padding: 6px 14px;
				margin: 0 15px 15px 0;
				text-align: left;
				display: inline-flex;
				justify-content: space-between;
				align-items: center;
				&:nth-child(3n) {
					margin-right: 0;
				}
				.lock-name {
					font-size: 13px;
					font-weight: 500;
					color: #354052;
					line-height: 21px;
				}
				.lock-area {
					font-size: 12px;
					color: #354052;
					opacity: .5;
					line-height: 21px;
				}
			}
		}
		.icon-font {
			color: #C8D2DF;
		}
	}
}

.hover-delete {
	&:hover {
		cursor: pointer;
		color: #E63B41 !important;
	}
}