//上传按钮
@mixin post-btn {
  width: 215px;
  height: 36px !important;
  position: relative;
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid $kb-color14;
  border-radius: 4px;
}

.post-btn-default {
  @include post-btn;

  .post-btn-content {
    position: absolute;
    text-align: center;
    width: 100%;
    margin-top: 8px;

    span {
      color: $color4;
    }

    img {
      display: none;
    }
  }

  a {
    display: none;
    position: absolute;
    right: 15px;
    top: 10px;
    color: #999999;
  }

  &:hover {
    background-color: $kb-color14;

    .post-btn-content {
      span {
        color: #FFFFFF;
      }
    }
  }

  &:active {
    color: $kb-color18;
    background-color: $kb-color18;
  }
}

.post-btn-ing {
  @include post-btn;

  .post-btn-content {
    position: absolute;
    text-align: center;
    width: 100%;
    margin-top: 8px;

    span {
      color: $color4;
    }

    img {
      display: none;
    }
  }

  a {
    display: none;
    position: absolute;
    right: 15px;
    top: 10px;
    color: #999999;
  }

  .post-btn-progress {
    height: 34px !important;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color4;
    @include default_transition(width, 0.5s);
  }
}

.post-btn-success {
  @include post-btn;
  background-color: $color4;

  .post-btn-content {
    position: absolute;
    text-align: center;
    width: 100%;
    margin-top: 8px;

    span {
      color: #fff;
    }

    img {
      display: none;
    }

    i {
      display: inline-block;
      width: 13px;
      height: 16px;
      background: url("#{$dev-url}pact-icon.png") no-repeat;
    }
  }

  a {
    display: inline-block;
    font-size: 18px;
    position: absolute;
    right: 5px;
    color: #fff;
    height: 100%;
    padding-top: 3px;
    z-index: 100;
  }

  .post-btn-progress {
    height: 34px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color4;
    @include default_transition(width, 0.5s);
  }

  &:hover {
    .post-btn-content {
      i {
        background: url("#{$dev-url}pact-icon-hover.png") no-repeat;
      }
    }
  }
}

//边框安妮
.bordered-btn {
  padding: 7px 14px;
  font-size: 14px;
  text-align: center;
  margin-left: 18px;
  color: $kb-color14;
  background-color: inherit;
  border: solid 1px $kb-color14;
  cursor: pointer;
  @include radius(100px);

  i {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    margin-right: 2px;
    @include transform-translateY(0);
  }

  &:hover {
    background-color: $kb-color14;
    color: #FFFFFF;
  }

  &:active {
    color: #FFFFFF;
    background-color: $kb-color16;
    border: solid 1px $kb-color18;
  }
}

.bordered-btn-solid {
  color: #fff;
  background-color: $kb-color14;
  border: solid 1px $kb-color14;

  &:hover {
    background-color: $kb-color16;
    border: solid 1px $kb-color16;
  }

  &:active {
    background-color: $kb-color18;
    border: solid 1px $kb-color18;
  }
}

.bordered-btn-end {
  padding: 7px 14px;
  font-size: 14px;
  text-align: center;
  margin-left: 18px;
  color: #dd5a55;
  background-color: $kb-color15;
  border: solid 1px #dd5a55;
  cursor: pointer;
  margin-bottom: 2px;
  @include radius(100px);

  i {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    margin-right: 2px;
    @include transform-translateY(0);
  }

  &:hover {
    background-color: #dd5a55;
    color: #FFFFFF;
  }

  &:active {
    color: #FFFFFF;
    background-color: rgba(183, 58, 53, 100);
    border: solid 1px rgba(183, 58, 53, 100);
  }
}

.bordered-btn-small,
.bordered-btn-lg {
  padding: 5px 14px;
  font-size: 12px;
  margin-left: 18px;
  color: $kb-color14;
  background-color: $kb-color15;
  border: solid 1px $kb-color14;
  cursor: pointer;
  @include radius(100px);

  i {
    display: inline-block;
    font-size: 16px;
    @include transform-translateY(2px);
  }

  &:hover {
    background-color: $kb-color14;
    color: #FFFFFF;
    border-color: $kb-color14;
  }

  &:active {
    color: #FFFFFF;
    background-color: $kb-color18;
    border: solid 1px $kb-color18;
  }
}

.bordered-btn-lg {
  padding: 10px 23px !important;
  font-size: 14px !important;
}

.bordered-btn-disabled {
  color: $kb-color20;
  background-color: $kb-color21;
  border: solid 1px $kb-color20;
  @include radius(100px);
}



//确定按钮
.certain-btn,
.certain-btn-ms,
.certain-delete-btn {
  background-color: $kb-color14;
  padding: 8px 23px;
  font-size: 14px;
  display: inline-block;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 3px;

  i {
    display: inline-block;
    font-size: 1px;
    font-weight: 600;
    @include transform-translateY(0);
  }

  &:hover {
    background-color: $kb-color16;
  }

  &:active {
    background-color: $kb-color18;
  }
}

.certain-delete-btn {
  background-color: #dd5a55;

  &:hover {
    background-color: #cc4f4a;
  }

  &:active {
    background-color: rgba(183, 58, 53, 100);
  }
}

.certain-btn-ms {
  padding: 5px;
}


.certain-btn-lg {
  padding: 10px 23px;
  font-size: 14px;
  color: white;
  border: none;
  cursor: pointer;
  background-color: $kb-color14;

  i {
    display: inline-block;
    font-size: 16px;
    @include transform-translateY(1px);
  }

  &:hover {
    background-color: $kb-color16;
  }

  &:active {
    background-color: $kb-color18;
  }
}


//取消按钮
.cancel-btn {
  font-size: 14px;
  cursor: pointer;
  color: #999999;

  &:hover {
    color: $kb-color14;
  }
}


//取消按钮
// .certain-btn-disabled{
//   background-color: $kb-color20 !important;
//   //padding: 8px 23px;
//   //font-size: 14px;
//   //display: inline-block;
//   //color: white;
//   //border: none;
//   //cursor: pointer;
// }
//无边框按钮
.add-btn {
  color: #2ea1f8;
  cursor: pointer;
  font-weight: 500;

  i {
    display: inline-block;
    font-size: 16px;
    @include transform-translateY(1px);
  }

  &:hover {
    color: $kb-color14;
  }

  &:active {
    color: $kb-color18;
  }
}

.add-btn-disabled {
  color: $kb-color20;
}

//特别input框
@mixin input-special {
  border: 1px solid $kb-color3;
  padding: 10px 60px 10px 0px !important;
}

.input-dollar-day {
  @include input-special;
  background: url("#{$newdev-url}input-dollar-day.png") no-repeat right center !important;
  background-color: #ffffff !important;
}

.input-dollar-day-else {
  border: 1px solid #E6E6E6;
  background: url("#{$newdev-url}input-dollar-day-else.png") no-repeat right center !important;
  padding: 10px 60px 10px 0px !important;
}

.input-dollar-month {
  border: 1px solid #E6E6E6;
  background: url("#{$newdev-url}input-dollar-month.png") no-repeat right center !important;
  padding: 10px 60px 10px 0px !important;
}

.input-dollar-moth {
  @include input-special;
  background: url("#{$dev-url}input-dollar-month.png") no-repeat right center !important;
  background-color: #ffffff !important;
}

.input-dollar-hour {
  @include input-special;
  background: url("#{$dev-url}input-dollar-hour.png") no-repeat right center !important;
  background-size: 4px 4px;
}

.input-per-month {
  @include input-special;
  background: url("#{$dev-url}month2.png") no-repeat right center !important;
}

.input-per-day {
  @include input-special;
  background: url("#{$dev-url}day2.png") no-repeat right center !important;
}

.input-hour {
  @include input-special;
  background: url("#{$dev-url}time1.png") no-repeat right center !important;
}

.input-frequency {
  @include input-special;
  background: url("#{$dev-url}name1.png") no-repeat right center !important;
}

.input-sheet {
  @include input-special;
  background: url("#{$dev-url}name2.png") no-repeat right center !important;
}

.input-per-hour {
  @include input-special;
  background: url("#{$dev-url}name4.png") no-repeat right center !important;
}

.input-per-time {
  @include input-special;
  background: url("#{$dev-url}name6.png") no-repeat right center !important;
}

.input-per-sheet {
  @include input-special;
  background: url("#{$dev-url}name3.png") no-repeat right center !important;
}

.input-date {
  @include input-special;
  background: url("#{$dev-url}input-date.png") no-repeat right center !important;
}

.input-dollar {
  @include input-special;
  background: url("#{$dev-url}input-dollar.png") no-repeat right center !important;
}

.input-people {
  @include input-special;
  background: url("#{$dev-url}input-people.png") no-repeat right center !important;
}

.input-month {
  @include input-special;
  background: url("#{$dev-url}input-month.png") no-repeat right center !important;
  background-size: 49px 44px;
}

.input-num {
  @include input-special;
  background: url("#{$dev-url}input-num.jpg") no-repeat right center !important;
  background-color: #ffffff !important;
  background-size: 49px 44px;
}

.input-area {
  @include input-special;
  background: url("#{$dev-url}input-area.jpg") no-repeat right center;
  background-size: 49px 44px;
}

.input-percent {
  @include input-special;
  background: url("#{$dev-url}input-percent.png") no-repeat right center;
  background-size: 49px 44px;
}

.input-dollar-area-moth {
  border: 1px solid $kb-color3;
  padding: 10px 90px 10px 0px !important;
  background: url("#{$dev-url}input-dollar-area-month.png") no-repeat right center;
  background-size: 85px 44px;
}

//报修上传图片按钮
.post-img-btn {
  width: 80px;
  height: 35px;
  position: relative;
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid #5fa7ec;

  .post-btn-content {
    position: absolute;
    text-align: center;
    width: 100%;
    margin-top: 8px;

    span {
      color: #5fa7ec;
    }
  }

  .post-btn-progress {
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #5fa7ec;
    @include default_transition(width, 0.5s);
  }
}

//时间按钮
.date-btn {
  @include clear_fix;

  .f-left {
    line-height: 36px;
  }

  .add-btn,
  .cancel-btn {
    margin-right: 10px;
  }
}

//条件搜索按钮
.s-btn {
  padding: 6px 20px;
  border: 1px solid #CCCCCC;
  color: #666;
  background-color: #ffffff;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    color: #ffffff;
    background-color: #5fa7ec;
    border-color: #5fa7ec;
  }

  &:active {
    color: #ffffff;
    background-color: $kb-color18;
    border-color: $kb-color18;
  }
}

//滑动按钮
.slide-btn {
  cursor: pointer;

  .slide-bg {
    display: inline-block;
    @include radius(100px);
    background-color: #0BD377;
    position: relative;
    width: 48px;
    height: 24px;
    @include default_transition(background-color, 0.3s, ease);

    .slide-move {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 50%;
      left: 2px;
      transform: translateY(-50%);
      @include radius(50%);
      background-color: #fff;
      @include default_transition(left, 0.3s, ease)
    }
  }

  .slide-right {
    background-color: #d9d9d9;

    .slide-move {
      left: 28px;
      background-color: #fff;
    }
  }

  .slide-left {
    background-color: #0BD377;

    .slide-move {
      left: 4px;
      background-color: #fff;
    }
  }
}

.slide-btn-disabled {
  cursor: not-allowed;
  opacity: 0.35;
}

//搜索框
.search-input {
  input {
    width: 100%;
    height: 37px;
    border: 1px solid $kb-color3;
    border-radius: 4px;
    padding-left: 42px;
    background: #ffffff url("#{$dev-url}header-search.jpg") no-repeat 15px center;

    &:focus {
      border-color: #A0A0A0;
    }
  }
}

//计数器
.kb-number-input {
  display: inline-block;

  .title {
    color: #999999;
  }
}

.counterBox {
  display: inline-block;
  position: relative;
  width: 120px;

  .subtracter,
  .summator {
    position: absolute;
    display: inline-block;
    width: 28px;
    height: 28px;
    background-color: #e0e7f1;
    color: #848484;
    top: 1px;
    cursor: pointer;
    outline: none;
    font-size: 16px;
    text-align: center;
    line-height: 28px;

    &:active {
      background-color: #999999;
      color: #ffffff;
    }
  }

  >input {
    width: 100%;
    border: 1px solid $kb-color3;
    border-radius: 4px;
    background-color: #ffffff;
    height: 30px;
    padding: 0px 32px;
    text-align: center;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .subtracter {
    left: 0px;
  }

  .summator {
    right: 0px;
  }
}

//空间设置 select
.set-select-box {
  display: inline-block;
  cursor: pointer;

  select {
    background: url("#{$dev-url}deg-bottom.png") no-repeat scroll calc(100% - 15px) transparent;
    appearance: none;
    -moz-appearance: none;
    border-radius: inherit;
    -webkit-appearance: none;
    border: 1px solid $kb-color3;
    border-radius: 4px;
    height: 30px;
    padding: 0px 29px;

    option {
      border-radius: inherit;
    }
  }

  input {
    padding: 6px 15px;
    border: 1px solid $kb-color3;
    width: 90px;

    &:focus {
      border: 1px solid #A0A0A0;
    }
  }
}

//通用 badge
.badge {
  display: inline-block;
  padding: 2px 6px;
  font-size: 12px;
  border-radius: 4px;
  color: #333;
  border: 1px solid #333
}

//财务checkBox
.squaredThree {
  width: 20px;
  position: relative;

  .active:after {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
  }

  .activeEnd:after {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=20);
    opacity: 0.2;
  }
}

.squaredThree label {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border: 1px solid #C1CACA;
  border-radius: 2px;
}

.squaredThree label:after {
  content: '';
  width: 13px;
  height: 7px;
  position: absolute;
  top: 4px;
  left: 3px;
  border: 2px solid #ffffff;
  border-top: none;
  border-right: none;
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

.squaredThree input[type=checkbox] {
  visibility: hidden;
}

.check-end {
  width: 20px;
  height: 20px;
  border: 1px solid #C1CACA;
  position: relative;
  @include radius(2px);

  &:after {
    content: '';
    width: 13px;
    height: 7px;
    position: absolute;
    top: 4px;
    left: 3px;
    border: 2px solid #C1CACA;
    border-top: none;
    border-right: none;
    background: transparent;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
  }
}

//通用checkbox
.label-title {
  color: #999999;
}

.chk_1+span {
  background-color: #FFF;
  border: 1px solid #C1CACA;
  padding: 7px;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  margin-right: 5px;
}

.chk_1:checked+span {
  background-color: #2ea1f8;
  border: 1px solid #2ea1f8;
  color: #ffffff;
}

.chk_1:checked+span:after {
  content: '';
  width: 11px;
  height: 6px;
  position: absolute;
  top: 3px;
  left: 1px;
  border: 2px solid #ffffff;
  border-top: none;
  border-right: none;
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=1);
  opacity: 100;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

//状态按钮
.status-btn {
  display: inline-block;
  padding: 5px 12px;
  border: 1px solid #2ea1f8;
  border-radius: 100px;
  color: #2ea1f8;
  cursor: pointer;
}

.status-btn_active {
  color: #ffffff;
  background-color: #2ea1f8;
}

//合同模板(草稿状态)
.status-scratch-file {
  display: inline-block;
  padding: 5px 12px;
  border: 1px solid #999;
  background-color: #999999;
  border-radius: 100px;
  color: #ffffff;
  cursor: pointer;
}

//hover 切换按钮
.hover-switcher-btn {
  text-align: center;
  cursor: pointer;

  >span {
    padding: 6px 0;
    text-align: center;
  }

  .superstratum {
    display: block;
  }

  .substratum {
    display: none;
    background-color: #2ea1f8;
    color: #ffffff;
  }

  &:hover {
    .superstratum {
      display: none;
    }

    .substratum {
      display: block;
    }
  }
}

.kb-input-disabled {
  background-color: #f3f3f3 !important;
  cursor: not-allowed !important;
}

.operation-btn {
  min-width: 70px;
  height: 36px;
  padding: 0 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 13px;
  font-weight: 500;
  border-radius: 4px;
  background-color: #279BF3;
  border: 1px solid #1585D8;

  &:hover {
    background-color: #1585D8;
  }

  &:active {
    background-color: #227abd;
  }
}

.kb-checked-style-circle {
  width: 20px;
  height: 20px;
  position: relative;
  background-color: #ffffff;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #2EA1F8, #1990EA);
}

.kb-checked-style-circle:after {
  content: '';
  width: 10px;
  height: 6px;
  position: absolute;
  top: 6px;
  left: 5px;
  border: 2px solid #ffffff;
  border-top: none;
  border-right: none;
  background: transparent;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 1;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
}

// 酷办 V2 button 样式=======================================================================================
@mixin c-btn {
  display: inline-block;
  position: relative;
  max-width: 100%;
  margin: 0;
  padding: .5rem 1.25rem;
  transition: all .15s ease-in-out;
  border: 1px solid;
  border-radius: 4px;
  border-color: #1a91eb;
  color: #fff;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  vertical-align: middle;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: #2ea1f8;
  background: linear-gradient(180deg, #2ea1f8, #1990ea);
}

.c-btn-success,
.c-btn-green,
.certain-btn {
  @include c-btn;
  background: #39b54a;
  background: linear-gradient(180deg, #39b54a, #34aa44);
  border-color: #249533;

  &:hover {
    background: linear-gradient(180deg, #34a544, #2f9a3e);
  }
}

.c-btn-blue,
.bordered-btn {
  @include c-btn;
  background: #2ea1f8;
  background: linear-gradient(180deg, #2ea1f8, #1990ea);
  border-color: #1a91eb;
  margin-left: 18px;

  &:hover {
    background: #1a98f7;
    background: linear-gradient(180deg, #1a98f7, #1485db);
  }
}

.c-btn-red,
.c-btn-delete,
.bordered-btn-end,
.certain-delete-btn,
.clear-criteria {
  @include c-btn;
  background: #f95359;
  background: linear-gradient(180deg, #f95359, #d35847);
  border-color: #d35847;
  margin-left: 18px;

  &:hover {
    background: #f83f46;
    background: linear-gradient(180deg, #f83f46, #cf4937);
  }
}

.c-btn-white,
.c-btn-secondary,
.cancel-btn {
  @include c-btn;
  background: #fff;
  background: linear-gradient(180deg, #fff, #f2f4f7);
  border-color: #dfe3e9;
  color: #354052;

  &:hover {
    background: #fcfcfc;
    background: linear-gradient(180deg, #fcfcfc, #eceff3);
    color: #354052;
  }
}

.c-btn-orange,
.c-btn-warning {
  @include c-btn;
  background: #fd9a18;
  background: linear-gradient(180deg, #fd9a18, #f16911);
  border-color: #f16911;

  &:hover {
    background: #fd9104;
    background: linear-gradient(180deg, #fd9104, #e0600d);
  }
}

.c-btn-disabled,
.bordered-btn-disabled,
.certain-btn-disabled {
  @include c-btn;
  border-color: #e6eaee !important;
  background: #f5f8fa !important;
  color: rgba(53, 64, 82, .5) !important;

  &:hover {
    border-color: #e6eaee !important;
    background: #f5f8fa !important;
    color: rgba(53, 64, 82, .5) !important;
    cursor: not-allowed !important;
  }
}

.clear-criteria {
  margin-left: 0;
  margin-top: 5px;

  i {
    margin-right: 5px;
  }
}