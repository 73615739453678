.kb-transfer-box {
  display: flex;
  justify-content: center;
  position: relative;
  border: 1px solid #DFE3E9;
  border-radius: 4px;
  overflow: hidden;
  .cut-off-rule{
    position: absolute;
    width: 1px;
    background-color: #e6e6e6;
    left: 50%;
    transform: translateX(-50%);
  }
  .kb-transfer-container:first-of-type{
    border-right: none;
  }
  .kb-transfer-container:last-of-type{
    border-left: none;
  }
}

.kb-transfer-container{
  flex: 1;
//   border: 1px solid $kb-color3;
}
.kb-transfer-source, .kb-transfer-target{
  padding-left: 20px;
  padding-right: 20px;
  .clear-search-btn{
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    @include radius(50%);
    background-color: #999999;
    color: #ffffff;
    font-size: 12px;
    text-align: center;
    line-height: 15px;
    top: 10px;
    right: 5px;
    cursor: pointer;
  }
}

.kb-transfer-convert {
  flex: 0 0 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  >span{
    display: block;
    width: 20px;
    height: 20px;
    @include radius(5px);
    border: 1px solid $kb-color3;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    &:first-child{
      margin-right: 5px;
    }
  }
  .active{
    background-color: #2ea1f8;
    color: #ffffff;
  }
}

.kb-transfer-check_all {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg,rgba(244,247,250,1),rgba(255,255,255,1));
  color: #354052;
  font-weight: 500;
  border-bottom: 1px solid #E6EAEE;
  input[type='checkbox'] {
    cursor: pointer;
  }
  .show-count {
    font-size: 14px;
  }
  .title{
    font-size: 14px;
    margin: 0 5px;
  }
}

.first-tier{
  padding: 10px 0;
  border-top: 1px solid #F2F3F5;
  &:first-child {
	  border-top: none;
  }
  .toggle-trans-btn{
    margin-left: 3px;
    font-size: 10px;
    cursor: pointer;
    &:hover{
      color: #2ea1f8;
    }
  }
  .trans-loading{
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-left: 3px;
    margin-top: 3px;
    background:  url("#{$dev-url}loading.png") no-repeat;
    background-size: 100% 100%;
    transform: translateY(2px);
  }
}

.parent-label{
  height: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  input[type='checkbox']{
    margin-right: 5px;
    cursor: pointer;
  }
  .adder-subtractor-btn{
	display: flex;
    align-items: center;
    justify-content: center;
    color: #CED0DA;
    width: 18px;
    height: 18px;
    background: linear-gradient(0deg,#f2f4f8,#feffff);
    border-radius: 4px;
	border: 1px solid #ced0da;
	.icon-add, .icon-close {
		margin-right: 0px;
		transform: scale(0.6);
		font-weight: 900;
	}
  }
  .data-name{
	flex: 1;
	color: #354052;
    font-weight: 500;
  }
  .load-btn {
	width: 20px;
	color: #A1A7AF;
  }
  &:hover{
    background-color: rgba(230,234,238,.3);
  }
}

.second-tier{
  padding: 5px;
  &:hover{
    background-color: rgba(230,234,238,.3);
  }
}

.second-tier-item{
  margin-top: 10px;
  padding-left: 16px;
}

.children-label{
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  .data-name{
    flex: 1;
    color: #54667A;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
  .adder-subtractor-btn{
	display: flex;
    align-items: center;
    justify-content: center;
    color: #CED0DA;
    width: 18px;
    height: 18px;
    background: linear-gradient(0deg,#f2f4f8,#feffff);
    border-radius: 4px;
	border: 1px solid #ced0da;
	.icon-add, .icon-close {
		margin-right: 0px;
		transform: scale(0.6);
		font-weight: 900;
	}
  }
  input[type='checkbox']{
    margin-right: 5px;
    cursor: pointer;
  }
}

.transfer-content{
  margin-top: 10px;
  margin-bottom: 10px;
  padding-right: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.kb-transfer-target {
	.parent-label, .children-label {
		.adder-subtractor-btn {
			background:linear-gradient(0deg,#29b311,#57d841);
			border:1px solid rgba(39,170,17,1);
			color: #fff;
		}
	}
}

.limited-label-name {
	display: inline-block;
	max-width: 150px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
