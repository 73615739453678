.select-area-btn{
  height: 36px;
  border: 1px solid #55CE6B;
  color: #55CE6B;
  cursor: pointer;
  text-align: center;
  line-height: 36px;
  float: left;
  margin-top: 29px;
  border-radius: 4px;
  &:hover {
      background-color: #55CE6B;
      color: #fff;
  }
}

.area-status{
  @include clear_fix;
  .status-title{
    float: right;
    display: flex;
    align-items: center;
    margin-left: 15px;
    >span:first-child{
      display: block;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      margin-right: 5px;
    }
    >span:last-child{
      font-size: 14px;
    }
  }
}

.area-block{
  width: 100px;
  height: 100px;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 20px;
  float: left;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  position: relative;
  .area-name{
    margin-bottom: 15px;
    font-size: 15px;
    font-weight: 500;
  }
  .area-desk-num{
    font-size: 12px;
  }
  &:nth-child(5n){
    margin-right: 0;
  }
}

.area-block_hover{
  &:hover{
      background-color: #EDFBEF !important;
      color: #39B54A !important;
      border: 2px solid #39B54A !important;
  }
}

.area-block_active{
  background-color: #EDFBEF !important;
  color: #39B54A !important;
  border: 2px solid #39B54A !important;
}

.area-block_occupied {
    .area-subscriptions-box-container {
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.35);
        background-color: #fff;
        border-radius: 4px;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, calc(100% + 20px));
        z-index: 99;
        padding: 15px 0;
        .box-arrow {
            width: 20px;
            height: 20px;
            box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.35);
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%) rotate(225deg);
            background-color: #fff;
        }
        .area-subscriptions-box {
            width: 400px;
            min-height: 100px;
            max-height: 375px;
            background-color: #fff;
            border-radius: 4px;
            padding: 0 15px;
            cursor: auto;
            overflow-y: auto;
            .area-sub {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 20px 5px;
                border-bottom: 1px solid #DFE2E5;
                &:last-child {
                    border-bottom: 0;
                }
                .sub-infos {
                    color: #7F8FA4;
                    font-size: 12px;
                    line-height: 17px;
                    font-weight: 500;
                    >div {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-top: 5px;
                        > i {
                            color: #CED0DA;
                            font-size: 14px;
                        }
                        .middle-info {
                            margin: 0 7px;
                        }
                        .serial-link {
                            color: #279BF3;
                            cursor: pointer;
                        }
                        .currently-occupied {
                            display: inline-block;
                            padding: 0px 9px;
                            line-height: 20px;
                            background-color: #fff;
                            border: 1px solid #39B54A;
                            border-radius: 3px;
                            color: #39B54A;
                        }
                        .sub-expired {
                            display: inline-block;
                            background-color: #E63B41;
                            border-radius: 3px;
                            padding: 0px 9px;
                            line-height: 20px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}

.rent-box{
  padding: 15px;
  border: 1px solid $kb-color3;
  border-radius: 4px;
  input {
	border-radius: 4px;
  }
  .rent-list{
    display: flex;
    align-items: center;
    margin-top: 15px;
    .triangle{
      width: 0;
      height: 0;
      border-top: 4px solid transparent;
      border-left: 8px solid #d8d8d8;
      border-bottom: 4px solid transparent;
      margin-right: 20px;
    }
    .per-desk-input, .per-free-input{
      height: 36px;
      line-height: 36px;
      border: 1px solid #E5E5E5;
      text-indent: 11px;
      &:focus{
        border: 1px solid #A0A0A0;
        outline: none;
      }
    }
    .per-desk-input{
      padding-right: 80px;
      margin-left: 15px;
    }
    .per-free-input{
      padding-right: 90px;
    }
    .per-desk-unit {
      width: 75px;
    }
    .per-free-unit{
      width: 85px;
    }
    .per-desk-unit, .per-free-unit{
      position: absolute;
      right: 1px;
      top: 1px;
      display: inline-block;
      height: 34px;
      background-color: #f3f7f9;
      line-height: 36px;
      text-align: center;
      padding: 0 5px;
      color: #999999;
      white-space: nowrap;
      border-left: 1px solid $kb-color3;
    }
  }
}

.totalRent-input{
  &:focus{
    border: 1px solid #A0A0A0;
    outline: none;
  }
}

.allot-desk{
  margin-top: 20px;
  display: flex;
  .allot-type{
    margin-top: 10px;
    display: flex;
    align-items: center;
    .desk-num{
      margin-left: 20px;
      display: flex;
      align-items: center;
    }
    &:first-of-type{
      margin-top: 0;
    }
  }
  .contract-edit-desk-btn{
    color: #2ea1f8;
    border: 1px solid #2ea1f8;
    height: 24px;
    line-height: 24px;
    font-size: 12px;
    padding: 0 10px;
    border-radius: 2px;
    background-color: #ffffff;
    cursor: pointer;
    margin-left: 20px;
    &:hover{
      background-color: #2ea1f8;
      color: #ffffff;
    }
  }
  .contract-edit-desk-btn_disabled{
    background-color: #ffffff;
    border-color: #cccccc !important;
    color: #cccccc !important;
    &:hover{
      background-color: #ffffff !important;
      color: #cccccc !important;
    }
  }

}

.contract-select-desk-header{
  display: flex;
  align-items: center;
  border-bottom: 1px solid $kb-color3;
  padding-bottom: 15px;
  .desk-state-container{
    display: flex;
    align-items: center;
    margin-left: 30px;
  }
  .desk-state-content{
    margin-right: 20px;
    display: flex;
    align-items: center;
    color: #9b9b9b;
    .desk-state-block{
      display: block;
      width: 14px;
      height: 14px;
      margin-right: 10px;
    }
  }
}

.contract-station-number{
  width: 60px;
  height: 60px;
  float: left;
  position: relative;
  text-align: center;
  margin-right: 13px;
  margin-bottom: 13px;
  background-size: 100% 100%;
  border-radius: 4px;
  cursor: pointer;
  span{
    display: block;
  }
  &:nth-child(8n){
    margin-right: 0;
  }
  i{
    display: block;
    width: 20px;
    height: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

.contract-station-number-normal{
  background-color: #ffffff;
  border: 1px solid #727272;
  color: #727272;
  i{
    background: url("#{$dev-url}desk-state_normal.png") no-repeat;
    background-size: 100% 100%;
  }
  &:hover{
    background-color: #727272;
    color: #ffffff;
    i{
      background: url("#{$dev-url}desk-state_hover.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}

.contract-station-number-assigned{
  background-color: #f6f6f6;
  color: #b8b8b8;
  cursor: default;
  i{
    background: url("#{$dev-url}desk-state_assigned.png") no-repeat;
    background-size: 100% 100%;
  }
}

.contract-station-number-team{
  background-color: #b4e0ff;
  color: #ffffff;
  i{
    background: url("#{$dev-url}desk-state_hover.png") no-repeat;
    background-size: 100% 100%;
  }
}

.contract-station-number-select{
  background-color: #2ea1f8;
  color: #ffffff;
  i{
    background: url("#{$dev-url}desk-state_hover.png") no-repeat;
    background-size: 100% 100%;
  }
}

.contract-logs-table{
  th{
    text-align: left;
    color: #333;
    padding: 10px;
    &:first-child{
      padding-left: 0;
    }
  }
  td{
    padding: 10px;
    &:first-child{
      padding-left: 0;
    }
  }
}

.update-phases{
  >td{
    padding-bottom: 40px !important;
  }
  .update-phase_status{
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -25px;
    left: 44px;
    >span{
      margin-right: 10px;
      font-size: 10px;
    }
  }
}

.area-entering-way{
  display: flex;
  align-items: center;
  background-color: #ffffff;
  float: left;
  >div{
    display: flex;
    align-items: center;
    margin-right: 30px;
    >label{
      color: #999999;
    }
    >input{
      margin-right: 15px;
    }
  }
}

.provider-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  >input{
    margin-right: 15px;
  }
  .location-provider{
    flex: 1;
    cursor: pointer;
  }
}

.provider-item_active{
  .location-provider{
    border: 1px solid #2ea1f8 !important;
    background-color: #ECF8FF !important;
  }
}

// 选择分区合同页面 显示合同详情的 slider
.slider-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    background-color: rgba(0,0,0,0);
    z-index: 100;
    transition: background-color 1s ease-in-out;
    .slider-content {
        width: 881px;
        height: 100%;
        background-color: #fff;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(100%);
        transition: transform 1s cubic-bezier(0.22, 0.61, 0.36, 1);
    }
}

.slider-container-active {
    height: 100%;
    background-color: rgba(0,0,0,0.65);
    .slider-content {
        transform: translateX(0);
    }
}
