body {
  overflow: hidden;
}

@mixin container-border-style {
  border: 1px solid #DFE2E5;
  border-radius: 4px;
}

.clear-fix {
  *zoom: 1;

  &:before,
  &:after {
    content: '';
    display: table;
  }

  &:after {
    clear: both;
  }
}

//最外层盒子
.kb-out-box {
  width: 100%;
  height: 100%;
  background-color: $kb-color1;
  overflow-y: auto;
  padding-top: 66px;
  // position: relative;
}

// main content
.kb-content {
  //display: table;
  width: 1136px;
  padding-top: 30px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
  height: 100%;
}

.kb-content-t {
  //display: table;
  width: 1136px;
  padding-top: 75px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
  height: 100%;
}

// nav 区块的样式处理
.nav-container {
  width: 225px;
  border-radius: 2px;
}

.nav-container-bg {
  width: 280px;
  border-radius: 2px;
}

.nav-container-content {
  @include clear_fix;
  background-color: white;
  //   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  @include container-border-style;
}

.nav-header {
  border-bottom: 1px solid $kb-color3;
  padding: $kb-value2 30px;
  position: relative;
  @include clear_fix;

  .nav-header-title {
    display: inline-block;
    float: left;
    @include clear_fix;
    line-height: 30px;

    img {
      float: left;
      width: 30px;
      height: 30px;
      margin-right: 12px;
      @include radius(50%);
      @include transform-translateY(-2px);
    }
  }
}

.nav-inner-header {
  background-color: white;
  padding-bottom: 4px;
  border-bottom: 1px solid #E6E6E6;
  @include clear_fix;

  .nav-header-title {
    display: inline-block;
    float: left;
    @include clear_fix;
    line-height: 30px;

    img {
      float: left;
      width: 30px;
      height: 30px;
      margin-right: 12px;
      @include radius(50%);
      @include transform-translateY(-2px);
    }
  }
}

.header-title {
  font-size: 18px;
  font-weight: 100;
}

.header-title-center {
  font-size: 24px;
  color: $kb-color35;
  font-weight: 100;
}

.nav-header-btn {
  position: absolute;
  right: 30px;
  top: 50%;
  @include transform-translateY(-49%);
  z-index: 500;
}

.nav-body {
  padding: 30px 0;
  max-height: 550px;
  overflow-y: auto;
  position: relative;

  p {
    //text-indent: 14px;
    font-size: 14px;
    color: #333;
    font-weight: 500;
  }

  &:hover {
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
    background-color: #F5F5F5;
    cursor: pointer;
  }
}

// nav 展开层的样式处理
.nav-section-container,
.nav-section-container-bg {
  @include clear_fix;
  width: 881px;
  //min-height: 574px;
  min-height: 100%;
  background-color: white;
  //   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  @include container-border-style;
}

.nav-section-container-multi {
  @include clear_fix;
  width: 881px;
  border-radius: 2px;
}

.multi-section-each {
  background-color: white;
  // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
  @include container-border-style;
}

.nav-section-container-bg {
  position: relative;
  width: 831px;
}

.nav-section-header {
  padding: $kb-value2 30px;
  //border-bottom: 1px solid $kb-color3;
}

.nav-meeting-title {
  font-size: 14px;
  color: #999999;
  margin-right: 12px;
}

.nav-section-header-title {
  @include clear_fix;
  font-size: 22px;
  font-weight: 100;

  .company-logo {
    width: 60px;
    height: 60px;
    background-color: #383838;
    display: inline-block;
    vertical-align: middle;
  }

  .customer-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }

  .meeting-people-all {
    margin-left: 20px;
    font-size: 22px;
    color: #999999;
  }

  .receipt-status {
    display: inline-block;
    margin-right: 8px;
    vertical-align: middle;
  }
}

.header-photo-box {
  .photo-box {
    .upload-photo {
      margin-right: 22px;
      margin-left: 0 !important;
    }
  }
}

@mixin icon-position {
  display: inline-block;
  @include transform-translateY(1px);
}

.action-bar-status,
.action-bar-type {
  display: inline-block;
  color: #999999;
  font-size: 14px;

  span {
    margin-left: 5px;
  }
}

.action-bar-status {
  margin-left: 20px;
}

.nav-section-action-bar {
  @include clear_fix;
  position: relative;
  margin-top: 20px;

  .action-bar-status,
  .action-bar-type {
    display: inline-block;
    color: #999999;
  }

  .action-bar-type {
    span {
      margin-left: 0;
    }
  }

  .action-bar-status {
    margin-left: 30px;
  }
}

.nav-section-search-input {
  width: 100%;
  padding: 15px;
  border: none;
  background: #E7F2FB url("#{$dev-url}staff-store-search.png") no-repeat calc(100% - 15px);
}

.nav-section-search-input-container {
  position: relative;
}

// nav 展开层的content部分
.nav-section-content-container {
  @include clear_fix;
  padding: 0px 30px 30px 30px;
  position: relative;
  height: 100%;
}

//kb-content-container 整体
.kb-content-container {
  background-color: #FFFFFF;
  min-height: 100%;
  //   box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  margin-bottom: 30px;
  @include container-border-style;
}

//kb-content-container-header 整体头部
.kb-content-container-header {
  padding: $kb-value2 30px;
  border-bottom: 1px solid $kb-color3;
}

//无边距
.p-none {
  padding: 0;
}

.m-none {
  margin: 0;
}

//边框
.border-full {
  border: 1px solid $kb-color3 !important;
}

.border-left-none {
  border-left: none !important;
}

//下边框
.border-bottom {
  border-bottom: 1px solid $kb-color3 !important;
}

//上边框
.border-top {
  border-top: 1px solid $kb-color3 !important;
}

//w无边距
.m-none {
  margin: 0 !important;
}

//w无上边距
.m-top-none {
  margin-top: 0 !important;
}

//w无下边距
.m-bottom-none {
  margin-bottom: 0 !important;
}

//w无左边距
.m-left-none {
  margin-left: 0 !important;
}

//w无右边距
.m-right-none {
  margin-right: 0 !important;
}

//上边距
.m-top {
  margin-top: 30px !important;
}

.m-top-sm {
  margin-top: 20px !important;
}

.m-top-lg {
  margin-top: 60px !important;
}

//下边距
.m-bottom {
  margin-bottom: 30px !important;
}

.m-bottom-sm {
  margin-bottom: 20px !important;
}

//左边距
.m-left {
  margin-left: 30px !important;
}

.m-left-sm {
  margin-left: 20px !important;
}

//右边距
.m-right {
  margin-right: 30px !important;
}

.m-right-sm {
  margin-right: 20px !important;
}

//上下边距
.m-updown {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

//下padding
.p-bottom {
  padding-bottom: 60px !important;
}

//上padding
.p-top {
  padding-top: 30px !important;
}

//右padding
.p-right {
  padding-right: 30px !important;
}

//hover变颜色
.a-hover:hover {
  color: #2ea1f8 !important;
  cursor: pointer;
}

//隐藏元素
.d-hidden {
  display: none !important;
}

.f-bold {
  font-weight: 500 !important;
}

//颜色
.color-six {
  color: #666666 !important;
}

.color-nine {
  color: #999999 !important;
}

.color-three {
  color: #333333 !important;
}

.color-red {
  color: #d84a4a !important;
}

.color-white {
  color: #ffffff !important;
}

//crm流程
.color-green {
  background-color: #a6cc7a !important;
}

//desk-2-ul负责人颜色
.color-purple {
  background-color: #6e6eb6 !important;
}


.d-iblock {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.button-margin {
  margin-top: 10px !important;
  margin-left: 18px !important;
}


//员工状态
.status-active-i {
  display: block;
  width: 8px;
  height: 8px;
  @include radius(50%);
  background-color: $color19;
}

.status-normal-i {
  display: block;
  width: 8px;
  height: 8px;
  @include radius(50%);
  background-color: $color12;
}

.button-box {
  @include clear_fix;
  padding-top: 20px;
  margin-bottom: 30px;
  border-top: 1px solid $kb-color3;

  button:nth-child(1) {
    margin-left: 0;
  }
}

.important-text {
  background-color: #f4f4f4;
  padding: 8px 10px;
  color: #666666;
}

//垂直居中
.v-center {
  position: absolute;
  top: 50%;
  @include transform-translateY(-50%);
  z-index: 999;
}

.vf-center {
  @include d-flex;
  align-items: center;
}

.member-name {
  display: flex;
  align-items: center;
  font-size: 30px;

  .user-name {
    padding: 5px;
    border: 1px solid #2ea1f8;
    color: #2ea1f8;
    font-size: 10px;
    margin-left: 10px;
    border-radius: 3px;
    font-weight: 300;
    cursor: pointer;
  }
}

.require {
  color: #f5222d;
  position: absolute;
  left: 15px;
}
