.kb-popup,.kb-popup-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: auto;
  display: none;
  background: rgba(1,1,1,0);
  .kb-form-container {
    max-height: 60%;
    // overflow-y: auto;
    // overflow-x: hidden;
  }

  .kb-form-container-m {
    max-height: 650px;
  }
}

.kb-popup--visible,.kb-popup-top--visible {
  z-index: 10000;
  background: rgba(1,1,1,0.6);
  display: block;

}

.kb-popup__box,.kb-popup-top__box {
  position: relative;
  margin: 7% auto 0;
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  z-index: 100;
}

.kb-popup-top__box{
  z-index: 200;
}

.kb-popup__box--popover,.kb-popup-top__box--popover {
  width: 200px;
}

.kb-popup__box--popover .kb-popup__box__body {
  padding: 20px;
}


.tooltip{
  position: absolute;
  display: inline-block;
  margin: 10px 20px;
  opacity: 1;
  z-index: 1000;
  background: transparent;
  border: 0px;
}
.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}
.tooltip.left {
  padding: 0 5px;
  //margin-left: -3px;
}
.tooltip.top {
  padding: 5px 0;
  //margin-top: -3px;
}
.tooltip.bottom {
  padding: 5px 0;
  //margin-top: 3px;
}
.tooltip.right {
  padding: 0 5px;
  //margin-left: 3px;
}
.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}
.tooltip.top .tooltip-arrow {
  bottom: 1px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}
.tooltip.bottom .tooltip-arrow {
  top: 1px;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}
.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 1px;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}
