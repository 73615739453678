.chart-data-list{
  margin-top: 12px;
  li{
    width: 200px;
    @include clear_fix;
    padding: 12px 0;
    border-bottom: 1px solid $kb-color3;
  }
}
.time-filter{
  display: inline-block;
  margin-left: 20px;
  >button, >span, .filter-block{
    padding: 8px 15px;
    background-color: #ffffff;
    border: none;
    border-bottom: 1px solid $kb-color3;
    border-left: 1px solid $kb-color3;
	border-top: 1px solid $kb-color3;
	transition: all .3s;
	cursor: pointer;
	background-color: #F2F4F7;
	background: linear-gradient(0deg,rgba(242,244,247,1),rgba(255,255,255,1));
    color: #354052;
    border-color: #CED0DA;
	&:first-child {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
    &:last-child{
	  border-right: 1px solid $kb-color3;
	  border-top-right-radius: 4px;
	  border-bottom-right-radius: 4px;
    }
  }
  .active{
    background-color: #1991EB;
	background: linear-gradient(0deg,rgba(25,145,235,1),rgba(45,161,248,1));
    color: #FFFFFF;
    border-color: #1389E1;
  }
}

.chart-title{
  border-bottom:  1px solid $kb-color3;
  padding-bottom: 5px;
  margin-bottom: 20px;
  @include clear_fix;
  span{
    float: left;
    font-size: 18px;
    color: #666;
    font-weight: 500;
    margin-top: 5px;
  }
  button{
    float: right;
    &:first-of-type{
      margin-right: 1px;
    }
  }
}
