.kb-svg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background: rgb(255, 255, 255);
  @include clear_fix;
}

.kb-svg-editor, .kb-svg-editor-full, .kb-svg-tool, .kb-svg-detail {
  height: 100%;
  margin-top: -50px;
  padding-top: 50px;
}

.kb-svg-editor, .kb-svg-editor-full, .kb-svg-tool, .kb-svg-detail {
  float: left;
}

.fp-tool-d2 {
  position: absolute;
  right: 0;
  top: 4px;
}

.kb-svg-content {
  overflow: auto;
  // height: calc(100% - 2px);
  height: 100%;
  text-align: center;
  border: 1px solid #d7cccc;
}

.kb-svg-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}


.kb-svg-max {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
}

.kb-fp-svg {
  width: 100%;
  height: 100%;
}

.kb-svg-editor {
  width: 100%;
  position: relative;
  margin-left: -200px;
  padding-left: 200px;
  margin-right: -200px;
  padding-right: 200px;
}

.kb-svg-editor-full {
  width: 100%;
}

.kb-svg-tool, .kb-svg-tool-shrink, .kb-svg-detail {
  height: 100%;
}

.kb-aisle-content {
  background: #f7f7f7;
  height: 100%;
  padding: 10px;
  border-top: 1px solid #d7cccc;
}

.kb-svg-tool-shrink  {
  position: absolute;
  right: 0;
  width: 1px;
}

.kb-svg-tool, .kb-svg-detail {
  position: relative;
  z-index: 100;
  width: 200px;
}

.kb-svg-header {
  height: 58px;
  padding: 0 30px;
  @include clear_fix;
  @include d-flex;
  align-items: center;
  position: relative;
  z-index: 104;
  background-image: linear-gradient(93deg, #fcfcfc, #eeeeee);
}

.svg-vision {
  float: left;
  margin-right: 30px;
  height: 41px;
  line-height: 41px;
}

.svg-tool-vision-input {
  width: 28px;
  border: none;
  background: initial;
}

.svg-vision-add {

}

.svg-vision-subtract {

}
.icon-fp-common{
  margin: auto;
  cursor: pointer;
}

.fp-tool-upload, .fp-tool-select {
  //background: rgb(194, 180, 183);
  margin-right: 40px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  width: 46px;
  height: 51px;
}

.fp-tool-rect {
  fill:white;
  stroke-width:1;
  stroke:rgb(0,0,0)
}
//按钮文字
.fb-btn-text{
  font-size: 10px;
  margin-top: 4px;
  display: block;
}

@mixin btn_common () {
  display: block;
  width: 20px;
  height: 20px;
}

// 上传平面图
.fp-tool-upload {
  .icon-fp-upload{
    @include btn_common;
    background: url("#{$dev-url}icon_upload.png") no-repeat;
    &:active{
      background: url("#{$dev-url}icon_check_upload.png") no-repeat;
    }
  }
}

// 选择平面图
.fp-tool-select {
  .icon-fp-select{
    @include btn_common;
    background: url("#{$dev-url}icon_open.png") no-repeat;
    &:active{
      background: url("#{$dev-url}icon_check_open.png") no-repeat;
    }
  }
  .icon-fp-delete{
    @include btn_common;
    background: url("#{$dev-url}icon-fp-delete.png") no-repeat;
    background-size: 100% 100%;
    &:active{
      background: url("#{$dev-url}icon-fp_check_delete.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}

.fp-tool-select_active{
  background-color: #dcdcdc;
}

.icon-fp-save{
  @include btn_common;
  background: url("#{$dev-url}icon_save.png") no-repeat;
  &:active{
    background: url("#{$dev-url}icon_check_save.png") no-repeat;
  }
}

.icon-fp-repeat{
  @include btn_common;
  background: url("#{$dev-url}icon_repeat.png") no-repeat;
  background-size: 100% 100%;
  &:active{
    background: url("#{$dev-url}icon_check_repeat.png") no-repeat;
    background-size: 100% 100%;
  }
}

.icon-fp-partition{
  @include btn_common;
  background: url("#{$dev-url}icon_edit_partition.png") no-repeat;
}

.icon-fp-check_partition{
  @include btn_common;
  background: url("#{$dev-url}icon_check_edit_partition.png") no-repeat;
}

.icon-fp-location{
  @include btn_common;
  background: url("#{$dev-url}icon_edit_location.png") no-repeat;
}

.icon-fp-check_location{
  @include btn_common;
  background: url("#{$dev-url}icon_check_edit_location.png") no-repeat;
}

.icon-fp-square {
  @include btn_common;
  background: url("#{$dev-url}icon_rectangle.png") no-repeat;
}

.icon-fp-check_square {
  @include btn_common;
  background: url("#{$dev-url}icon_check_rectangle.png") no-repeat;
}

.icon-fp-broken {
  @include btn_common;
  background: url("#{$dev-url}icon_line.png") no-repeat;
}

.icon-fp-check_broken {
  @include btn_common;
  background: url("#{$dev-url}icon_check_line.png") no-repeat;
}

.icon-fp-blow_up{
  @include btn_common;
  background: url("#{$dev-url}icon_blow_up.png") no-repeat;
}

.icon-fp-check-blow_up{
  @include btn_common;
  background: url("#{$dev-url}icon_check_blow_up.png") no-repeat;
}

.icon-fp-edit{
  @include btn_common;
  background: url("#{$dev-url}icon_editor.png") no-repeat;
}

.icon-fp-rotate{
  @include btn_common;
  background: url("#{$dev-url}icon_rotate.png") no-repeat;
  background-size: 100% 100%;
}

.icon-fp-check_edit{
  @include btn_common;
  background: url("#{$dev-url}icon_check_editor.png") no-repeat;
}

.icon-fp-check_rotate{
  @include btn_common;
  background: url("#{$dev-url}icon_check_rotate.png") no-repeat;
  background-size: 100% 100%;
}

.icon-fp-add,.icon-un-add {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
  cursor: pointer;
}

.icon-fp-add{
  background: url("#{$dev-url}icon_add.png") no-repeat;
  background-size: 100% 100%;
}

.icon-un-add{
  background: url("#{$dev-url}icon_un_add.png") no-repeat;
  background-size: 100% 100%;
}

.icon-fp-decreases,.icon-un-decreases{
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 10px;
  cursor: pointer;
}

.icon-fp-decreases{
  background: url("#{$dev-url}icon_decreases.png") no-repeat;
  background-size: 100% 100%;
}

.icon-un-decreases {
  background: url("#{$dev-url}icon_un_decreases.png") no-repeat;
  background-size: 100% 100%;
}

.fb-btn-box{
  @include d-flex;
  justify-content: space-between;
}

.fp-shape-tool, .fp-tool-d1 {
  float: left;
  margin-right: 10px;
  position: relative;

  .fp-tool-item {
    margin-right: 10px;
  }

  .fp-tool-item-active {
    background: rgb(109, 204, 191);
  }

  .fp-tool-item:last-child {
    margin-right: 0
  }
}

.fp-model-active {
  background-color: rgb(39, 183, 203);
  color: rgb(255, 255, 255);
}

.fp-model {
  width: 90px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

// 绘制
.kb-cursor-draw {
  cursor : crosshair;
}

// 绘制编辑
.kb-cursor-drawEdit {
  cursor : crosshair;
}

.kb-cursor-pointer {
  cursor: pointer;
}

.kb-cursor-circleEdit {
  cursor: grab;
}

.kb-cursor-pathMove {
  cursor: move;
}
.kb-select-userSelect{
  >div{
    width: 100%;
  }
}

//楼层icon
.icon-Enclosedspace-color_three{
  color: #333;
  .path1,.path2,.path3{
    &:before{
      color: #333333 !important;
    }
  }
}

.relevance-ftn{
  >span{
    display: none;
  }
  &:hover{
    >span{
      display: inline-block;
    }
    >i{
      display: none;
    }
  }
}
