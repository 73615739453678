$comment-box1-width:280px;
$lr-header-footer-height:58px;
$p-value:10px;

//登录注册背景图
@mixin lr-bg{
  @include default_bgimg($lr-bg-url3);
}
//登录注册头部
@mixin lr-header{
  width: 100%;
  height: $lr-header-footer-height;
  border-bottom: 1px solid $color2;
  background-color: $color5;
  position: fixed;
  z-index: 1;
  top: 0;
}
//登录注册尾部
@mixin lr-footer{
  width: 100%;
  height: $lr-header-footer-height;
  border-top: 1px solid $color2;
  background-color: $color5;
  position: fixed;
  z-index: 1;
  bottom: 0;
  @include d-flex;
  justify-content: center;
  align-content: center;
}
@mixin outBox{
  width: 100%;
  height: 100%;
}
//div下图片
@mixin img{
  display: block;
  width: 100%;
  height: 100%;
}
//comment-box1 登录,注册内容 外层盒子
.comment-box1{
  width: $comment-box1-width;
  margin-top: 80px;
}
//注册登录错误提示信息
@mixin lr-error-p{
  padding-top: 2px;
}
//注册错误input边框
@mixin lr-error-input{
  border: 1px solid $color6;
}
@mixin lr-required-input{
  border: 1px solid $color8;
}
//转块的a标签
@mixin a-block{
  display: block;
  text-align: center;
}
//显示省略号
@mixin text-over($mwidth){
  max-width: $mwidth !important;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

//显示省略号
@mixin text-over-noi($mwidth){
  max-width: $mwidth;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

//flex
@mixin d-flex{
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}




//页面外层盒子
.kb-assist-box{
  @include outBox;
  @include lr-bg;
}
//鼠标滑过手型
.c-pointer{
 cursor: pointer;
}
//input placeholder字体
.common-font-1{
  color: #999999;
  font-size: 14px;

}
//登录注册字体
.lr-text{
  font-size: 22px;
  color: $color3;
}
//适中灰色字体
.suitable-font{
  font-size: 14px;
  color: $color3;
}
//a链接蓝色字体
.a-blue{
  font-size: 12px;
  color: $color4;
}
//input外层盒子
.kb-box{
  margin-bottom: 12px;
  overflow: hidden;
  input{
    width: 100%;
    padding: $p-value;
    border: 1px solid $color1;
	border-radius: 4px;
    outline: #A0A0A0;
  }
  input:focus,textarea:focus,select:focus{
    border: 1px solid #A0A0A0;
  }
  button{
    width: 100%;
    padding: $p-value;
	background-color: $color4;
	border-radius: 4px;
    color: $color5;
	outline: none;
    background: linear-gradient(180deg,#39b54a,#34aa44);
	border: 1px solid #249533;
	&:hover {
		background: linear-gradient(180deg,#34a544,#2f9a3e);
	}
  }
  textarea{
    width: 100%;
    border: 1px solid $color1;
    text-indent: 10px;
    outline: medium;
  }
}
//多选框外层盒子
.kb-sub-fb{
  margin-bottom: 12px;
}
.kb-sub-fb  select
{
  width: 100%;
  padding: $p-value;

  border-radius: inherit;
  color: #A9A9A9;
  border: 1px solid $color1;
  outline: none;
}
.kb-sub-fb  select option
{
  text-indent: .5rem;
  border-radius: inherit;
  color: #9da4a8;
}
.kb-sub-fb  select
{
  padding-right: 14px;
  outline: medium;
  background: url("#{$dev-url}deg-bottom.png") no-repeat scroll calc(100% - 15px) transparent;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.kb-sub-fb  select::-ms-expand
{
  display: none;
}

.kb-relative{
  position: relative;
}

.p-box{
  padding: $p-value;
}
//文本居中
.t-center{
  text-align: center !important;
}
//文本居右
.t-right{
  text-align: right !important;
}
//文本居左
.t-left{
  text-align: left !important;
}
//超出隐藏
.t-over{
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
//块居中
.c-center{
  margin-left: auto;
  margin-right: auto;
}
//绿色偏大字体样式
.green-font{
  font-size: 26px;
  color: $color7;
}
.f-left{
  float: left !important;
}
.f-right{
  float: right !important;
}
.o-hidden{
  overflow: hidden;
}
.cf-lf{
   padding: 0 0 0 15px;
 }

.cf-rt{
  padding: 0 15px 0 0;
}

.cf-sm-lf{
  padding: 0 0 0 7.5px;
}

.cf-sm-rt{
  padding: 0 7.5px 0 0;
}
.input-7 input{
  width: 70%;
}
.input-6 input{
  width: 60%;
}
.input-5 input{
  width: 50%;
}
.input-4 input{
  width: 40%;
}
.input-3 input{
  width: 30%;
}

.select-7 select{
  width: 70%;
}

.span-pr-smail {
  padding-right: 20px;
}

.span-pr-s {
  padding-right: 10px;
}

.span-slash {
  margin: 0 10px;
  color: #ddd;
}

.span-title-smail {
  font-size: 12px;
  color: #959595;
}

.span-type-smail-i {
  font-size: 12px;
  color: #60A6EC;
  border-radius: 4px;
  border: 1px solid #60A6EC!important;
  padding: 2px 4px;
}

.region-cascader {
  .ant-cascader-input.ant-input {
    padding-left: 0;
    font-weight: inherit;
  }
}
.ant-cascader-menus, .ant-select-dropdown {
  z-index: 99999;
}

.ant-select-search--inline .ant-select-search__field {
  border: none !important;
  text-indent: 0;
}
