.post-block {
    padding: 27px 0 22px;
    border-bottom: 1px solid #E3E8EF;
    position: relative;
    font-size: 14px;
    font-weight: normal;
    .header-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .creator {
            .name {
                font-weight: 500;
            }
            .title {
                color: #54667A;
            }
        }
        .category-and-time {
            padding-top: 6px;
            .category {
                display: inline-block;
                padding: 3px 7px;
                border-radius: 3px;
                font-size: 10px;
            }
            .category-demand {
                color: #fff;
                background-color: #ee8418;
            }
            .time {
                color: #7F8FA4;
                font-size: 12px;
            }
        }
        .icon-delete {
            font-size: 16px;
            color: #C8D2DF;
            &:hover {
                color: #dd5a55;
                cursor: pointer;
            }
        }
    }

    .body-detail {
        padding-left: 92px;
        .post-tags {
            padding-top: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .post-tag-block {
                display: inline-block;
                border-radius: 3px;
                padding: 1px 5px;
                background-color: #eee;
                color: #54667A;
                font-size: 10px;
                margin-right: 4px;
                margin-bottom: 4px;
            }
        }
        .post-summary {
            padding-top: 15px;
            color: #354052;
            line-height: 19px;
        }
        .post-images {
            padding-top: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .img-block {
                display: inline-block;
                width: 112px;
                height: 112px;
                border-radius: 4px;
                background-position: center;
                background-size: cover;
                margin-right: 10px;
                margin-bottom: 10px;
                cursor: pointer;
            }
        }
        .votes-and-comments {
            padding-top: 20px;
            font-weight: 500;
            .iconfont {
                color: #AEB6C5;
                margin-right: 5px;
                font-weight: 700;
            }
            .number {
                color: #7F8FA4;
            }
        }
    }
}


.post-comments {
    border-radius: 4px;
    border: 1px solid #DFE2E5;
    padding: 0 30px;
    position: relative;
    .post-votes {
        padding: 20px 0;
        flex-wrap: wrap;
        .iconfont {
            color: #EE8418;
            margin-right: 7px;
            &::before {
                color: #EE8418;
            }
        }
        .voter {
            display: inline-block;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            margin-right: 4px;
            background-position: center;
            background-size: cover;
        }
    }
}

.add-more {
    border-top: 1px solid #E3E8EF;
    padding: 22px 0;
    text-align: center;
    .add-more-btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 547px;
        height: 42px;
        border-radius: 4px;
        border: 1px solid #DFE3E9;
        background-color: #E9EDF1;
        color: #354052;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        &:hover {
            background-color: #1991EB;
            border-color: #1389E1;
            color: #fff;
        }
    }
}
