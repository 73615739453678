.nav-manage-list{
  padding: 30px 0px;
  >li{
    >a{
      display: block;
      padding: 15px 30px;
      font-size: 18px;
      i{
        margin-right: 12px;
        color: #999;
      }
      span{
        color: #666;
      }
      &:hover{
        background-color: #a7b4c8;
        i,span{
          color: #fff;
        }
      }
    }
  }
  .active{
    >a{
      background-color: #a7b4c8;
      i,span{
        color: #fff;
      }
    }
  }
}
.meeting-room{
  display: block;
  width: 250px;
  height: 235px;
  float: left;
  margin-right: 34px;
  margin-top: 30px;
  text-align: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  &:nth-child(3n){
    margin-right: 0;
  }
  img{
    width: 100%;
  }
  .meeting-content{
    text-align: left;
    font-size: 12px;
  }
  .meeting-room-name{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 3px;
    color: #484848;
    flex: 1;
    // max-width: 210px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .meeting-people-num{
    color: #484848;
    font-size: 16px;
  }
}
.add-new{
  width: 232px;
  height: 134px;
  border: 1px solid $kb-color3;
  float: left;
  margin-right: 61px;
  margin-top: 30px;
  text-align: center;
  cursor: pointer;
  .add-new-btn{
    width: 56px;
    height: 56px;
    @include radius(50%);
    display: block;
    margin: 30px auto 14px;
    background: url("#{$dev-url}add-meeting-btn.jpg") no-repeat;
    background-size: 100% 100%;
  }
  >span{
    font-size: 14px;
    color: #999999;
  }
  &:hover{
    border: solid 1px #5fa7ec;
    .add-new-btn{
      background: url("#{$dev-url}add-meeting-btn-hover.jpg") no-repeat;
    }
    >span{
      color: #5fa7ec;
    }
  }
}
//预定信息确认
.reservation-date{
  margin: 0 10px;
}
.message-group{
  width: 328px;
  overflow: hidden;
  text-align: left;
  label{
    min-width: 56px;
    display: inline-block;
    margin-right: 12px;
    font-size: 14px;
    color: #999999;
  }
  input{
    padding: 10px;
    border: 1px solid $kb-color3;
    margin-top: 20px;
    width: 260px;
    &:focus{
      border: 1px solid #A0A0A0;
      @include default_transition(border-color,0.15s,linear);
    }
  }
}
.nav-content-footer{
  display: inline-block;
  margin-top: 140px;
  button{
    margin-left: 30px;
  }
  padding: 30px 30px 0px 30px;
}
//会议格子
.timeline-box{
  @include clear_fix;
  position: relative;
  padding-top: 6px;
  .current-time{
    position: absolute;
    z-index: 10;
    height: calc(100% - 5px);
    top: 0px;
    width: 1px;
    background-color: #ef7070;
  }
}
.line-box{
  @include clear_fix;
  margin-bottom: 10px;
  position: relative;
  white-space: nowrap;
  .time-grid{
    &:nth-child(49n){
     &:before{
       display: none;
     }
    }
  }
}
//.line-title,.line-week,.time-grid{
//  float: left;
//}
.line-date{
  width: 140px;
  margin-bottom: 15px;
  height: 26px;
  line-height: 26px;
  .week{
    font-size: 12px;
    color: #999;
    margin-right: 16px;
  }
  .day{
    font-size: 16px;
    color: #999;
  }
}
.line-date_today{
  .week,.day{
    color: #666;
  }
}
.line-week{
  width: 70px;
  height: 26px;
  line-height: 26px;
  text-align: left;
  font-size: 14px;
  color: #666666;
}
.line-time{
  top: 6px;
  @include clear_fix;
  white-space: nowrap;
}
.time-quantum{
  width: 33px;
  height: 26px;
  text-align: left;
  line-height: 26px;
  margin-right: 33px;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  color: #666666;
  &:last-child{
    margin-right: 0;
  }
}
.time-grid{
  width: 33px;
  height: 26px;
  cursor: pointer;
  text-align: center;
  line-height: 26px;
  display: inline-block;
  position: relative;
  background-color: #f4f6f7;
  &:hover{
    background-color: #d4d5d6;
  }
  &:nth-child(even){
    &:before{
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 1px;
      height: 20px;
      background-color: #dbdcdd;
    }
  }
  &:nth-child(odd){
    &:after{
      content: '';
      display: block;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 1px;
      height: 12px;
      background-color: #dbdcdd;
      z-index: 1;
    }
  }
  &:nth-child(48){
    &:before{
      content: '';
      display: none !important;
    }
  }
  //&:first-child{
  //  border: 1px solid red;
  //}
}
.meeting-status{
  position: absolute;
  top: 0;
  height: 26px;
  background-color: $kb-color40;
  opacity: 0.6;
 // z-index: 1;
  @include clear_fix;
  cursor: pointer;
  .icon-close{
    display: none;
    float: right;
    font-size: 1px;
    color: #FFFFFF;
    margin-top: 2px;
    margin-right: 2px;
    cursor: pointer;
  }
  &:hover{
    .icon-close{
      display: block;
    }
  }
}
.past-tense{
  background-color: rgb(230,230,230) !important;
}

.meeting-block_disabled {
  background: url("#{$dev-url}meeting_disabled.png") no-repeat !important;
  background-size: 100% 100% !important;
  &:before, &:after{
    display: none !important;
  }
}

.meeting-position-1{
  left: 142px;
  width: 24px;
}
.meeting-position-2{
  left: 142 + 26px;
  width: 24*2 + 2px;
}
.meeting-position-3{
  left: 142 + 26*2px;
  width: 24*3 + 2*2px;
}
.meeting-position-4{
  left: 142 + 26*3px;
  width: 24*4 + 2*3px;
}
.meeting-position-5{
  left: 142 + 26*3px;
  width: 24px;
}




.meeting-dropDown-select{
  padding-top: 30px;
  border-top: 1px solid $kb-color3;
  display: block;
  select {
    background: url("#{$dev-url}deg-bottom.png") no-repeat scroll calc(100% - 13px) transparent;
    appearance: none;
    -moz-appearance: none;
    border-radius: inherit;
    -webkit-appearance: none;
    border: 1px solid $kb-form-color5;
    padding: 10px 29px 10px 13px;
    font-size: 14px;
    margin-right: 30px;
    color: #999999;
    option{
      border-radius: inherit;
    }
  }
}
//添加新预约
.meeting-select-lg{
  width: 400px !important;
}
.reservation-btn{
  padding: 3px 14px !important;
}
//会议弹出框
.meeting-popup{
  position: absolute;
  width: 260px;
  z-index: 15;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  cursor: default;
  margin-left: -130px;
  margin-bottom: 30px;
  .meeting-header{
    padding: 30px;
    border-bottom: 1px solid $kb-color3;
    @include clear_fix;
    .header-title{
      float: left;
    }
    .icon-cancel{
      float: right;
      font-size: 18px;
      color: #999;
      cursor: pointer;
      &:hover{
        color: #2ea1f8;
      }
    }
  }
  .meeting-footer{
    padding: 12px 0;
    text-align: center;
  }
}
.subscribe-details{
  padding: 20px 30px;
  li{
    padding: 10px 0;
    i{
      font-size: 16px;
      color: #666666;
      @include icon-position;
      margin-right: 5px;
    }
    span{
      font-size: 14px;
      color: #666666;
    }
  }
}
//会议室设备
.meeting-device{
  margin-top: 5px;
  display: block;
  color: #999999;
  >span{
    margin-right: 15px;
    >i{
      margin-right: 5px;
    }
  }
  >i{
    margin-right: 10px;
  }
}

.meeting-title-box{
  position: absolute;
  left: 0;
  top: 36px;
}

_::-webkit-full-page-media, _:future, :root .meeting-title-box {
  line-height: 25px;
}

.line-meet{
  width: 140px;
  overflow: hidden;
  margin-bottom: 15px;
  .meet-title{
    cursor: pointer;
    height: 26px;
    line-height: 26px;
    a{
      display: inline-block;
      @include text-over(120px);
      font-size: 14px;
      font-weight: 500;
      color: #2ea1f8;
      // line-height: 20.5px;
    }
  }
}
.meet-message{
  .meet-num{
    color: #999;
    i{
      margin-right: 5px;
    }
  }
}

.meeting-room-message{
  display: inline-block;
  padding-right: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  &:first-child{
    padding-left: 0;
  }
  &:last-child{
    border-right: none;
  }
  border-right: 1px solid $kb-color3;
}

.meeting-device{
  >i{
    &:last-child{
      margin-right: 0;
    }
  }
}
.meeting-empty{
  position: absolute;
  top: 76px;
  bottom: 0;
  left: 0;
  right: 0;
  @include d-flex;
  justify-content: center;
  align-items: center;
}

//主题管理
.meeting-theme-table{
  border-collapse: inherit;
  td{
    border-bottom: 1px solid $kb-color3;
  }
}

//会议室详情时间线
.current-time-line{
  position: absolute;
  width: 1px;
  height: 36px;
  top: -5px;
  z-index: 10;
  background-color: rgb(239, 112, 112);
}

.meeting-room-imgs{
  padding: 10px 0px;
  img{
    width: 140px;
    height: 96px;
    border: 1px solid #E0E3E5;
    margin-right: 20px;
  }
}
