.kb-nav-header{
  height: 20px;
  line-height: 20px;
  margin-bottom: 20px;
  @include clear_fix();
  > div{
    float: left;
  }

  span {
    font-size: 12px;
    vertical-align: middle;
    margin-left: 12px;
  }

  i{
    vertical-align: middle;
    font-size: 12px;
    font-weight: bold;
    color: #2b88b5;
  }

  .kb-nav-line {
    float: right;
    height: 100%;
    line-height: 12px;
    display: inline-block;
    >div {
      height: 1px;
      background: #ddd;
      width: 471px;
      margin-top: 9px;
    }
  }
  .pact-line{
    >div{
      width: 930px;
      margin-top: 10px;
    }
  }
  .pact-line-lg{
    >div{
      width: 1010px;
      margin-top: 10px;
    }
  }
}
.form-content{
  @include clear_fix;
  margin-bottom: 50px;
  .time-quantum{
    input{
      color: #666666;
      width: 500px;
    }
  }

  .nav-content-container {
    padding: 0 25px;

    .choices {
      display: inline-block;
      float: left;
      cursor: pointer;
    }
    .reason-note {
      >div{
      width: 100%;
      }
    }
    .terminate-reasons {
      display: inline-block;
      float: left;
      cursor: pointer;
      margin: 10px 12px 10px 0;
    }
    .radio {
      display: inline-block;
      width: 18px;
      height: 18px;
      background-color: #ffffff;
      border: solid 1px #e6e6e6;
      border-radius: 50%;
      margin-right: 10px;
      margin-bottom: -3px;
    }
    .radio-active {
      border: 7px solid #2ea1f8;
    }
  }

  .title-text {
    color: #333;
    font-size: 12px;
  }
}
.region-input-box{
  margin-bottom: 20px;
  padding: 10px 0;
  border:  1px solid $kb-color3;
  li{
    padding: 10px 30px;
    position: relative;
    cursor: pointer;
    .icon-close{
      &:hover{
        color: #5fa7ec;
      }
    }
  }
  .icon-close{
    position: absolute;
    color: #999999;
    top: 20px;
    right: 8px;
    cursor: pointer;
  }
}
.region-title{
  font-size: 12px;
  color: #666666;
  i{
    @include transform-translateY(0);
  }
}
.aggregate-amount{
  //margin: 9px 0 8px;
  text-align: right;
  span{
    font-size: 24px;
    color: #666666;
  }
}
.row-btn{
  .bordered-btn{
    margin-left: 63px;
  }
}
.popup-half{
  width: 50%;
  float: left;
  &:nth-child(1){
    padding-right: 20px;
  }
  &:nth-child(2){
    padding-left: 20px;
    border-left: 1px solid $kb-color3;
    padding-bottom: 20px;
  }
}
.detailed-statement-title{
  span{
    font-size: 16px;
    color: #666666;
  }
  padding-bottom: 16px;
  border-bottom: 1px solid $kb-color3;
}
.detailed-statement-list{
  li{
    padding: 10px 0;
    @include clear_fix;
    border-bottom: 1px solid $kb-color3;
    span{
      font-size: 14px;
      color: #999999;
      &:nth-child(1){
        float: left;
      }
      &:nth-child(2){
        float: right;
      }
    }
  }
}
//签署合同
.sign-contract{
  margin-bottom: 10px;
  input{
    margin-left: 5px;
  }
}
//缴租阶段
.rent-stage{
  border:  1px solid $kb-color3;
  padding: 30px;
  position: relative;
  .icon-close{
    position: absolute;
    top: 8px;
    right: 5px;
    cursor: pointer;
  }
}
.total-num{
  padding: 15px 0 15px 15px;
  text-align: right;
  span{
    display: inline-block;
    padding: 15px 30px;
    border-bottom: 1px solid $kb-color3;
  }
}
.total-num-block {
    float: right;
    text-align: left;
    border: 1px solid #DFE2E5;
    padding: 16px 34px;
    margin-top: 25px;
    border-radius: 4px;
    font-size: 11px;
    color: #7f8fa4;
    background-color: #FAFBFC;
    .total-money {
        color: #E63B41;
        font-size: 30px;
        font-weight: 500;
        line-height: 45px;
    }
    .tax {
        font-weight: 500;
    }
}
//输入金额input
.entry-input{
  height: 36px;
  text-indent: 11px;
  border: 1px solid $kb-color3;
  border-radius: 4px;
  &:focus{
    border-color: #A0A0A0;
  }
}

//合同续约 公司会员
.contract-members{
  @include clear_fix;
  li{
    float: left;
    margin-right: 50px;
  }
}
//终止日期 时间段选择
.time-bucket-btn{
  >span {
	margin-right: 6px;
	margin-bottom: 10px;
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid #5fa7ec;
    font-size: 10px;
    border-radius: 5px;
    color: #5fa7ec;
	cursor: pointer;
	&:last-child {
		margin-right: 0;
	}
    &:active {
      color: $kb-color18;
      border-color: $kb-color18;
    }
  }
}
//合同模板
.sub-step-header{
  padding: 60px 0;
  text-align: center;
  .step-content{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 84px;
    margin-right: 78px;
    position: relative;
    .step-num{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      background-color: #F1F6FB;
      @include radius(50%);
      margin: 20px auto 0px;
    }
    .active{
      width: 50px;
      height: 50px;
      background-color: #5fa7ec;
      border: 5px solid #B9DDFB;
      color: #ffffff;
      margin: 18px auto 0px;
    }
    &:last-of-type{
      margin-right: 0;
    }
  }
  .parting-line{
    background: url("#{$dev-url}spot.png") repeat-x;
    position: absolute;
    width: 116px;
    height: 50px;
    left: 66px;
    top: 16px;
  }
}
.sub-add-btn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.delete-file-btn{
  display: inline-block;
  width: 16px;
  height: 16px;
  font-size: 10px;
  cursor: pointer;
  text-align: center;
  line-height: 14px;
  color: #c0c0c0;
  &:hover{
    color: #5fa7ec;
  }
}
.placeholder-content{
  float: left;
  cursor: pointer;
  padding: 10px 8px;
  border: 1px solid $kb-color3;
  border-radius: 4px;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  >span{
    color: #999999;
  }
  &:hover{
    background-color: #1990ea;
    border-color: #1990ea;
    >span{
      color: #ffffff;
    }
    .delete-icon{
      display: block;
    }
  }
  .copy-success_popover{
    padding: 6px 0;
    background-color: #ffffff;
    border: 1px solid $kb-color3;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    color: #333333;
    border-radius: 2px;
    position: absolute;
    width: 116px;
    text-align: center;
    font-size: 12px;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .delete-icon{
    display: none;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #ffffff;
    color: #999999 !important;
    font-size: 10px;
    text-align: center;
    line-height: 13px;
    top: -8px;
    right: -8px;
    border: 1px solid #999999;
  }
}

//提示
.hint-frame{
  display: flex;
  align-items: center;
  background-color: rgb(231,244,253);
  padding: 15px 20px;
  >img{
    margin-right: 12px;
    width: 20px;
    height: 20px;
  }
  >span{
    color: #666666;
  }
}

// 弹出框 6:4 布局
.popup-left-6 {
  width: 60%;
  float: left;
  .kb-form-group{
    >label {
      width: 25%;
    }
    >div {
      width: 65%;
    }
  }
}
.popup-right-4 {
  width: 40%;
  height: 220px;
  float: left;
  background-color: #f9fbfd;
  padding: 60px 25px;
}
