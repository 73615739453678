//滚动条样式
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  background-color: #a2a2a2;
  border-radius: 2px ;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #ffffff;
}
@mixin lg-screen() {
  @media (min-width: $screen-lg-min){
    @content;
  }
}

@mixin md-screen() {
  @media (min-width: $screen-sm + 1){
    @content;
  }
}

@mixin sm-screen() {
  @media (min-width: $screen-xs + 1) and (max-width: $screen-sm){
    @content;
  }
}

@mixin xs-screen(){
  @media (max-width: $screen-xs){
    @content;
  }
}

@mixin sx-screen() {
  @media (min-width: $screen-xs + 1){
    @content;
  }
}
@mixin radius($value: 5px) {
  border-radius: $value;
  -o-border-radius: $value;
  -ms-border-radius: $value;
  -moz-border-radius: $value;
  -webkit-border-radius: $value;
}
@mixin transform-scale($x:1,$y:1){
  transform: scale($x,$y);
  -o-transform: scale($x,$y);
  -ms-transform: scale($x,$y);
  -moz-transform: scale($x,$y);
  -webkit-transform: scale($x,$y);
}

@mixin clear_fix {
  &:after,&:before,&::after,&::before{
    content: ".";
    height: 0;
    visibility: hidden;
    display: block;
    clear: both;
  }
}

/*---------------------------------------------------
 *  transition 样式
 *---------------------------------------------------*/
@mixin default_transition($attribute:width,$second: 0.2s, $type: ease) {
  transition: $attribute $second $type;
  -o-transition: $attribute $second $type;
  -ms-transition: $attribute $second $type;
  -moz-transition: $attribute $second $type;
  -webkit-transition: $attribute $second $type;
}
@mixin font-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@mixin transform-translateY($translateY: -50%) {
  transform: translateY($translateY);
  -o-transform: translateY($translateY);
  -ms-transform: translateY($translateY);
  -moz-transform: translateY($translateY);
  -webkit-transform: translateY($translateY);
}
@mixin transform-translateX($translateX: -50%) {
  transform: translateX($translateX);
  -o-transform: translateX($translateX);
  -ms-transform: translateX($translateX);
  -moz-transform: translateX($translateX);
  -webkit-transform: translateX($translateX);
}
@mixin transform-rotateZ($rotateZ: -90deg) {
  transform: rotateZ($rotateZ);
  -o-transform: rotateZ($rotateZ);
  -ms-transform: rotateZ($rotateZ);
  -moz-transform: rotateZ($rotateZ);
  -webkit-transform: rotateZ($rotateZ);
}
@mixin animation($keyframes,$duration,$delay,$count,$function:linear,$direction:normal,$mode:forwards){
  animation-name: $keyframes;
  animation-duration: $duration;
  animation-timing-function: linear;
  animation-delay: $delay;
  animation-iteration-count: $count;
  animation-direction: alternate;
  animation-play-state: running;
  -moz-animation-name: $keyframes;
  -moz-animation-duration: $duration;
  -moz-animation-timing-function: linear;
  -moz-animation-delay: $delay;
  -moz-animation-iteration-count: $count;
  -moz-animation-direction: alternate;
  -moz-animation-play-state: running;
  -webkit-animation-name: $keyframes;
  -webkit-animation-duration: $duration;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: $delay;
  -webkit-animation-iteration-count: $count;
  -webkit-animation-direction: alternate;
  -webkit-animation-play-state: running;
  -o-animation-name: $keyframes;
  -o-animation-duration: $duration;
  -o-animation-timing-function: linear;
  -o-animation-delay: $delay;
  -o-animation-iteration-count: $count;
  -o-animation-direction: alternate;
  -o-animation-play-state: running;
}

/*---------------------------------------------------
 *  默认背景图片
 *---------------------------------------------------*/
//定位居中
.kb-lr-center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
//弹性盒绝对居中
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
//弹性盒水平居中
.flex-horizontal-center{
  display: flex;
  justify-content: center;
}
//弹性盒垂直居中
.flex-vertical-center{
  display: flex;
  align-items: center;
}
@mixin p-center{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
@mixin default_bgimg($image_url, $position: 0 0) {
  background: url($image_url) no-repeat $position!important;
}
