.again-send{
  width:220%;
  button{
    width:45%;
  }
  button:nth-of-type(2){
    background-color: $color5;
    border: 1px solid $color1;
    color: $color1;
  }
}