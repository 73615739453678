$kb-form-color1: #354052;
$kb-form-color2: #d6d6d6;
$kb-form-color3: #e6e6e6;
$kb-form-color4: #7F8FA4;
$kb-form-color5: #DFE3E9;
$kb-form-color6: #2ea1f8;

$kb-from-width-1: 8.33333%;
$kb-from-width-2: 16.66667%;
$kb-from-width-3: 25%;
$kb-from-width-4: 33.33333%;
$kb-from-width-6: 50%;
$kb-from-width-8: 66.66667%;
$kb-from-width-9: 75%;
$kb-from-width-10: 83.33333%;
$kb-from-width-11: 91.66667%;

$kb-from-cwidth-1: 11.33333%;
$kb-from-cwidth-8: 88.66667%;

$kb-from-cwidth-3: 23%;
$kb-from-cwidth-9: 77%;

$kb-from-cwidth-17: 17%;
$kb-from-cwidth-83: 83%;

.kb-form-header {
  height: 66px;
  @include clear_fix();
  border-bottom: 1px solid $kb-form-color3;

  .header-title,
  .header-close {
    display: block;
  }

  .header-close {
    float: right;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    padding: 5px;
    margin: 20px 20px 20px 0;
    color: $kb-form-color2;

    &:hover {
      color: #f95359;
    }
  }

  .header-title {
    float: left;
    font-weight: 500;
    font-size: 18px;
    margin: 20px 0 20px 20px;
    color: #2c2b2e;
  }
}

.kb-form-container-m,
.kb-form-container {
  @include clear_fix;
  padding: 24px;

  .kb-row {
    margin-bottom: 15px;
    @include clear_fix();

    >span {
      margin-right: 30px;
    }
  }

  .kb-row:last-child {
    margin-bottom: 0;
  }

  .kb-form-39,
  .kb-div-39 {

    >div:first-child,
    >label {
      width: $kb-from-width-3;
    }

    .kb-more-file {
      padding-left: $kb-from-width-3;
    }

    >div:last-child {
      width: $kb-from-width-9;
    }
  }

  .kb-form-210,
  .kb-div-210 {

    >div:first-child,
    >label {
      width: $kb-from-width-2;
    }

    .kb-more-file {
      padding-left: $kb-from-width-2;
    }

    >div:last-child {
      width: $kb-from-width-10;
    }
  }

  .kb-form-102,
  .kb-div-102 {

    >div:first-child,
    >label {
      width: $kb-from-width-10;
    }

    .kb-more-file {
      padding-left: $kb-from-width-10;
    }

    >div:last-child {
      width: $kb-from-width-2;
    }
  }

  .kb-form-111,
  .kb-div-111 {

    >div:first-child,
    >label {
      width: $kb-from-width-1;
    }

    .kb-more-file {
      padding-left: $kb-from-width-1;
    }

    >div:last-child {
      width: $kb-from-width-11;
    }
  }


  .kb-form-48,
  .kb-div-48 {

    >div:first-child,
    >label {
      width: $kb-from-width-4;
    }

    .kb-more-file {
      padding-left: $kb-from-width-4;
    }

    >div:last-child {
      width: $kb-from-width-8;
    }
  }

  .kb-form-100,
  .kb-div-100 {
    >div {
      width: 100%;
    }
  }

  .kb-f-93,
  .kb-df-93 {
    >div:first-child {
      width: $kb-from-cwidth-3;
    }

    >label {
      width: $kb-from-cwidth-9;
    }
  }

  .kb-form-66,
  .kb-div-66 {

    >div,
    >label {
      width: 50%;
    }
  }

  .kb-div-66-active {
    margin-right: 10px;

    >label {
      margin-left: 15px !important;
    }
  }

  .kb-form-6,
  .kb-div-6 {
    label {
      width: 50%;
    }
  }

  .kb-form-84,
  .kb-div-84 {

    >div:first-child,
    >label {
      width: $kb-from-width-8;
    }

    .kb-more-file {
      padding-left: $kb-from-width-8;
    }

    >div:last-child {
      width: $kb-from-width-4;
    }
  }

  .kb-form-93,
  .kb-div-93 {

    >div:first-child,
    >label {
      width: $kb-from-width-9;
    }

    .kb-more-file {
      padding-left: $kb-from-width-9;
    }

    >div:last-child {
      width: $kb-from-width-3;
    }
  }

  .kb-form-33,
  .kb-div-33 {

    >div:first-child,
    >label {
      width: $kb-from-width-3;
    }

    .kb-more-file {
      padding-left: $kb-from-width-3;
    }

    >div:nth-child(2) {
      width: $kb-from-width-3;
    }
  }

  .kb-form-22,
  .kb-div-22 {

    >div:first-child,
    >label {
      width: $kb-from-width-2;
    }

    .kb-more-file {
      padding-left: $kb-from-width-2;
    }

    >div:nth-child(2) {
      width: $kb-from-width-2;
    }
  }

  .kb-form-22,
  .kb-div-22 {

    >div:first-child,
    >label {
      width: $kb-from-width-2;
    }

    >div:nth-child(2) {
      width: $kb-from-width-2;
    }
  }

  .kb-form-11,
  .kb-div-11 {

    >div:first-child,
    >label {
      width: $kb-from-cwidth-1;
    }

    >div:nth-child(2) {
      width: $kb-from-cwidth-1;
    }
  }


  .kb-div-width {
    width: 70%;
  }

  .kb-form-44,
  .kb-div-44 {

    >div:first-child,
    >label {
      width: $kb-from-width-4;
    }

    .kb-more-file {
      padding-left: $kb-from-width-4;
    }

    >div:last-child {
      width: $kb-from-width-4;
    }
  }

  .kb-form-c18,
  .kb-div-c18 {

    >div:first-child,
    >label {
      width: $kb-from-cwidth-1;
    }

    .kb-more-file {
      padding-left: $kb-from-cwidth-1;
    }

    >div:last-child {
      width: $kb-from-cwidth-8;
    }
  }

  .kb-form-c39,
  .kb-div-c39 {

    >div:first-child,
    >label {
      width: $kb-from-cwidth-3;
    }

    .kb-more-file {
      padding-left: $kb-from-cwidth-3;
    }

    >div:last-child {
      width: $kb-from-cwidth-9;
    }
  }

  .kb-from-initial {
    >div {
      float: left;
    }

    >label {
      float: left;
    }

    .kb-from-radio {
      margin-right: 30px;
      height: 36px;

      &:last-child {
        margin-right: 0;
      }
    }

    line-height: 36px;
    @include clear_fix();
  }


  .kb-from-radio {

    >div:first-child,
    >label {
      float: left;
      margin-right: 10px;
    }

    >div:last-child {
      float: left;
    }

    @include clear_fix();
  }

  div.kb-two-button {
    @include clear_fix();

    button {
      float: left;

      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .kb-form-c48,
  .kb-div-c48 {

    >div:first-child,
    >label {
      width: $kb-from-width-4;
    }

    .kb-more-file {
      padding-left: $kb-from-width-4;
    }

    >div:last-child {
      width: $kb-from-width-8;
    }
  }

  .kb-form-c178,
  .kb-div-c178 {

    >div:first-child,
    >label {
      width: $kb-from-cwidth-17;
    }

    .kb-more-file {
      padding-left: $kb-from-cwidth-17;
    }

    >div:last-child {
      width: $kb-from-cwidth-83;
    }
  }

  .kb-div-33-active {
    display: flex;
    width: 70%;
  }

  .kb-div-c48-active {
    width: 100% !important;
    margin-left: -5px;
    display: flex;

    >div:last-child {
      width: 100%;
    }

    >label {
      width: 12% !important;
    }
  }

  .kb-form-alone {
    float: none;

    >div {
      width: 100% !important;
    }
  }

  .kb-form-80 {
    float: none;

    >div {
      width: 80% !important;
    }
  }

  .fieldset {
    border: none;
    margin: 0 0 20px;
    padding: 0;

    legend {
      font-size: 18px;
      font-weight: 500;
      color: #2C2B2E;
      font-size: 18px;
      width: 100%;
      padding-bottom: 6px;
      margin-bottom: 20px;
    }
  }

  .fieldset {
    margin-bottom: 30px;
  }

  .kb-ps {
    position: relative;
  }

  .btn-cancel,
  .btn-setup,
  .btn-setup-next {
    height: 40px;
    min-width: 82px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    font-size: 18px;
    cursor: pointer;
    padding: 0 23px;
    border: none;
  }

  .btn-setup {
    background-color: $kb-form-color6;
    color: #ffffff;
  }

  .btn-setup-next {
    background-color: #fff;
    color: $kb-form-color6;
    border: 1px solid $kb-form-color6;
  }

  .btn-cancel {
    background-color: $kb-form-color2;
    color: #fff;
  }

  button.kb-from-cmr {
    margin-right: 0;
  }
}

.kb-input-error {
  border-color: $color6 !important;
}

.kb-form-group {
  float: left;
  height: 100%;
  display: block;
  @include clear_fix;

  >label,
  >div {
    float: left;
  }

  >div {
    height: 100%;
  }

  .label-rt {
    text-align: left;
    padding-right: 12px;
  }

  >label {
    font-size: 11px;
    margin: 9px 0 3px;
    color: $kb-form-color4;
    position: relative;
    white-space: nowrap;
    width: 100% !important;
  }

  >.label {
    margin: 9px 0 8px;
    font-size: 14px;
    font-weight: 500;
    position: relative;
    white-space: nowrap;
    color: $kb-form-color1;
    width: auto !important;
  }

  .react-date-picker__date-format-spinner input {
    width: initial;
  }

  input,
  select,
  textarea {
    width: 100%;
    height: 100%;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  input[type=checkbox] {
    width: 18px;
  }

  input[type=radio] {
    width: 14px;

    &:checked {
      background-color: #eee;
    }
  }

  select,
  input,
  option,
  textarea {
    font-weight: 500;
    border-radius: 4px;
    height: 36px;
  }

  input,
  textarea {
    border: 1px solid $kb-form-color5;

    &:focus {
      border: 1px solid #55CE6B;
      @include default_transition(border-color, 0.15s, linear);
      outline: none;
    }
  }

  select,
  input,
  option {
    text-indent: 11px;
    color: $kb-form-color1;
  }

  select {
    background: url("#{$dev-url}deg-bottom.png") no-repeat scroll calc(100% - 15px) transparent;
    appearance: none;
    -moz-appearance: none;
    border-radius: 4px;
    -webkit-appearance: none;
    border: 1px solid $kb-form-color5;

    option {
      border-radius: inherit;
    }
  }

  textarea {
    height: 68px;
    padding: 10px;
  }

  .lr-error {
    @include clear_fix();
  }

  //textarea{
  //  height: 100px;
  //}
  .kb-control-error {
    border: 1px solid $color6 !important;
  }

  //button {
  //  margin-top: 10px;
  //}
  //p{
  //  display: inline-block;
  //  padding-top: 5px;
  //}
  div.description {
    font-size: 12px;
    margin-top: 20px;
    text-align: center;
  }

  div.description-error {
    font-size: 14px;
    margin-top: 5px;
    text-align: center;
    color: $color6 !important;
  }
}

//生日
.kb-form-fete {
  input {
    width: 90px;
    float: left;
  }

  label {
    float: left;
    margin: 8px 15px 0 5px;
  }

  >label {
    margin-right: 35px;
    margin-left: 0;
  }
}

//添加分区格子
.desks-block {
  position: relative;

  >div {
    height: 35px;
    min-width: 60px;
    float: left;
    margin-right: 12px;
    margin-bottom: 10px;
    padding: 2px 10px;
    line-height: 33px;
    position: relative;
    background-color: $color23;
    text-align: center;
    cursor: pointer;

    &:hover {
      i {
        display: inline-block;
      }
    }
  }

  span {
    color: #fff;
    display: inline-block;
  }

  i {
    display: none;
    font-size: 8px;
    color: #83ba48;
    background: #fff;
    border: 1px solid #83ba48;
    border-radius: 100%;
    position: absolute;
    top: -8px;
    right: -8px;
    margin: 0;
    width: 20px;
    height: 20px;
    line-height: 20px;
  }
}

.kb-form-nav {
  margin: 0 30px;
  overflow: hidden;
  padding: 20px 0 16px 0;
  border-bottom: 1px solid $color24;
  @include clear_fix();

  li {
    span {
      line-height: 1;
      cursor: pointer;
      font-size: 16px;
      margin-right: 21px;
    }

    i {
      font-weight: bold;
      color: #e5e5e5;
      margin-right: 20px;
    }

    float: left;

    &:last-child i {
      display: none;
    }
  }

  .kb-nav-hover {
    span {
      color: $kb-color14;
    }
  }
}

.kb-form-fr {
  float: right;
}

//添加部门信息表单上传excel
.space-setting {
  margin-left: auto;
  margin-right: auto;
}

.excel-upload-box {
  text-align: center;
  margin-top: 30px;
}

.excel-upload {
  text-align: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;
  width: 230px;
  height: 130px;
  border: 1px solid $kb-color3;

  a {
    display: block;
    font-size: 14px;
    color: #666666;
    margin-top: 10px;
  }

  &:hover {
    border: 1px solid #2ea1f8;

    a {
      color: #2ea1f8;
    }
  }
}

.upload-text {
  font-size: 14px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  display: inline-block;
  text-align: left;
  margin-top: 30px;

  span {
    color: #666666;
  }

  a {
    color: #2ea1f8;
  }
}

.skip-step {
  font-size: 14px;
  color: #c8c8c8;
  margin-right: 20px;
  cursor: pointer;
}

.header-title-box {
  float: left;

  .header-title {
    float: left;
    font-size: 22px;
    color: $kb-form-color1;
  }

  .header-title-right {
    float: left;
    font-weight: 400;
    font-size: 22px;
    color: $kb-form-color1;
    margin-left: 10px;
  }

  .icon-navigate_next {
    color: #e5e5e5;
    float: left;
    margin-left: 10px;
    margin-top: 10px;
    font-weight: 600;
    font-size: 14px;
  }
}

.group-content {
  line-height: normal;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    font-weight: 500;
  }
}

.group-form-content {
  padding-top: 9px;
}

//工位选择
.station-show {
  header {
    margin-bottom: 20px;
  }

  .station-self {
    display: inline-block;
    width: 14px;
    height: 14px;
    background-color: #2ea1f8;
    margin-right: 10px;
    vertical-align: middle;
  }

  .station-click {
    background-color: rgb(98, 168, 233);

    span,
    i {
      color: #ffffff;
    }

    &:before {
      content: url('#{$dev-url}station-select.png');
      position: absolute;
      display: block;
      width: 16px;
      height: 16px;
      @include radius(50%);
      background-color: #81d459;
      color: #ffffff;
      top: -4px;
      right: -4px;
    }
  }

  .station-number {
    width: 48px;
    height: 48px;
    float: left;
    position: relative;
    text-align: center;
    margin-right: 8px;
    margin-bottom: 20px;
    background-size: 100% 100%;
    cursor: pointer;

    span {
      display: block;
    }

    &:nth-child(10n) {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, .5);
      border: none !important;
    }
  }
}

//空间设置
.upload-hint {
  margin-top: 12px;

  span {
    font-size: 12px;
    color: #999999;
  }
}

//分店设置 创建分店按钮
.create-subbranch-btn {
  @include transform(translate(-30px, -30px));
}

//*必填项
.must-fill {
  color: #F62D51;
  position: absolute;
  top: 3px;
  left: -8px;
}

.must-fillR {
  color: #F62D51;
  @include transform-translateY(3px);
  display: inline-block;
  margin-right: 2px;
}

.rotate-box {
  img {
    animation: rotate 10s linear infinite;
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

//email logo 弹出提示框
.email-hint {
  @include clear_fix;
  border-bottom: 1px solid $kb-color3;
  text-align: center;
  padding: 14px 30px;
  background: url("#{$dev-url}email-hint-bg.jpg") no-repeat;

  .icon-cancel {
    color: #ffffff;
    padding: 5px;
    float: right;
    cursor: pointer;

    &:hover {
      color: #2ea1f8;
    }
  }
}

//日期选择器下三角
.bg-bottom_deg {
  background: url("#{$dev-url}deg-bottom.png") no-repeat scroll calc(100% - 15px) #fff !important;
}

// 客户意向的选择标签
.kb-form-group {
  .tabs {
    margin: 9px 0;

    .intentions {
      color: #666;
      margin-right: 15px;
      cursor: pointer;
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .content {
    padding: 15px 20px;
    margin-bottom: 15px;
    background-color: #f9fbfd;

    .kb-row {
      label {
        margin-right: 15px;
        color: #878787;
      }

      .desk-types {
        display: inline-block;
        margin-right: 10px;
        color: #666;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }

      .radio {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-color: #ffffff;
        border: solid 1px #e6e6e6;
        border-radius: 50%;
        margin-right: 5px;
        margin-bottom: -3px;
        cursor: pointer;
      }

      .radio-active {
        border: 7px solid #2ea1f8;
      }
    }

    .kb-row-s {
      margin-bottom: 20px;

      label {
        margin-right: 12px;
        color: #878787;
      }

      div {
        display: inline-block;
      }

      &>div {
        margin-right: 30px;

        &:last-child {
          margin-right: 0;
        }
      }

      select {
        width: 145px;
        background-color: #fff;
      }
    }

    .kb-row-e {
      label {
        width: auto;
        margin-right: 12px;
        color: #878787;
      }

      div {
        display: inline-block;
      }

      .kb-form-c18 {
        &>div {
          width: auto;
          float: none;
        }
      }

      textarea {
        width: 388px;
        height: 116px;
      }
    }

    .kb-row-m {
      margin-bottom: 20px;

      label {
        margin-right: 12px;
        color: #878787;
      }

      div {
        display: inline-block;
      }

      select {
        width: 388px;
        background-color: #fff;
      }
    }
  }
}

.faq-position-input {
  >label {
    color: #333;
    width: 7.111111%;
  }

  >div {
    width: 92.888889%;
  }
}

.fixed-form-btn {
  position: absolute;
  bottom: -66px;
  background-color: #F0F3F8;
  width: 100%;
  left: 0;
  padding: 17px 30px;
  text-align: center;
  border-radius: 0px 0px 4px 4px;
  border-top: 1px solid #E6EAEE;
}

.hover-delete {
  &:hover {
    cursor: pointer;
    color: #E63B41 !important;
  }
}