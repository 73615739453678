.space-table{
  td,th{
    padding: 5px 60px 5px  0;
    text-align: left;
  }
}
.app-module-url{
  display: inline-block;
  @include text-over(200px)
}
.space-app-logo{
  border: 1px solid $kb-color3;
  padding: 20px;
  .title{
  }
  .app-logo{
    width: 375px;
    height: 64px;
    background: url("https://media-dev-ssl.kuban.io/uploads/spaces/364/8aa2e91c-cc16-433e-8a1b-ff3b456646ac.png") no-repeat;
    background-size: 100% 100%;
    margin-top: 10px;
    @include d-flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    img{
      margin-top: 22px;
      height: 24px;
      width: auto;
    }
  }
}


.space-base-setting{
  float: left;
  width: 400px;
  .upload-square{
    >img{
      max-width : 250px !important;
      background-color: #fff;
    }
  }
}

.wechat-authorization{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  >img{
    width: 360px;
    height: auto;
    margin-right: 40px;
  }
}

.select-type-radio{
  display: flex;
  align-items: center;
  >label{
    margin-right: 40px;
    cursor: pointer;
    >input{
      margin-right: 10px;
    }
  }
}

.rate-option{
  margin-top: 5px;
  >span{
    display: inline-block;
    width: 38px;
    height: 25px;
    text-align: center;
    line-height: 25px;
    border: 1px solid #2ea1f8;
    color: #2ea1f8;
    border-radius: 5px;
    font-size: 10px;
    margin-right: 5px;
    cursor: pointer;
  }
}
