.nav-section-tab {
  width: 100%;
  border-bottom: 1px solid $kb-color3;
  margin-bottom: 30px;
  li{
    display: inline-block;
    margin-right: 30px;
    cursor: pointer;
    position: relative;
    &:hover{
      a{
        color: #2d3848;
      }
    }
  }
  a{
    color: #7f8fa4;
    padding: 10px 10px 14px 10px;
    display: inline-block;
  }
  .nav-section-tab-active{
    >a{
	  color: #2d3848;
	  font-weight: 600;
    }
    &:after{
      width: 100%;
      content: '';
      display: block;
      height: 3px;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
	  background-color: #1990ea;
	  transform: translateY(1px);
    }
  }
}

.nav-section-tab{
  .team-tile,.team-list{
    margin-top: 15px;
    margin-right: 10px;
    float: right;
    color: #999;
    &:after{
      display: none !important;
    }
  }
  .team-list{
    margin-right: 0;
  }
}

// new tab
.common-tab{
  display: flex;
  align-items: center;
  .tab-list{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 15px;
    background-color: #ffffff;
    border: 1px solid $kb-color3;
    border-right: none;
	cursor: pointer;
	&:first-child {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
    .radio{
      display: block;
      width: 14px;
      height: 14px;
      border: 1px solid #2ea1f8;
      border-radius: 50%;
      margin-right: 10px;
      position: relative;
      background-color: #ffffff;
      &:before{
        content: '';
        position: absolute;
        display: none;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        border: 1px solid #2ea1f8;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &:last-child{
	  border-right: 1px solid $kb-color3;
	  border-top-right-radius: 4px;
	  border-bottom-right-radius: 4px;
    }
  }
  .tab-list_active{
    background-color: #2ea1f8;
    border-color: #2ea1f8;
    color: #ffffff;
    .radio:before{
      display: block;
    }
  }
}

.point-recharge-tab {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    height: 36px;
    color: #333;
    padding: 5px 15px;
    cursor: pointer;
    border: 0px;
    &:hover {
      >.status-text {
        color: #2ea1f8;
      }
    }
}
.point-recharge-tab-active {
   background-color: #f9fbfd;
   >.status-text {
     color: #2ea1f8;
   }
}
