//表格
.nav-section-content-container .t-layout{
  table-layout: fixed;
}
.table-align{
  td{
    vertical-align: top;
  }
}
.content-table{
  margin-top: 15px;
  width: 100%;
  text-align: left;
  td:first-child,th:first-child{
    padding-left: 15px !important;
  }
  thead{
  }
  th,
  tr {
    border-bottom: 1px solid #F3F1F1;
  }
  td{
    color: $kb-color12;
    padding: 15px 10px 15px 0;
    font-size: 14px;
    position: relative;
    .icon-edit, .icon-dismiss{
      cursor: pointer;
      &:hover{
        color: #2ea1f8;
      }
	}
	.icon-delete {
	  cursor: pointer;
	  &:hover {
		  color: #f95359;
	  }
	}
    .icon-close{
      font-weight: 600;
      margin-right: 2px;
    }
    .icon-radio_button_unchecked{
      margin-right: 2px;
    }
    .icon-star{
      @include transform-translateY(2px);
    }
  }
  //td:first-child,th:first-child{
  //  padding-left: 25px;
  //}
  td:last-child,th:last-child{
    padding-right: 15px;
  }
  th {
    font-weight: 500;
    padding: 12px 10px 12px 0;
    font-size: 14px;
    color: #54667A;
  }
}
.content-table-half{
  width: 50%;
  text-align: left;
  th,
  tr {
    border-bottom: 1px solid $kb-color3;
  }
  td{
    color: $kb-color12;
    padding: 15px 0;
    font-size: 16px;
  }
  td:first-child,th:first-child{
    padding-left: 15px;
  }
  td:last-child{
    padding-right: 15px;
  }
  th {
    font-weight: 500;
    padding: 15px 0;
    font-size: 14px;
    color: #666666;
  }
}
//hover表格
.edit-table{
  tbody{
    tr{
      td{
        .icon-delete,.icon-edit,.table_btn_delete, .icon-dismiss{
          filter: Alpha(opacity=0); /* IE */
          -moz-opacity: 0;      /* FF 主要是为了兼容老版本的FF */
          opacity: 0;
        }
        a{
          &:hover{
            color: #2ea1f8;
          }
        }
      }
      &:hover{
        background-color: $kb-color41;
        cursor: pointer;
        td{
          //color: #ffffff;
          .icon-delete,.icon-edit,.table_btn_delete, .icon-dismiss{
            filter: Alpha(opacity=100); /* IE */
            -moz-opacity: 1;    /* FF 主要是为了兼容老版本的FF */
            opacity: 1;
          }
          a{
           // color: #ffffff;
          }
        }
      }
    }
  }
}
.contact-way{
  i{
    font-size: 14px;
    margin-right: 8px;
  }
}
//会员表格 工位特殊显示
.s-bg{
  display: block;
  width: 38px;
  padding: 5px 0;
  text-align: center;
  background-color: #666666;
  color: #ffffff;
}
.s-bg-w{
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  width: 49px;
  padding: 5px 0;
  text-align: center;
  background-color: #666666;
  color: #ffffff;
}
//公司表格 工位特殊显示
.edit-station_num{
  .edit-station{
    display: none;
  }
  &:hover{
    .edit-station{
      display: inline-block;
    }
    .s-bg-fix{
      display: none;
    }
  }
}
.s-bg-fix{
  width: 78px;
  padding: 5px 0;
  font-size: 13px;
  background-color: #666666;
  color: #ffffff;
  display: block;
  text-align: center;
  @include text-over(78px);
}

.important-rank{
  font-size: 14px;
  color: $kb-color38 !important;
  i{
    color: $kb-color38;
    margin-right: 2px;
  }
}
.operate-td{
  width: 100px;
  text-align: right;
}
.operate-icon{
  display: none;
  i{
    margin-left: 20px;
    cursor: pointer;
    color: #999999;
  }
}
.operate-text{
  display: none;
  span{
    font-size: 14px;
    color: #999999;
    cursor: pointer;
  }
  span:last-child{
    margin-left: 20px;
  }
  span:hover{
    color: #2ea1f8;
  }
}
.operate-tr{
  td{
    //width: 100px;
  }
  &:hover{
    .operate-icon{
      display: inline-block;
    }
  }
}
.td-name{
  position: relative;
  width: 78px;
  input{
    padding: 5px 0 5px 10px;
    width: 100%;
    position: absolute;
    top: 8px;
    right: 10px;
    border: 1px solid $kb-color3;
  }
}
.drop-down{
  position: relative;
  display: inline-block;
}
.drop-down-title{
  padding-right: 30px;
}
.drop-down-container{
  position: absolute;
}

.td-status{
  color: $kb-color13;
}
.principal-sign{
  display: inline-block;
  color: $kb-color30;
  margin-left: 4px;
}
.receipt-status{
  display: inline-block;
  padding: 3px 6px;
  text-align: center;
  color: #FFFFFF;
  font-size: 12px;
  background-color: #999999;
}
.receipt-status-sm{
  display: inline-block;
  padding: 4px ;
  text-align: center;
  color: #FFFFFF;
  font-size: 12px;
}

.nav-container-list{
  li{
    cursor: pointer;
    position: relative;
    &:last-child{
      margin-bottom: 0;
    }
    >a{
      color: $kb-color26;
      font-size: 14px;
      display: block;
      padding: 10px 30px;
      //display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      //width: 110px;
    }
    &:hover{
      //.station-num{
      //  display: none;
      //}
      //.edit-option{
      //  margin-right: 0;
      //  display: inline-block;
      //}
      &:before{
        content: " ";
        display: block;
        height: 100%;
        width: 8px;
        background-color: #a7b4c8;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  .select-list-hover:before{
      background-color: #3B82C7 !important;
  }
  .nav-container-list-hover{
    .icon-Openspace,.icon-Enclosedspace{
      span{
        &:before{
          color: #ffffff;
        }
      }
    }
    >a,>span,i{
      color: $kb-color27;
    }
    background-color: $kb-color28;
    &:before{
      background-color: #3B82C7 !important;
    }
  }
  .station-num,.edit-option{
    position: absolute;
    right: 30px;
    bottom: 5px;
  }
  .station-num{
    font-size: 14px;
    color: #999999;
  }
  .edit-option{
    font-size: 14px;
    color: #2ea1f8;
    cursor: pointer;
    display: none;
  }
}
.nav-container-list-else{
  li{
    padding: 10px 0;
    @include clear_fix;
    span{
      font-size: 14px;
      color: #666666;
      float: left;
    }
    a{
      font-size: 14px;
      color: #5fa7ec;
      float: right;
    }
  }
}
//报修表格
.accident-content {
  margin-bottom: 4px;
  >a {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    color: #666666;
    max-width: 320px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 8px;
    font-weight: 500;
    &:hover{
      color: #2ea1f8;
    }
  }
  .accident-degree{
    display: inline-block;
    vertical-align: middle;
    .icon-radio_button_unchecked,>span{
      color: #c8174b;
    }
    span{
      font-size: 14px;
    }
  }
}
.responsible-person{
  font-size: 14px;
  color: #999999;
  margin: 0 8px;
}
.update-time{
  font-size: 14px;
  color: #999999;
}
//分店管理
.city-subbranch{
  @include clear_fix;
  margin-bottom: 50px;
  header{
    font-size: 16px;
    color: #666666;
    margin-top: 30px;
    padding-bottom: 10px;
    font-weight: 500;
  }
}
.subbranch-message{
  width: 322px;
  /*height: 134px;*/
  /*overflow: hidden;*/
  float: left;
  margin-right: 54px;
  margin-top: 35px;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  &:nth-child(3n + 1){
    margin-right: 0;
  }
  img{
    @include img;
  }

  .location_info{
    display: flex;
    align-items: center;
    text-align: center;
    padding-left: 12px;
    padding-bottom: 18px;
  }
  .loc_info_list {
    text-align: left;
  }
  .loc_info_list p:first-child{
    font-weight: 300;
    font-size: 14px;
    color: #666;
  }

 .loc_info_list p:last-child{
    padding-top: 10px;
    font-size: 10px;
 }

 .loc_info_list p:last-child > span{
  font-size: 18px;
  color: #666666;
 }

  .space_location{
    border: 1px solid #e1e1e1;
	border-top: none;
	border-radius: 4px;
  }

  .space_location > p{
    padding: 10px 12px;
    font-weight: 500;
    color: #666666;
    font-size: 18px;
  }
  .subbranch-mark{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1 ;
    //background-color: black;
    //filter: Alpha(opacity=20); /* IE */
    //-moz-opacity: 0.2;    /* FF 主要是为了兼容老版本的FF */
    //opacity: 0.2;    /* FF */
    background-image: linear-gradient(0deg,rgba(0,0,0,.2) 0,transparent);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d000000', endColorstr='#00000000', GradientType=0);
  }
  &:hover{
    .subbranch-mark{
      //filter: Alpha(opacity=50); /* IE */
      //-moz-opacity: 0.5;    /* FF 主要是为了兼容老版本的FF */
      //opacity: 0.5;    /* FF */
      background-image: linear-gradient(0deg,rgba(0,0,0,.5) 0,transparent);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4d000000', endColorstr='#00000000', GradientType=0);
    }
  }
}

.subbranch-message:hover{
  /*background: red;*/
 box-shadow: 0px 0px 10px #cccccc;
 transform: translate3d(0px,-3px,200px);
 transition-duration: 0.5s;
}
.subbranch-station-name{
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 2;

  .subbranch-name{
    font-size: 18px;
    color: #ffffff;
    display: block;
  }
  .subbranch-station{
    font-size: 14px;
    color: #ffffff;
  }
}
.add-new-subbranch{
  text-align: center;
  margin-top: 120px;
  .add-new{
    float: none;
    margin-right: auto;
    margin-left: auto;
    margin-top: 1px;
    margin-bottom: 30px;
  }
  p{
    font-size: 18px;
    color: #666666;
  }
}
//分店首页
.visitor-principal{
  width: 20px;
  height: 20px;
  @include transform-translateY(4px);
  @include radius(50%);
  margin-right: 5px;
}
.customer-search{
  background-color: #ffffff;
  margin-bottom: 30px;
  position: relative;
  .add-staff-list{
    max-height: 400px;
  }
}
//crm-homepage
.flow-communicate{
  margin-bottom: 55px;
  background-color: #f6f8f9;
  border: 1px solid #DFE4E7;
  border-radius: 3px;
  padding: 20px 25px;
  position: relative;
  padding-bottom: 60px;
  label {
    float: left;
    color: #262626;
    width: 11.33333%;
    margin: 0;
    // margin-right: 20px;
    padding: 10px 0;
  }
  .communicate-arrow{
    position: absolute;
    width: 12px;
    height: 12px;
    top: -30px;
    *{
      display: block;
      border-width: 15px;
      position: absolute;
      border-style: solid dashed dashed dashed;
      font-size: 0;
      line-height: 0;
    }
    em{
      border-color:  transparent transparent #DFE4E7 transparent;
    }
    span{
      border-color:  transparent transparent #f6f8f9 transparent;
      top: 2px;
    }
  }
  .kb-box {
    margin-bottom: 20px;
    &>div {
      display: inline-block;
      width: 88.66667%;
      input {
        height: 36px;
      }
    }
  }
  .clear-fix {
    margin-bottom: 20px;
    .kb-form-alone {
      width: 100%;
      .kb_dropdown, &>div {
        width: 88.666666%;
      }
    }
  }
  .radio {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-color: #ffffff;
    border: solid 1px #e6e6e6;
    border-radius: 50%;
    margin-right: 5px;
    margin-bottom: -3px;
  }
  .radio-active {
    border: 6px solid #2ea1f8;
  }
}
.flow-box{
  @include clear_fix;
  li{
    float: left;
    padding: 8px 0;
    width: 67px;
    text-align: center;
    background-color: #999999;
    margin-right: 1px;
    cursor: pointer;
    &:nth-last-child(2){
      margin-left: 67px;
    }
    &:nth-last-child(2),&:nth-last-child(1){
      float: left;
    }
    &:nth-last-child(1){
      margin-right: 1px;
    }
    &:hover{
      background-color: #6c9dd5 !important;
    }
    a{
      color: #ffffff;
    }
  }
}
.communicate-box{
  border: 1px solid $kb-color3;
  margin-top: 10px;
  position: relative;
  textarea{
    height: 118px;
    width: 100%;
    padding: 10px 10px 40px 10px;
    border: none;
  }
}
.communicate-way{
  width: 100%;
  bottom: 0;
  left: 0;
  @include clear_fix;
  li{
    float: left;
    margin-right: 10px;
    padding: 10px 0;
    a{
      font-size: 12px;
      color: #999999;
      cursor: pointer;
    }
  }
  .communicate-way-container {
    width: 81.3%;
    float: left;
  }
  .communicate-active{
    a{
      color: #5fa7ec;
    }
  }
}
.visit-choice {
  width: 100%;
  margin-bottom: 20px;
  @include clear_fix;
  .visit-container {
    float: left;
    width: 88.66667%;
    .choices {
      float: left;
      &>li{
        float: left;
        margin-right: 23px;
        padding: 10px 0;
        a{
          font-size: 12px;
          color: #999999;
          cursor: pointer;
        }
      }
    }
    .visited-state {
      float: left;
      width: 100%;
      padding: 10px 0;
      div, span {
        display: inline-block;
        font-size: 12px;
        color: #999;
      }
      div {
        float: left;
        margin-right: 46px;
        span {
          margin-right: 18px;
        }
        .overtime {
          border: 1px solid #c53c3c;
          color: #c53c3c;
          text-align: center;
          font-size: 8px;
          padding: 0px 6px;
        }
      }
      .clean {
        float: right;
        margin: 0;
        cursor: pointer;
      }
    }
    .visit-choice-container {
      width: 100%;
      float: left;

      .kb-row {
        width: 100%;
        float: left;
        margin-bottom: 14px;
        .visit-input-ele {
          display: inline-block;
          float: left;
          width: 30%;
          margin-right: 3%;
        }
        .visit-input-ele-input {
          display: inline-block;
          float: left;
          width: 45%;
        }
        .kb-form-alone {
          label {
            width: 21%;
            color: #999999;
          }
          .kb_dropdown, &>div {
            width: 100%;
          }
        }
        .visit-record {
          display: inline-block;
          width: 202px;
          height: 90px;
          background: rgba(255,255,255,1);
          border: 1px solid #DFE4E7;
          border-radius: 3px;
          position: relative;
          margin: 0 10px 10px 0;
          padding: 11px;
          text-align: left;
          float: left;
          .a-link {
            font-size: 14px;
            color: rgba(95,167,236,1);
			word-break: keep-all;
			max-width: 100%;
          }
          .visit-receiver {
            display: block;
            color: #999;
            font-size: 13px;
            padding-top: 5px;
            word-break: keep-all;
          }
          .icon-close {
            display: none;
            width: 18px;
            height: 18px;
            background: rgba(255,86,86,1);
            font-size: 12px;
            color: #fff;
            margin-right: 0;
            padding-top: 3px;
            text-align: center;
            border-radius: 50%;
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(40%, -40%);
          }
          &:hover {
            .icon-close {
              display: inline-block;
            }
          }
        }
        .selected-guider {
          width: 100%;
          height: 38px;
          border: 1px solid #e5e5e5;
          padding: 10px;
		  color: #666;
		  border-radius: 4px;
        }
      }
    }
  }
  .communicate-active{
    a{
      color: #5fa7ec;
    }
  }

}
//员工详情
.staff-details-left,.staff-details-right{
  width: 50%;
  margin-top: 20px;
  .kb-section:first-child{
    margin-top: 0px;
  }
}
.staff-details-left{
  float: left;
  padding-right: 30px;
  border-right: 1px solid $kb-color3;
}
.staff-details-right{
  float: right;
  padding-left: 30px;
}
//分区type list
.area-type-list{
  @include clear_fix;
  margin-bottom: 20px;
  margin-left: 8px;
  margin-right: 8px;
  border-bottom:  1px solid $kb-color3;
  @include d-flex;
  justify-content: space-between;
  overflow-x: auto;
  overflow-y: hidden;
  li {
    cursor: pointer;
    > a {
      color: #999;
      font-size: 12px;
      display: block;
      padding: 4px;
      position: relative;
      margin-right: 4px;
	  white-space: nowrap;
	  &:last-child {
		  margin-right: 0;
	  }
      &:after{
        content: '';
        display: none;
        width: 100%;
        height: 2px;
        background-color: #5fa7ec;
        position: absolute;
        left: 0;
        bottom: -1px;
      }
      &:hover{
        color: #5fa7ec;
        &:after{
          display: block;
        }
      }
    }
  }
  .active{
    >a{
      color: #5fa7ec !important;
      &:after{
        display: block;
      }
    }
  }
}
.inventory-tr{
  border: none !important;
  >td{
    border-bottom: 1px solid #e6e6e6 !important;
  }
}

// 表格中的按钮样式
.table_btn_click {
  display: inline-block;
  text-align: center;
  color: #2ea1f8;
  font-size: 12px;
  border: 1px solid #2ea1f8;
  padding: 3px 8px;
  background-color: #fff!important;
  &:hover {
      color: #fff;
      background-color: #2ea1f8!important;
  }
}
.table_btn_delete {
  display: inline-block;
  text-align: center;
  color: #dd5a55;
  font-size: 12px;
  border: 1px solid #dd5a55;
  padding: 3px 8px;
  background-color: #fff!important;
  &:hover {
      color: #fff;
      background-color: #dd5a55!important;
  }
}

.meeting_available_time{
  display: flex;
  align-items: center;
  justify-content: round;
  padding-bottom: 15px;
  .meeting_room{
    text-align: center;
    flex: 1;
    .remain, .used{
      font-size: 35px;
    }
    >p{
      padding-top: 10px;
      font-size: 14px;
    }
  }
}

.uploadAnnex{
  &:hover{
    background: #5fa7ec;
    color: #ffffff !important;
    cursor:pointer;
  }
}


.inputStyle{
  >label{
    width: 25%;
  }
  >div{
    width: 75%;
  }
}

.feedback{
  width: 48px;
  height: 48px;
  background: #5ccd89;
  border-radius: 50%;
  position: fixed;
  bottom: 100px;
  right: 40px;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  color: #ffffff;
  z-index: 999;
}

.feedback_url > .feedback:hover{
  background-color: #63db93;
  cursor:pointer;
}

.feedback_url > .feedback:active{
  background-color: #4bc27b;
}

.feedback_url > .feedback:hover + .feedback_hover{
  display: block;
}

.feedback_hover{
  display: none;
  width: 235px;
  height: 70px;
  border: 1px solid #dfdfdf;
  position: fixed;
  bottom: 90px;
  right: 100px;
  background: #ffffff;
  box-shadow: 2px 2px 10px #999999;
  padding: 15px;
  border-radius: 3px;
  z-index: 999;
  .feedback_img{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    position: relative;
    img{
      display: block;
      width: 21px;
      height: 21px;
    }
    span{
      font-weight: 500;
      font-size: 14px;
      color: #707375;
      margin-left: 12px;
    }
    .arrow_left{
      width:0;
      height:0;
      border-top:10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid #ffffff;
      position: absolute;
      right: -25px;
      top: 10px;
    }
  }
  .feedback_text{
    font-size: 12px;
    color: #999999;
    font-weight: 300;
  }
}

.select_content_before{
  color: #e03641;
  position: absolute;
  top: 13px;
  left: -10px;
}

.timeline-table {
  margin-top: 30px;
  width: 100%;
  text-align: center;
  thead {
    background-color: #d4d9df;
  }
  th {
    border: solid 1px #a4b5c8;
    padding: 5px 25px;
    font-weight: normal;
    white-space: nowrap;
  }
  td {
    border: 1px solid #e6e6e6;
    padding: 4px 10px;
    height: 43px;
    white-space: nowrap;
  }
}

.timeline-table-right {
  thead>tr>th:first-child, tbody>tr>td:first-child {
    border-left: none;
  }
  tbody>tr>td {
    padding: 0;
    display: table-cell;
    vertical-align: middle;
    height: 43px;
    border-top: none;
    border-bottom: none;
    position: relative;
    .period-span {
      display: inline-block;
      height: 37px;
      position: absolute;
      top: 3px;
    }
  }
}
