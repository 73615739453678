.status-initial {
  background-color: $kb-color31 !important;
}

.status-certain {
  background-color: $kb-color48 !important;
}

.status-scheme {
  background-color: $kb-color50 !important;
}

.status-audit {
  background-color: #cb962e !important;
}

.status-win {
  background-color: $kb-color32 !important;
}

.status-entered {
  background-color: $kb-color42 !important;
}

.status-disable,
.status-archived {
  background-color: $kb-color43 !important;
  color: #666666 !important;

  a {
    color: #c8c8c8 !important;
  }
}

.status-failed {
  background-color: $kb-color52 !important;
  color: #ffffff !important;
}

.status-shelved {
  background-color: $kb-color51 !important;
}

.receipt-going {
  background-color: $kb-color31 !important;
  color: #ffffff !important;
}

.receipt-confirmed {
  background-color: $kb-color42 !important;
  color: #ffffff !important;
}

.receipt-approving {
  background-color: $kb-color42 !important;
  color: #ffffff !important;
}

.receipt-finish {
  background-color: $kb-color32 !important;
  color: #ffffff !important;
}

.receipt-debt {
  background-color: $kb-color43 !important;
  color: #666666 !important;
}

.receipt-new {
  background-color: $kb-color31 !important;
  color: #ffffff !important;
}

.receipt-expiring {
  background-color: #966acd !important;
  color: #ffffff !important;
}

.receipt-unpaid {
  background-color: $kb-color44 !important;
  color: #FFFFFF !important;
}

.receipt-refunding {
  background-color: $kb-color33 !important;
  color: #ffffff !important;
}

.receipt-pending {
  background-color: $kb-color46 !important;
  color: #ffffff !important;
}

.receipt-finish_new {
  background-color: $kb-color32 !important; //已签
  color: #ffffff !important;
}

.receipt-expiring_new {
  background-color: $kb-color48 !important; //快到期
  color: #ffffff !important;
}

.receipt-cancelled_new {
  background-color: $kb-color43 !important; //已取消
  color: #666666 !important;
}

.receipt-overdue_new {
  background-color: $kb-color49 !important; //已结束
  color: #DD5A55 !important;
}

.receipt-rejected_new {
  background-color: $kb-color33 !important; //审批拒绝
  color: #ffffff !important;
}

.receipt-draft_new {
  background-color: $kb-color50 !important; //待签字
  color: #ffffff !important;
}

.receipt-waiting_approving_new {
  background-color: $kb-color46 !important; //待审批
  color: #ffffff !important;
}

.receipt-already_approving_new {
  background-color: $kb-color45 !important; //审批中
  color: #ffffff !important;
}

.receipt-approving_new {
  background-color: $kb-color46 !important; //审批中
}


//新增状态
.receipt-partially_paid,
.receipt-in_progress {
  background-color: $kb-color45 !important; //进行中
  color: #ffffff !important;
}

.receipt-paid,
.receipt-completed {
  background-color: $kb-color32 !important; //已完成
}

.receipt-overdue {
  background-color: $kb-color33 !important; // 逾期
  color: #ffffff !important;
}

.receipt-closed {
  background-color: $kb-color43 !important; //关闭
  color: #c8c8c8 !important;
}

.receipt-approving {
  background-color: $kb-color46 !important; //审批中
}

.receipt-draft {
  background-color: $kb-color50 !important; //未签署
}

.receipt-submit_task {
  background-color: $kb-color48 !important; //提交
  color: #ffffff !important;
}

.receipt-reopen {
  background-color: $kb-color50 !important; //重新打开
  color: #ffffff !important;
}

//合同状态
.subscription-active {
  background-color: $kb-color39 !important;
  color: #ffffff !important;
}

.subscription-expiring {
  background-color: #966acd !important;
  color: #ffffff !important;
}

.subscription-cancelled {
  background-color: $kb-color32 !important;
  color: #ffffff !important;
}

.subscription-ended {
  background-color: $kb-color33 !important;
  color: #ffffff !important;
}

.subscription-approving {
  background-color: #ecbc23 !important;
  color: #ffffff !important;
}

.subscription-draft {
  background-color: #d1658f !important;
  color: #ffffff !important;
}

//报修详情滑动按钮
@mixin record-status {
  display: inline-block;
  padding: 3px 6px;
  font-size: 12px;
}

.record-user {
  @include record-status;
  background-color: #81c55b;
  color: #ffffff;
}

.record-inside {
  @include record-status;
  background-color: #e6e6e6;
  color: #666666;
}

//工位开放封闭状态
.default-space {
  padding: 3px 12px;
  color: #ffffff;
  font-size: 12px;
}

.open-space {
  background-color: #5655ce;
}

.close-space {
  background-color: #4acbbb;
}

.flexible-space {
  background-color: #81c55b;
}

.floor-space {
  background-color: #999999;
}

//服务商管理
.default-status {
  padding: 3px 12px;
  color: #ffffff;
  font-size: 12px;
  background-color: #999999;
  display: inline-block;
  min-width: 64px;
  text-align: center;
}

.service-request_accepted {
  background-color: $kb-color31 !important;
}

.service-canceled {
  background-color: $kb-color43 !important;
  color: #666666 !important;
}

.service-completed {
  background-color: $kb-color47 !important;
}

.service-requested {
  background-color: $kb-color48 !important;
}

.service-rejected {
  background-color: $kb-color33 !important;
}


.color-today {
  color: #c8174b !important;
}

.color-tomorrow {
  color: #73b43b !important;
}

.color-other {
  color: #999999 !important;
}

.color-link {
  color: #2ea1f8 !important;
}

.color-price {
  color: #ff5a60 !important;
}

//公司账单已生成账单 未来账单
.receipt-started {
  background-color: #73bb4b !important;
  color: #ffffff !important;
}

.receipt-later {
  background-color: #2ea1f8 !important;
  color: #ffffff !important;
}

//分店首页 租户状态
.rent-entering {
  //即将入住
  background-color: $kb-color31 !important;
  color: #ffffff !important;
}

.rent-expiring {
  //即将到租
  background-color: $kb-color33 !important;
  color: #ffffff !important;
}

.rent-leaved {
  //离开
  background-color: #966acd !important;
  color: #ffffff !important;
}

//会议室预定会议progress
.progress-upcoming {
  //未开始
  color: #2ea1f8;
  border-color: #2ea1f8;
}

.progress-proceeding {
  //进行中
  color: #73b43b;
  border-color: #73b43b;
}

.progress-ended {
  //结束
  color: #c8174b;
  border-color: #c8174b;
}

//工位状态
.area-all {
  background-color: $kb-color39 !important;
  color: #ffffff !important;
}

.area-free {
  background-color: #749aef !important;
  color: #ffffff !important;
}

.area-assigned {
  background-color: #9ED562 !important;
  color: #ffffff !important;
}

.area-expiring {
  background-color: #F66E73 !important;
  color: #ffffff !important;
}

.area-list_all {
  background-color: #ffffff !important;
  color: #ffffff !important;
}

.area-list_free {
  background-color: #ffffff !important;
  border: 1px solid #aabac6 !important;
  color: #ffffff !important;
}

.area-list_reserved {
  background-color: #8DA9FF !important;
  color: #ffffff !important;
}

.area-list_assigned {
  background-color: #9ED562 !important;
  color: #ffffff !important;
}

.area-list_expiring {
  background-color: #F66E73 !important;
  color: #ffffff !important;
}

.area-list_partial_free {
  background-color: #edffda !important;
  color: #ffffff !important;
}

.area-public_office {
  background-color: #80a4f1 !important;
  color: #ffffff !important;
}

.area-private_office {
  background-color: #ecbc23 !important;
  color: #ffffff !important;
}

.area-list_free {
  background-color: #DBEBFF !important;
  color: #ffffff !important;
}

.area-list_already_rented {
  background-color: #87A8FF !important;
  color: #ffffff !important;
}

.area-list_not_available {
  background-color: #D3D3D3 !important;
  color: #ffffff !important;
}

//边框筛选条件
.border-show_status {
  display: inline-flex;
  align-items: center;
  padding: 5px 15px;
  border: 1px solid $kb-color3;
  border-left: none;
  cursor: pointer;

  &:first-child {
    border-left: 1px solid $kb-color3;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .color-show {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

.border-show_status_active {
  background-color: #2ea1f8;
  border-color: #2ea1f8;

  .status-text {
    color: #ffffff;
  }
}

// CRM库存

.one-month {
  background-color: #D84848 !important;
  color: #ffffff !important;
}

.two-month {
  background-color: #F88C3F !important;
  color: #ffffff !important;
}

.three-month {
  background-color: #A3D676 !important;
  color: #ffffff !important;
}

.more-month {
  background-color: #CCCCCC !important;
  color: #ffffff !important;
}

// 意愿程度
.desire-strong {
  background-color: #D84848 !important;
}

.desire-ordinary {
  background-color: #78C832 !important;
}

.desire-notstrong {
  background-color: #F5A623 !important;
}

.desire-notclear {
  background-color: #E1E1E1 !important;
  color: #999999 !important;
}