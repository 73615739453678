.location-box{
  margin-bottom: 30px;
  header{
    @include clear_fix;
    padding-bottom: 4px;
    font-size: 18px;
    font-weight: 500;
    .more{
      height: 24px;
      font-size: 12px;
      cursor: pointer;
      color:  $kb-color14;
      display: block;
      float: right;
      padding: 0px 10px;
      line-height: 24px;
      border: 1px solid $kb-color14;
      background-color: #ffffff;
      @include transform-translateY(2px);
      &:hover{
        color: #ffffff;
        background-color: $kb-color14;
      }
    }
  }
  .chart-out_box{
    padding-top: 20px;
    @include d-flex;
    justify-content: space-between;
    .chart-box{
      min-width: 155px;
    }
  }
}
.short-btn{
  display: block;
  width: 100px;
  height: 100px;
  padding-top: 15px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  &:nth-child(2n-1){
    margin-right: 19px;
  }
  i{
    font-size: 40px;
  }
  span{
    display: block;
    margin-top: 10px;
  }
  &:hover{
    i,span{
      color: #2ea1f8;
    }
  }
}
.short-btn_active{
  background-color: #2ea1f8;
  i,span{
    color: #ffffff !important;
  }
}

// 甲方信息
.location-provider-container {
  margin-bottom: 10px;
  text-align: left;
  overflow: hidden;
  .tip-msg {
    width: 100%;
    height: 40px;
    padding: 0 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #FF5157;
    color: #fff;
    font-size: 13px;
    .icon-hint {
        opacity: 0.7;
        font-size: 16px;
        margin-right: 12px;
    }
  }
}

.location-provider {
    padding: 16px 20px;
    position: relative;
    border: 1px solid #DFE2E5;
    border-radius: 4px;
    background-color: #FAFBFC;
    .provider-line{
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:last-child {
        margin-bottom: 0;
      }
      >div{
        flex: 1;
        >span:first-child{
          color: #7F8FA4;
          font-size: 11px;
          display: inline-block;
          text-align: left;
        }
        >span:last-child{
          color: #354052;
          font-size: 13px;
          font-weight: 500;
          display: block;
          padding-top: 3px;
        }
      }
    }
    .operate-provider{
      display: none;
      position: absolute;
      top: 10px;
      width: 16px;
      height: 16px;
      font-size: 14px;
      border-radius: 100%;
      color: #333;
      text-align: center;
      line-height: 17px;
      cursor: pointer;
      &:hover {
        color: #2ea1f8;
      }
    }
    .delete-provider {
      right: 10px;
      &:hover {
        color: #FF5656;
      }
    }
    .update-provider {
      right: 35px;
    }
    &:hover{
      .operate-provider{
        display: block;
      }
    }
}

.notification-day-block {
    padding: 45px 25px 0;
    position: relative;
    transform: translateY(-1px);
    border-top: 1px solid #DFE2E5;
    .day-time {
        display: inline-flex;
        align-items: center;
        height: 28px;
        padding: 0 20px;
        color: #7F8FA4;
        text-align: center;
        position: absolute;
        top: -14px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
    }
    .noti-block {
        border-left: 2px solid #EFF3F6;
        position: relative;
        font-size: 13px;
        padding-bottom: 40px;
        &:last-child {
            border-left: 0;
        }
        .noti-header {
            position: relative;
            left: -14px;
            top: -13px;
        }
        .noti-centent {
			padding-left: 5px;
            font-weight: 500;
            color: #354052;
        }
        .noti-time {
            padding-left: 23px;
			color: #7F8FA4;
			transform: translateY(-10px);
        }
    }
}

.flex-row-right-270 {
	display: flex;
	height: 450px;
	>div:first-child {
		flex: 1 1 auto;
	}
	>div:last-child {
		width: 270px;
		flex: 0 0 auto;
		margin-left: 20px;
	}
}

.flex-row-evenly {
	display: flex;
	height: 450px;
	>div:first-child {
		flex: 1 1 auto;
		width: 50%;
	}
	>div:last-child {
		flex: 1 1 auto;
		margin-left: 20px;
		width: 50%;
	}
}
