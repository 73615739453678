.kb-folding-cube {
    margin: 20px auto;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.kb-folding-cube .kb-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
}
.kb-folding-cube .kb-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #5fa7ec;
  -webkit-animation: kb-foldCubeAngle 2.4s infinite linear both;
          animation: kb-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
      -ms-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}
.kb-folding-cube .kb-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.kb-folding-cube .kb-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.kb-folding-cube .kb-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.kb-folding-cube .kb-cube2:before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.kb-folding-cube .kb-cube3:before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.kb-folding-cube .kb-cube4:before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
@-webkit-keyframes kb-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes kb-foldCubeAngle {
  0%, 10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  } 25%, 75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  } 90%, 100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

.kb-spinner {
  margin: 100px auto 0;
  width: 100px;
  text-align: center;
}

.kb-spinner > div {
  width: 16px;
  height: 16px;
  margin: 4px;
  background-color: #e2e2e2;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: kb-bouncedelay 1.4s infinite ease-in-out both;
  animation: kb-bouncedelay 1.4s infinite ease-in-out both;
}

.kb-spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.kb-spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes kb-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes kb-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
