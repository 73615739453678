// transitionName ReactCSSTransitionGroup
.opacity-enter {
  opacity: 0.01;
  transition: opacity .3s linear;
  //transform: translateY(60px);
}

.opacity-enter.opacity-enter-active {
  opacity: 1;
  //transform: translateY(0);
  //transition: transform 1000ms ease-in-out;
}

.opacity-leave {
  opacity: 1;
  transition: opacity .3s linear;
  //transform: translateY(0);
}

.opacity-leave.opacity-leave-active {
  opacity: 0.01;
  //transform: translateY(-60px);
  //transition: transform 1000ms ease-in-out;
}


.background-enter {
  transform: translate(100%);
}
.background-enter.background-enter-active {
  transform: translate(0%);
  transition: transform 750ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.background-leave {
  transform: translate(0%);
}
.background-leave.background-leave-active {
  transform: translate(100%);
  transition: transform 750ms cubic-bezier(0.68, -0.55, 0.27, 1.55);
}


.example-enter {
  opacity: 0.01;
  transition: opacity .5s ease-in;
}

.example-enter.example-enter-active {
  opacity: 1;
}

.example-leave {
  opacity: 1;
  transition: opacity .5s ease-in;
}

.example-leave.example-leave-active {
  opacity: 0;
}

.crm-new-opp-progress-bar {
  transition: width 1s ease-in-out;
}

.blue-background-class {
  background-color: #c8ebfb;
}
