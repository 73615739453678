// 弹出框及form表单部分
#popup-panel-container {
  width: 620px;
  position: absolute;
  top: 85px;
  left: calc( 50% - 310px );
  background-color: white;
  border: 1px solid #CCCCCC;
  z-index: 2;
  margin-bottom: 130px;
}
.popup-panel-header {
  border-bottom: 1px solid #E4E4E4;
}
.popup-panel-header-close {
  line-height: 3.4em;
  padding-right: 30px;
  cursor: pointer;
  color: #787878;
  font-size: 18px;
  margin-top: 5px;
}
.popup-panel-content-container {
  padding: 30px;
}

//图片编辑弹出
//.logo-popup{
//  border: none !important;
//}
//.logo-popup:before,.logo-popup:after{
//  content: "";
//  width: 620px;
//  height: 30px;
//  background-color: #999999;
//  display: block;
//}
.logo-box{
  //height: 360px;
  // background-color: #000000;
  position: relative;
}
.initial-logo{
  position: absolute;
  @include p-center;
}
.change-logo{
  margin-bottom: 30px;
  a{
    span{
      font-size: 14px;
      margin-left: 8px;
      color: #77b3ec;
    }
    i{
      font-size: 18px;
      transform: translateY(3px);
      display: inline-block;
      color: #68bef9;
    }
  }
}
.kb-title{
  border-bottom:  1px solid $kb-color3;
  padding-bottom: 10px;
  span{
    font-size: 18px;
    color: #666;
    font-weight: 500;
  }
}
.kb-title_num{
  span{
    color: #666;
    font-size: 14px;
  }
}
.certain-logo{
  margin-top: 30px;
  margin-bottom: 10px;
  img{
    width: 200px;
    height: 160px;
  }
}
.logo-size{
  margin-bottom: 60px;
  span{
    font-size: 14px;
    color: #878787;
  }
}
.upload-num-visible{
  display: block !important;
}
.upload-num{
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(1,1,1,.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1111;
  .upload-content{
    @include p-center;
    span:nth-child(1){
      font-size: 24px;
      color: #ffffff;
      margin-bottom: 20px;
      display: block;
      text-align: center;
    }
    span:nth-child(2){
      font-size: 14px;
      color: #FFFFFF;
    }
  }
}
.show-picture{
  height: 450px;
  position: relative;
  background-color: #000000;
  .header-close{
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    color: #d6d6d6;
    display: none;
  }
  img{
    object-fit: contain;
    width:  100%;
    height: 100%;
  }
  &:hover{
    .header-close{
      display: block;
    }
  }
  .icon-navigate_next,.icon-navigate_before{
    position: absolute;
    font-size: 60px;
    color: $kb-color41;
    top: 50%;
    @include transform-translateY(-50%);
    cursor: pointer;
    &:hover{
      color: #ffffff;
    }
  }
  .icon-navigate_next{
    right: 20px;
  }
  .icon-navigate_before{
    left: 20px;
  }
}
