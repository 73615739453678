.add-staff-component {
  position: relative;
  input {
    width: 100%;
    border: 1px solid #dfe3e9;
    border-radius: 4px;
    padding: 8px 12px 8px 42px;
    background: #ffffff url("#{$dev-url}header-search.jpg") no-repeat 15px
      center;
    background-size: 14px 14px;
    &:focus {
      border: 1px solid #a0a0a0 !important;
    }
  }
  .currency-symbol {
    position: absolute;
    width: 90px;
    height: 38px;
    text-align: center;
    line-height: 34px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(180deg, #fcfcfc, #eceff3);
    color: #354052;
    border: 1px solid #dfe3e9;
    cursor: pointer;
  }
}
.add-inline-component {
  position: relative;
  display: inline-block;
  input {
    width: 150px;
    border: 0px solid #e6e6e6;
    padding-left: 42px;
    background: #ffffff url("#{$dev-url}header-search.jpg") no-repeat 15px
      center;
    background-size: 14px 14px;
  }
}

.add-search-argument {
  padding: 8px 10px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.add-staff-list {
  position: absolute;
  padding: 20px 0;
  z-index: 10;
  width: 100%;
  top: 40px;
  left: 0;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.32);
  max-height: 200px;
  overflow-y: auto;
  li {
    cursor: pointer;
    a {
      @include clear_fix;
      display: block;
      padding: 10px 30px;
    }
    @include clear_fix;
    &:hover {
      background-color: $kb-color41;
      //.add-staff-name,.search-company-name,.company-name{
      //  color: #ffffff;
      //}
    }
  }
  .company-name {
    margin-left: 10px;
    margin-top: 5px;
    display: block;
    float: left;
  }
  .add-staff-portrait {
    width: 30px;
    height: 30px;
    float: left;
    img {
      @include img;
      @include radius(50%);
    }
  }
  .add-staff-name {
    margin-left: 10px;
    margin-top: 5px;
    display: block;
    float: left;
    max-width: 68px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.add-new-staff {
  .company-name {
    max-width: 100px;
    color: #2ea1f8;
  }
  &:hover {
    .add-staff-name {
      color: #2ea1f8 !important;
    }
  }
}

//CRM搜索添加客户显示列表
.search-customer {
  position: absolute;
  padding: 20px 0;
  z-index: 10;
  width: 100%;
  top: 40px;
  left: 0;
  background: #ffffff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.32);
  max-height: 400px;
  overflow-y: auto;
  li {
    > a {
      display: block;
      padding: 10px 30px;
      @include clear_fix;
      cursor: pointer;
      &:hover {
        background-color: $kb-color41;
      }
    }
  }
  .customer-message {
    @include d-flex;
    align-items: center;
    .customer-img {
      margin-right: 10px;
      img {
        display: inherit;
      }
    }
    .customer-name {
      font-weight: 500;
      margin-right: 10px;
    }
  }
  .customer-principal {
    @include d-flex;
    align-items: center;
    padding-top: 5px;
    color: #999;
  }
}
