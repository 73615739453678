// top header
.header-top-menu {
  height: 66px;
  background-color: $kb-color8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  //overflow: hidden;
}
.p-top-lg{
  padding-top: 50px !important;
}
.header-logo-container {
  @include d-flex;
  height: 100%;
  align-items: center;
  img{
    &:hover{
      transition: transform 300s linear;
      transform: rotate(3600deg);
    }
  }
}
.header-nav {
  display: flex;
  align-items: center;
  float: left;
  height: 66px;
}
.header-nav-list {
  display: inline-block;
  .header-list-active{
    a{
      color: #ffffff;
    }
  }

  .kb_dropdown__content {
    position: relative;
  }

  .bar-drop-down {
    top: 0;
  }

  .bar-all-locations {
	border-bottom: 1px solid #DBDBDB;
    margin-bottom: 30px;
    padding-bottom: 14px;
    span {
      display: inline-block;
      padding: 0 14px;
	  border: 1px solid #e6e6e6;
	  border-radius: 4px;
      height: 40px;
	  line-height: 40px;
	  color: #565f6d;
      &:hover {
        color: #fff;
		background: #2ea1f8;
		border-color: #1a91eb;
      }
    }
  }

  >li {
    display: inline;
    margin-right: 30px;
    &:last-child{
      margin-right: 40px;
    }
    span, a{
      cursor: pointer;
      color: $kb-color24;
      font-size: 16px;
      font-weight: 500;
    }
    span:hover, a:hover{
      cursor: pointer;
      color: $kb-color25;
      i {
        color: $kb-color25;
      }
    }

    i {
      margin-left: 4px;
      color: $kb-color24;
    }
  }

  .icon-company {
    margin-right: 4px;
  }
}
.user-avatar-container {
  margin-right: 30px;
  position: relative;
  cursor: pointer;
  .user-avatar {
    width: 36px;
    height: 36px;
    border: 1px solid #3f3f3f;
    vertical-align: middle;
    @include radius(50%);
  }
  .msg-warn {
    width: 7px;
    position: absolute;
    height: 7px;
    background-color: $kb-color9;
    bottom: 8px;
    right: 2px;
    @include radius(50%);
  }
}
.user-dropdown{
  width: 120px;
  background-color: #FFFFFF;
  z-index: 10;
  a{
    display: block;
    cursor: pointer;
    //padding: 5px 0;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #354052;
	font-size: 14px;
	border-bottom: 1px solid #e8ecf3;
	&:last-child {
		border-bottom: none;
	}
    &:hover{
		background-color: #f2f4f7;
		color: #2ea1f8;
		text-decoration: none;
    }
  }
}
.margin-top-lg{
  // padding-top: 60px;
  height: 100%;
}
// location search bar
.sub-nav-bar{
  background-color: white;
  height: 60px;
  border-bottom: 1px solid $kb-color3;
  text-align: center;
  padding: 0 30px;
  position: absolute;
  top: 66px;
  width: 100%;
  z-index: 998;
}
//搜索员工和公司
.company-staff-result{
  width: 336px;
  position: absolute;
  right: 0px;
  top: 56px;
  background-color: white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.32);
  z-index: -1;
  .add-staff-list{
    position: static;
    border: none;
    max-height: 380px;
    overflow-y: auto;
    box-shadow: none;
    li{
      cursor: pointer;
    }
  }
  .add-staff-name{
    margin-left: 10px;
    margin-top: 5px;
    display: block;
    float: left;
    max-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .search-company-name{
    margin-left: 10px;
    margin-top: 5px;
    display: block;
    float: left;
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.search-company-result{
  .search-company-name{
    max-width: 160px;
  }
  .add-staff-portrait{
    img{
      border-radius: inherit;
    }
  }
}
.search-type-box{
  padding-top: 20px;
  text-align: left;
  position: relative;
  .icon-close{
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    color: #999999;
    &:hover{
      color: $kb-color28;
    }
  }
}

//.nav-section-tab-active{
//  border-bottom: 2px solid #5FA7EC;
//}

.sub-nav-bar{
  .nav-section-tabs{
    margin-bottom: 0;
    width: calc(100% - 60px);
    margin-left: auto;
    margin-right: auto;
    li{
      margin-right: 15px;
      &:hover{
        border-bottom: 2px solid #1990ea;
        &:after{
          height: 0;
        }
      }
    }
    a{
      font-size: 12px;
    }
    .nav-search-tab-active{
      border-bottom: 2px solid #1990ea !important;
    }
  }
  //快捷入口
  .shortcut-entrance{
    @include transform-translateY(-50%);
    position: absolute;
    right: 300px;
    top: 50%;
    .icon-Addpictures{
      font-size: 30px;
      color: #1990ea;
      cursor: pointer;
    }
  }
}

.bar-address{
  //position: absolute;
  //left: 30px;
  //top: 50%;
  //@include transform-translateY(-50%);
  float: left;
  margin-top: 18px;
  a{
    color: #444444;
    font-size: 16px;
    font-weight: 500;
    @include d-flex;
    align-items: center;
    span{
      margin-right: 5px;
    }
    i{
      @include transform-translateY(0);
    }
  }
}
.bar-drop-down{
  width: 640px;
  max-height: 500px;
  overflow-y: auto;
  padding:  10px 30px 30px 30px;
  background-color: white;
  border: 1px solid $kb-color3;
  z-index: 998;
  .city-list{
    margin-bottom: 10px;
    @include clear_fix;
    .city-name{
      color: $kb-color36;
      font-size: 14px;
      height: initial;
      line-height: initial;
      margin-bottom: 14px;
      text-align: left;
    }
  }
  .company-name{
    li{
      float: left;
	  border: 1px solid $kb-color3;
	  border-radius: 4px;
      margin-right: 20px;
      margin-bottom: 20px;
      span{
        display: block;
        padding: 0 14px;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
		color: #565f6d;
      }
      &:hover{
        span{
          color: #ffffff;
        }
        background-color: #39b54a;
        border: solid 1px #249533;
      }
      &:active{
        span{
          color: #ffffff;
        }
        background-color: #39b54a;
        border: solid 1px #249533;
      }
    }
  }
}
.sub-nav-list {
  display: inline-block;
  line-height: 60px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  a{
    color: $kb-color11;
    display: inline-block;
    margin-right: 25px;
    font-size: 14px;
  }
  a:hover{
    color: $kb-color22;
    position: relative;
    &:after{
      content: "";
      width: 100%;
      height: 3px;
      background-color: $kb-color22;
      position: absolute;
      bottom: 13px;
      left: 0;
    }
  }
  .active{
    color: $kb-color22;
    position: relative;
    &:after{
      content: "";
      width: 100%;
      height: 3px;
      background-color: $kb-color22;
      position: absolute;
      bottom: 13px;
      left: 0;
    }
  }
}
.sub-nav-search{
  //position: absolute;
  //right: 30px;
  //top: 50%;
  //@include transform-translateY(-50%);
  float: right;
  border: 1px solid #E6E6E6;
  background-color: #FFFFFF;
  border-radius: 4px;
  &:focus{
    border: 1px solid #A0A0A0 !important;
  }
  .search-type{
    border-right: 1px solid #e6e6e6;
    padding: 0 15px;
  }
  .search-type_content {
    width: 75px;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.32);
    padding: 10px 0;
    >li{
      padding: 5px;
      cursor: pointer;
      padding-left: 15px;
      &:hover{
        background-color: #f4f6f7;
      }
    }
  }
  input{
    width: 400px;
    padding: 8px 12px 8px 12px;
    background: #ffffff  url("#{$dev-url}header-search.jpg") no-repeat calc(100% - 15px) center;
    background-size: 14px 14px;
    border: none;
  }
}
.common-search{
  input{
    width: 100%;
	border: 1px solid #E6E6E6;
	border-radius: 4px;
    padding: 8px 12px 8px 42px;
    background: #ffffff  url("#{$dev-url}header-search.jpg") no-repeat 15px center;
    background-size: 14px 14px;
    &:focus{
      border: 1px solid #A0A0A0 !important;
    }
  }
}
.guarantee-search{
  right: 0;
  input{
    width: 266px;
    padding: 11px 12px 10px 42px;
  }
}

.search-staff-company{
  position: relative;
}


.header-bar-title {
  padding: 20px 0!important;
  font-size: 18px;
  font-weight: 500;
  border-bottom: 1px solid #F3F1F1;
}
