.section-box{
  position: relative;
}
.section{
  background-color: #FFFFFF;
  padding: 30px 0;
}
//tree start
.tree-view{

}
.tree-view_item {
  position: relative;
  display: inline-block;
  width: 100%;
  padding-left: 30px;
  cursor: pointer;
  &:hover{
    background-color: $kb-color41;
    nobr{
      //color: #ffffff;
    }
    .icon-settings{
      display: inline-block;
      //color: #ffffff;
    }
    .icon-arrow_drop_down_circle{
      //color: #ffffff;
    }
  }
}
.tree-view_click{
  background-color: #2ea1f8 !important;
  .node{
    color: #ffffff !important;
  }
  .icon-settings:hover,.icon-arrow_drop_down_circle:hover{
    color: #ffffff !important;
  }
  .icon-arrow_drop_down_circle{
    color: #ffffff !important;
  }
  //.node{
  //  color: $kb-color16;
  //}
}
.tree-view_children {
  position: relative;


}
.tree-view_item .icon-arrow_drop_down_circle{
  color: #333333;
  top: 8px;
  position: absolute;
  display: block;
  @include transform-rotateZ(-90deg);
  &:hover{
    //color: #ffffff;
  }
}

.tree-view_item .tree-view_arrow-down {
  @include transform-rotateZ(0deg);
}


.tree-view_content .icon-settings{
  display: none;
  position: absolute;
  right: 30px;
  font-size: 16px;
  font-weight: normal;
  top: 50%;
  @include transform-translateY(-50%);
  color: #333333;
  &:hover{
    //color: #ffffff;
  }
}
.section-option{
	display: block;
    border: none;
    border-radius: 4px;
    padding: 15px 10px;
    background-color: #FFFFFF;
    position: absolute;
    z-index: 100;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  img{
    width: 14px;
    height: 14px;
    float: left;
    margin-right: 5px;
    margin-top: 1px;
  }
  li{
    @include clear_fix;
    width: 138px;
    padding: 13px 0 13px 10px;
    font-size: 14px;
  }
  li:nth-of-type(4){
    margin-top: 15px;
  }
  li:hover{
    background-color: $color20;
    //color: #ffffff;
    a{
      //color: #ffffff;
    }
  }
  .option-underline{
    position: absolute;
    padding: 0;
    bottom: 68px;
    left: 0;
    content: "";
    width: 158px;
    height: 1px;
    background-color: #CCCCCC;
  }
}
.node{
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  font-size: 16px;
  color: #333333;
}
.tree-view_content{
  display: inline-block;
  float: left;
  //min-width: 110px;
  margin-left: 12px;
  margin-right: 10px;
  padding: 5px 0 5px 0;
  text-indent: 5px;
  //position: relative;
  padding-right: 25px;
  cursor: pointer;
  .kb_dropdown{
    position: inherit;
  }
}

.dept-option-show{
  display: none;
}

.tree-view .kb_dropdown {
  display: block
}
