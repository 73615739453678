.kb-dropdown-box {
    position: relative;
    margin-right: 25px;
    width: 100%;
    

    .Select-arrow-zone {
        position: absolute;
        right: 0;
        top: 0;
        height: 36px;
        line-height: 36px;
    }

    .kb-input::-webkit-input-placeholder{
        color:#dedede;
    }
    .kb-input:-moz-placeholder{
        color:#dedede;
    }
    .kb-input::-moz-placeholder{
        color:#dedede;
    }
    .kb-input:-ms-input-placeholder{
        color:#dedede;
    }

    input {
        position: relative;
        &:focus {
            border: 1px solid #DFE3E9;
        }
    }

    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: #F5F5F5;
        cursor: pointer;
    }
}

.kb-select-box {
    border: 1px solid #DFE3E9;
    background-color: #fff;
    position: absolute;
    top: 34px;
    height: 156px;
    width: 100%;
    overflow-y: auto;
    z-index: 10000;
}

.kb-select-row {
    padding: 10px 0 10px 10px;
    background-color: #FFF;
    cursor: pointer;
    &:hover {
        color: #2ea1f8;
        background: #f1f4f8;
    }
}

.kb-selectt {
    position: relative;
}

.kb-select-add {
    position: absolute;
    z-index: 10000;
    background-color:rgba(240,243,248,1);
    height: 42px;
    line-height: 42px;
    text-align: center;
    width: 100%;
    color: #279BF3;
    cursor: pointer;
	border: 1px solid #DFE3E9;
	border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
	border-top: none;
    i {
        font-weight: bold;
        margin-right: 6px;
    }
}

.kb-selectt-new {
    input {
        text-indent: 11px;
        color: #354052;
        border: 1px solid #DFE3E9;
        font-weight: 500;
        border-radius: 4px;
        height: 36px;
        outline: none;
    }
    label {
        font-size: 11px;
        margin: 9px 0 3px;
        color: #7F8FA4;
        position: relative;
        white-space: nowrap;
        width: 100% !important;
    }
}

.kb-select-multi-box {
    position: absolute;
    top: 0;
    height: 36px;
    line-height: 36px;
    overflow: hidden;
    width: auto;
    max-width: 80%;
}

.kb-select-multi-row {
    background-color: #f1f4f8;
    border-radius: 4px;
    border: 1px solid #d5dce6;
    color: #354052;
    display: inline-block;
    font-size: 14px;
    margin-left: 5px;
    margin-top: 4px;
    vertical-align: top;
    height: 28px;
    line-height: 26px;
}

.kb-select-multi-r1 {
    cursor: pointer;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
    padding: 0px 5px 3px;
    color: #354052;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    border-right: 1px solid #d5dce6;
}

.kb-select-multi-r2 {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    padding: 0px 5px;
    display: inline-block;
    vertical-align: middle;
    color: #354052;
    font-size: 14px;
    border-spacing: 0;
    border-collapse: separate;
}