.lockersList{
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;
  .locker{
    width: 88px;
    height: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    margin-bottom: 16px;
    cursor: pointer;
    border-radius: 4px;
    position: relative;
    &:nth-child(8n){
      margin-right: 0;
    }
    &:hover{
      box-shadow: 0 0 4px 0 rgba(0,0,0,.5);
      .locker_popup{
        display: block;
      }
    }
  }
  .lockerNum, .lockerName{
    @include text-over(82px)
  }
  .locker_free{
    width: 87px;
    height: 83px;
    background-color: #ffffff;
    border: 1px solid #E9E9E9;
  }
  .locker_occupied{
    background-color: #E63B41;
    >span{
      color: #ffffff;
    }
  }
  .locker_overtime{
    background-color: #F38F27;
    >span{
      color: #ffffff;
    }
  }
  .locker_cleanup{
    background-color: #2ea1f8;
    >span{
      color: #ffffff;
    }
  }
  .locker_disabled{
    background-color: #717171;
    >span{
      color: #ffffff;
    }
  }
}

.locker_popup{
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: #ffffff;
  padding-top: 10px;
  .popup_title {
    margin-left: 21px;
    font-size:16px;
    font-weight:600;
    color:rgba(53,64,82,1);
    line-height:26px;
  }
  .popup_user {
    margin-left: 21px;
    font-size:12px;
    font-weight:500;
    color:rgba(39,155,243,1);
    line-height:23px;
  }
  .popup_content {
    margin-left: 21px;
    font-size:12px;
    font-weight:500;
    color:rgba(127,143,164,1);
    line-height:23px;
  }
  .popup_bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 15px;
    margin-top: 10px;
    height:70px;
    background:rgba(240,243,248,1);
    border-top: 1px solid rgba(223,226,229,1);
  }
}

.popup_root {
  :global {
    .ant-popover-inner-content {
      padding: 0;
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

.lockerCategory{
    width: 342px;
    padding: 12px 15px;
    border: 1px solid #DFE2E5;
    border-radius: 4px;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 20px;
    &:nth-child(3n){
        margin-right: 0
    }
    .categoryTitle{
        color: #354052;
        font-size: 16px;
        font-weight: 900;
        margin-bottom: 10px;
    }
    .categorySize{
        color: #7F8FA4;
        font-size: 12px;
        margin-bottom: 8px;
    }
    .categoryPrice{
        color: #FF5157;
        font-size: 14px;
        flex: 1;
    }
    i{
        color: #C8D2DF;
        margin-left: 20px;
        cursor: pointer;
        &:hover{
            color: #2ea1f8;
        }
    }
    &:hover{
        background-color: #FAFBFC;
    }
}

.light-box {
  width: 130px;
  height: 186px;
  box-shadow: rgba(60, 70, 83, 0.04) 2px 2px 5px 0px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // position: relative;
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(230, 231, 239, 0.85);
  border-image: initial;
  overflow: hidden;
  // margin: 6px 6px 6px 0px;
}

.light-status {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  // background: linear-gradient(rgb(100, 238, 108) 0%, rgb(23, 176, 29) 100%);
}

.light-status-255{
  background: linear-gradient(rgb(100, 238, 108) 0%, rgb(23, 176, 29) 100%);
}

.light-status-0{
  background: linear-gradient(rgb(250, 120, 102) 0%, rgb(244, 67, 54) 100%);
}

.light-status-offline{
  background: linear-gradient(rgb(179, 179, 179) 0%, rgb(90, 90, 90) 100%);
}

.light-heartbeat {
  display: flex;
  align-items: center;
  width: 130px;
  height: 35px;
  color: rgb(255, 255, 255);
  padding: 0px 13px;
  background: rgb(234, 234, 241);
}

.light-name {
  height: 15px;
  font-size: 14px;
  font-weight: bold;
  color: #373857;
  line-height: 15px;
  margin-bottom: 8px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.assetable-name {
  height: 18px;
  font-size: 12px;
  color: #373857;
  line-height: 18px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}