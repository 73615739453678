.crm-new-opp-progress-container {
  width: 100%;
  height: 4px;
  position: relative;
  background-color: #eef5fd;
  .crm-new-opp-progress-bar {
    width: 0%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #2ea1f8;
  }
}

.add-recommend-form {
  &>div {
    width: 80%;
  }
}

.opp-status-container {
  margin-bottom: 20px;
  .opp-status {
    margin-bottom: 20px;
    .single-status {
      display: inline-block;
      width: 135px;
      height: 70px;
      background: rgba(255,255,255,1);
      border-radius: 4px;
      border: 1px solid #DFE4E7;
      text-align: center;
      padding: 10px;
      font-size: 16px;
      color: #BDCBD3;
      >span {
        display: block;
        margin-top: 5px;
      }
    }
    .status-active {
      color: #fff;
      background-color: #45d29e;
      border-color: #45d29e;
    }
    .index-2 {
      margin-right: 95px;
    }
    .index-3,.index-4,.index-5 {
      >span {
		  
      }
    }
    .index-1, .index-3, .index-4 {
      border-right: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .index-2, .index-4, .index-5 {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  .change-status-btn {
    border-radius: 4px;
    padding: 13px 35px;
    font-size: 16px;
  }
}

.crm-requirement-square {
  background: #f6f8f9;
  padding: 10px;
  font-size: 13px;
  margin-bottom: 10px;
  .kb-row {
    margin: 10px;
    .title {
      font-size: 15px;
    }
    .kb-form-group>label {
      margin: 0;
    }
  }
}

.intent-areas-presentation {
  width: 48%;
  float: left;
  margin-right: 2%;
}

.crm-opp-info fieldset .kb-row {
  margin-bottom: 15px;
}

.recommended-offices {
  margin-top: 30px;
  .form-content {
    padding: 15px;
    position: relative;
    .kb-row {
      margin-bottom: 10px;
      .group-content {
        line-height: normal;
      }
    }
    .icon-delete {
      display: none;
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      &:hover {
        color: #FF5656;
      }
    }
    &:hover {
      .icon-delete {
        display: inline-block;
      }
    }
  }
}

.crm-opps-with-same-phone-number {
  width: 100%;
  float: left;
  padding-bottom: 95px;
  .tip-msg {
    width: 100%;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 30px;
    background-color: #FCEBEB;
    >img {
      width: 20px;
    }
    >span {
      color: #e13b3f;
      margin-left: 10px;
    }
  }
  .opps-container {
    width: 100%;
    float: left;
    padding: 48px;
    .opp-block {
      float: left;
      display: inline-block;
      width: 217px;
      height: 130px;
      background: rgba(246,248,249,1);
      border-radius: 3px;
      border: 1px solid #DFE4E7;
      padding: 10px;
      margin: 0 20px 20px 0;
      >div {
        display: flex;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        .a-link {
          display: inline-block;
          width: 126px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        >span {
          font-size:14px;
          display: inline-block;
          &:first-child {
            width: 56px;
            text-align: right;
            color: #999999;
            margin-right: 10px;
          }
          &:last-child {
            text-align: left;
            color: #333333;
          }
        }
      }
      .continue-add-new {
        display: block;
        text-align: center;
        width: 100%;
        margin: 20px 0;
        >.add-icon-con {
          display: inline-block;
          width: 42px;
          height: 42px;
          border: 1px dashed #B8C3CB;
          color: rgba(184,195,203,1);
          position: relative;
          cursor: pointer;
          .icon-add {
            font-size: 20px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          &:hover {
            border: 1px solid #2ea1f8;
            color: #2ea1f8;
          }
        }
      }
      .continue-add-msg {
        display: block;
        width: 100%;
        text-align: center;
        color: rgba(184,195,203,1);
      }
    }
  }
}
