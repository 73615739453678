//nav员工信息详细列表
.desk-1-ul{
  max-height: 520px;
  //min-height: 346px;
  overflow-y: auto;
  padding-bottom: 30px;
  li{
    overflow: hidden;
    padding: 10px;
    cursor: pointer;
    &:hover{
      background-color: $kb-color41;
      .desk-user-close{
        opacity: 1;
      }
    }
    .very_strong {
      background-color: #D84848;
    }
    .ordinary {
      background-color: #78C832;
    }
    .not_strong {
      background-color: #F5A623;
    }
    .not_clear {
      background-color: #E1E1E1;
      color: #999999;
    }
  }
}
.desk-user-img{
  width: 30px;
  height: 30px;
  float: left;
  position: relative;
  img{
    @include img;
    @include radius(50%);
  }
  i{
    width: 10px;
    height: 10px;
    background-color: $color12;
    @include radius(50%);
    position: absolute;
    bottom: 0;
    right: 3px;
  }
}
.desk-user-message{
  float: left;
  max-width: 90px;
  padding-left: 10px;
  line-height: 30px;
  .desk-user-name{
    max-width: 62px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    span:nth-of-type(1){
      font-size: 14px;
      color: #666666;
      margin-right: 8px;
    }
    span:nth-of-type(2){
      font-size: 12px;
      color: #999999;
    }
  }
  .desk-user-tel{
    font-size: 12px;
    color: #999999;
  }
}
.desk-user-email{
  font-size: 24px;
  color: #999999;
  float: right;
  cursor: pointer;
  //margin-top: 5px;
}
.desk-user-close{
  opacity: 0;
  font-size: 12px;
  float: right;
  cursor: pointer;
  margin-top: 8px;
  margin-right: 5px;
}
.repairs-principal{
  float: right;
  font-size: 12px;
  color: #ffffff;
  margin-top: 3px;
  padding: 3px 8px;
  background-color: #9288E1;
}
.intent-area-desire {
  float: right;
  font-size: 10px;
  color: #ffffff;
  margin-top: 3px;
  margin-right: 10px;
  border: 0;
  padding: 3px 3px 5px 3px;
  text-align: center;
}
.add-staff{
  padding: 20px 30px;
}
.select-principal{
  margin-right: 10px;
  float: left;
  margin-top: 5px;
  a{
    display: block;
    width: 18px;
    height: 18px;
    @include radius(50%);
    border: 1px solid #e6e6e6;
  }
}
.principal-select{
  a{
    border: none;
    background: url("#{$dev-url}/checkbox-active.jpg") no-repeat;
  }
}

.desk-2-ul{
  max-height: 520px;
  //min-height: 346px;
  overflow-y: auto;
  padding: 20px 0;
  li{
    >a{
      display: block;
      padding: 10px;
      @include clear_fix;
      position: relative;
    }
    cursor: pointer;
    position: relative;
    &:hover{
      background-color: $kb-color41;
      //.desk-user-name-2{
      //  span{
      //    color: #ffffff;
      //  }
      //}
    }
    .desk-user-name-2{
      position: absolute;
      top: 50%;
      @include transform-translateY(-50%);
      span{
        //color: #ffffff;
        &:nth-child(1){
          max-width: 70px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.desk-user-message-2{
  overflow: hidden;
}
.desk-user-img-2{
  @include clear_fix;
  width: 30px;
  height: 30px;
  float: left;
  margin-right: 10px;
  img{
    @include img;
    @include radius(50%);
  }
}
.desk-user-name-2{
  float: left;
  @include clear_fix;
  span{
    display: block;
    &:nth-child(1){
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:nth-child(2){
      font-size: 12px;
      color: #999999;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

}
.desk-user-tel-2{
  position: absolute;
  right: 30px;
  top: 50%;
  @include transform-translateY(-50%);
  font-size: 12px;
  color: #ffffff;
  padding: 2px 6px;
  background-color: #eeb957;
}
.desk-user-icon{
  max-width: 120px !important;
  i{
    font-size: 14px;
    color: #f9d83b;
  }
}
//crm task-list
.desk-3-ul{
  max-height: 520px;
  //min-height: 346px;
  overflow-y: auto;
  padding: 20px 0;
  li{
    >a{
      @include clear_fix;
      display: block;
      padding: 10px 0;
      cursor: pointer;
      &:hover{
        background-color: $kb-color41;
        //.task-content{
        // span{
        //   color: #ffffff;
        // }
        //}
      }
    }

    i {
      margin-right: 8px;
    }
  }
}
.task-status{
  i{
    display: inline-block;
    @include transform-translateY(2px);
  }
  span{
    font-size: 12px;
    color: #999999;
    margin-right: 4px;
    word-break: break-all;
  }
}
.task-content{
  margin-top: 3px;
  span{
    display: block;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    color: #666666;
  }
}
