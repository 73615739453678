html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0;
  //-webkit-font-smoothing: antialiased;
}

html,
body
{
  width: 100%;
  height: 100%;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

*:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}
ul,ol,li{
  list-style: none;
}
ul{
  margin: 0;
  padding: 0;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline
}

audio:not([controls]) {
  display: none;
  height: 0
}

[hidden], template {
  display: none
}

b, strong {
  font-weight: bold
}

dfn {
  font-style: italic
}

h1 {
  font-size: 2em;
  margin: 0.67em 0
}

mark {
  background: #ff0;
  color: #000
}

small {
  font-size: 80%
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  top: -0.5em
}

sub {
  bottom: -0.25em
}

img {
  border: 0
}

svg:not(:root) {
  overflow: hidden
}

figure {
  margin: 1em 40px
}

hr {
  box-sizing: content-box;
  height: 0
}

pre {
  overflow: auto
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
  outline: medium;
  padding: 0;
}
textarea{
  resize: none;
}
button {
  overflow: visible
}

button, select {
  text-transform: none
}

select::-ms-expand { display: none; }

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer
}

button[disabled], html input[disabled] {
  cursor: default
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0
}

input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  height: auto
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em
}

legend {
  border: 0;
  padding: 0
}

textarea {
  overflow: auto
}

optgroup {
  font-weight: bold
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

td, th {
  padding: 0
}

a {
  color: #333333;
  text-decoration: none;
}

//a:visited {
//  color: #4D9ACC
//}
//
//a:focus {
//  color: #4D9ACC
//}
//
//a:hover {
//  color: #4D9ACC
//}
//
//a:active {
//  color: #4D9ACC
//}

html {
  font-size: 14px;
  -webkit-tap-highlight-color: transparent
}

body {
  font-family: 'PingFang SC', 'kuban', 'Lantinghei SC', 'Open Sans', Arial, 'Hiragino Sans GB', 'Microsoft YaHei', 微软雅黑, STHeiti, 'WenQuanYi Micro Hei', SimSun, sans-serif;;
  font-size: 14px;
  line-height: 1.42857;
  color: #333333;
  background-color: #fff
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
}

h1, .h1 {
  font-size: 36px
}

h2, .h2 {
  font-size: 30px;
  margin: 0;
}

h3, .h3 {
  font-size: 24px
}

h4, .h4 {
  font-size: 18px;
  margin: 0;
}

h5, .h5 {
  font-size: 14px
}

h6, .h6 {
  font-size: 12px
}

p {
  margin: 0 ;
}

.vertical-middle {
  vertical-align: middle;
}

.iconfont{
  margin-right: 4px;
  font-size: 14px;
}

@include make-grid-columns;

@media (max-width: $screen-xs) {
  @include make-grid(xs);
}

//// Small grid
@media (min-width: $screen-xs + 1) and (max-width: $screen-sm) {
  @include make-grid(sm);
}

//// Medium grid
//@media (min-width: $screen-md-min) {
//  @include make-grid(md);
//}

// Medium grid
@media (min-width: $screen-sm + 1) {
  @include make-grid(md);
}

//// Large grid
@media (min-width: $screen-lg-min) {
  @include make-grid(lg);
}

@include make-grid(al);
