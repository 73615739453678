.member-number-chart{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 70px;
}
@mixin commonBj(){
  width: 52px;
  height: 42px;
  margin-bottom: 20px;
  background-size: contain;
}
.member-navigation{
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  >a{
    width: 230px;
    height: 200px;
    border: 1px solid #E5E5E5;
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-right: 50px;
    overflow: hidden;
    &:nth-child(4n){
      margin-right: 0;
    }
    &:hover{
      border: 1px solid #2ea1f8;
      color: #2ea1f8;
    }
    .title{
      font-size: 20px;
    }

  }
  .member{
    .member-bj{
      background: url("#{$dev-url}membershipMember.png") no-repeat;
      @include commonBj;
    }
  }
  .member_special{
    .member-bj{
      background: url("#{$dev-url}membershipMember.png") no-repeat;
      @include commonBj;
    }
  }
  .card{
    .card-bj{
      background: url("#{$dev-url}membershipCard.png") no-repeat;
      @include commonBj;
    }
  }
  .resource{
    .resource-bj{
      background: url("#{$dev-url}membershipResource.png") no-repeat;
      @include commonBj;
    }
  }
  .coupon{
    .coupon-bj{
      background: url("#{$dev-url}membershipCoupon.png") no-repeat;
      @include commonBj;
    }
  }
  .announcement{
    .announcement-bj{
      background: url("#{$dev-url}membershipAnnouncement.png") no-repeat;
      @include commonBj;
    }
  }
  .order{
    .order-bj{
      background: url("#{$dev-url}memberShipOrder.png") no-repeat;
      @include commonBj;
    }
  }
  .message{
    .message-bj{
      background: url("#{$dev-url}membershipMessage.png") no-repeat;
      @include commonBj;
    }
  }
  .detail{
    .detail-bj{
      background: url("#{$dev-url}membershipDetail.png") no-repeat;
      @include commonBj;
    }
  }
}
.member-card{
  margin-bottom: 50px;
  border: 1px solid #AABAC6;
  .disable {
    opacity: 0.5;
  }
  .title{
    display: flex;
    justify-content: space-between;
    background-color: #D4D9DF;
    padding: 10px 30px;
    .grade{
      font-size: 18px;
      margin-right: 15px;
      color: #666;
    }
    .memberships-count{
      font-size: 12px;
      color: #666666;
    }
  }
  .member-card_content{
    padding: 30px;
    .card-title{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .line{
        height: 14px;
        width: 1px;
        background-color: $kb-color3;
        margin-left: 20px;
        margin-right: 20px;
      }
    }
    .source-content{
      @include clear_fix;
      >div{
        width: 17%;
        padding: 10px 0;
        float: left;
        text-align: left;
        > .content-title {
          color: #999;
          width: 100%;
        }
        > .numbers {
          width: 100%;
          font-size: 40px;
          color: #4C4C4C;
        }
        &:last-child {
          width: 32%;
          margin-right: 0;
          float: right;
        }
      }
    }
  }
}
.membership-btn{
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.membership-card-list{
  >div{
    padding: 5px 0;
    >span:first-child{
      display: inline-block;
      width: 160px;
    }
    >span:last-child{
      color: #999999;
      margin-left: 10px;
    }
  }
}
.member-resource-content{
  width: 260px;
  padding: 20px;
  margin-bottom: 20px;
  float: left;
  margin-right: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  text-align: center;
  &:hover{
    border: 1px solid #2ea1f8;
  }
  &{
    margin-right: 20px;
  }
  &:nth-child(3n){
    margin-right: 0;
  }
  .icon-delete{
    position: absolute;
    top: 10px;
    right: 10px;
    &:hover{
      color: #2ea1f8;
    }
  }
  .title{
    span{
      font-size: 20px;
    }
  }
  .resource-message{
    padding: 15px 0;
    font-size: 12px;
    .price{
      color: #fc2b48;
      padding-right: 12px;
      border-right: 1px solid #d8d8d8;
    }
    .type{
      padding-left: 12px;
    }
  }
  .resource-date{
    font-size: 10px;
  }
}

.member-percentage{
  display: flex;
  align-items: center;
  .title{
    font-size: 20px;
    margin-bottom: 20px;
  }
}
.member-type{
  >div{
    margin-bottom: 10px;
    color: #666666;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .type-name{
    >i{
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #000000;
      margin-right: 10px;
    }
  }
}
.member-chart{
  width: 40%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .member-type-num{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    >span:first-child{
      font-size: 48px;
      margin-bottom: 10px;
    }
    >span:last-child{
      color: #666666;
    }
  }
}
.more-btn{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #2ea1f8;
  >img{
    width: 32px;
  }
}

.membership-loading-container{
  height: 100%;
  width: 881px;
  float: right;
  position: relative;
}

.memberships-res-types {
  float: left;
  .res-type {
    float: left;
    padding: 9px 40px 8px;
    border: 1px solid #e5e5e5;
    border-right: 0;
    color: #666;
    cursor: pointer;
    &:last-child {
      border-right: 1px solid #e5e5e5;
    }
  }
  .res-type-active {
    background-color: #2ea1f8;
    color: #fff;
    border-color: #2ea1f8;
  }
}
