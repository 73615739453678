.user-message{
  padding: 24px 30px;
  @include clear_fix;
  >img{
    width: 44px;
    height: 44px;
    @include  radius(50%);
    float: left;
  }
}
.user-name-tel{
  float: left;
  margin-left: 20px;
  >span{
    margin-bottom: 4px;
    font-size: 18px;
    max-width: 98px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    &:nth-child(2){
      font-size: 14px;
    }
  }
  >div{
    span:last-child{
      margin-left: 5px;
      max-width: 42px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: top;
      font-weight: 500;
    }
  }
}
.upload-portrait{
  //margin-bottom: 20px;
  img{
    vertical-align: middle;
    width: 64px;
    height: 64px;
    margin-right: 12px;
  }
  .imgRadius{
    @include radius(50%);
  }
  .imgOrthogonal{
    @include radius(0);
  }
}
.portrait-title{
  font-size: 14px;
  color: #999999;
}
.personal-form{
  width: 620px;
  input,select{
    width: 400px;
  }
}
//账号密码
.title-box{
  padding: 30px 30px 0px 30px;
}
.personal-email{
  line-height: 36px;
  font-size: 14px;
  color: #666666;
}
.change-email{
  float: right;
  margin-right: 96px !important;
}
.verify-box{
  margin-right: 26px;
  input{
    width: 258px;
  }
}
