.faq-box{
  margin-top: 30px;
}
.faq-title{
  margin-left: 0px;
  margin-bottom: 15px;
  span{
    font-size: 18px;
    color: #666666;
    display: inline-block;
    padding-right: 30px;
    padding-bottom: 15px;
    border-bottom: 1px solid #CCCCCC;
  }
  .icon-navigate_next,.icon-arrow_drop_down_circle,.icon-edit,.icon-delete{
    cursor: pointer;
    &:hover{
      color: #5fa7ec;
    }
  }
  .faq-title-content{
    cursor: pointer;
    font-size: 22px;
    .icon-delete{
      margin-left: 5px;
      font-size: 14px;
    }
    .icon-edit{
      margin-left: 30px;
      font-size: 14px;
    }
  }
}
.faq-list{
  .faq-list-content{
    padding: 15px 30px 15px 30px;
    cursor: pointer;
    .title{
      font-size: 18px;
      vertical-align: middle;
    }
    &:hover{
      background-color: #f4f6f7;
      .icon-delete,.icon-navigate_next,.icon-edit{
        display: inline-block;
        &:hover{
          color: #5fa7ec;
        }
      }
    }
    .icon-delete,.icon-edit{
      float: right;
      margin-right: 15px;
      margin-top: 5px;
      display: none;
    }
    .icon-navigate_next{
      float: right;
      @include transform-rotateZ(-90deg);
      display: none;
    }
  }
}
.item-header{
  border-bottom: 1px solid $kb-color3;
  padding-bottom: 10px;
  @include clear_fix;
  input{
    border: none;
    outline: medium;
    text-indent: 28px;
    width: 400px;
    color: #999;
  }
  .item-time{
    float: right;
    color: #666666;
  }
}
.text-format{
  background-color: #f9f9f9;
  padding-left: 24px;
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid $kb-color3;
  span{
    display: inline-block;
    width: 22px;
    height: 22px;
    margin-right: 10px;
    text-align: center;
    line-height: 24px;
    cursor: pointer;
    i{
      margin-right: 0;
    }
  }
  .active{
    background-color: #dadada;
  }
}
.item-text{
  margin-top: 20px;
  textarea{
    padding: 0 30px;
    height: 200px;
    width: 100%;
    border: none;
  }
}
.item-option{
  padding: 15px 0;
  border-top: 1px solid $kb-color3;
  border-bottom: 1px solid $kb-color3;
  margin-top: 15px;
  @include d-flex;
  align-items: center;
  @include clear_fix;
  .title{

  }
}
.user-link{
  padding-bottom: 10px;
  @include clear_fix;
  .title{
    float: left;
    color: #333333;
  }
  textarea{
    outline: medium;
    float: left;
    margin-left: 30px;
    padding: 10px;
    width: calc(100% - 58px);
    height: 78px;
    border: 1px solid $kb-color3;
    color: #999999;
  }
}
.select-way{
  color: #999;
  margin-top: 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid $kb-color3;
}
.edit-way{
  display: inline-block;
  margin-left: 30px;
  span,a{
    display: inline-block;
    padding: 5px 8px;
    border: solid 1px #2ea1f8;
    cursor: pointer;
	color: #2ea1f8;
	&:first-child {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
    &:last-child{
	  border-left: none;
	  border-top-right-radius: 4px;
	  border-bottom-right-radius: 4px;
    }
    &:hover{
      background-color: #2ea1f8;
      color: #ffffff;
    }
  }
  .active{
    background-color: #2ea1f8;
    color: #ffffff;
  }
  input{
    border: none;
    width: 90px;
  }
}

.announcement-img{
  img{
    display: block;
    width: 120px;
    height: 68px;
  }
}
