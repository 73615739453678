.news-container{
  border-bottom: 1px solid $kb-color3;
  padding: 20px 0;
  display: flex;
  position: relative;
  >img{
    margin-right: 30px;
    width: 340px;
    height: 200px;
  }
  .news-content {
    width: calc(100% - 370px);
  }
  .news-status{
    display: inline-block;
    padding: 0 6px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    border-radius: 2px;
    margin-left: 15px;
    font-weight: normal;
    color: #ffffff;
    white-space: nowrap;
  }
  .news-status_published{
    background-color: #02C862;
  }
  .news-status_deleted{
    background-color: #ffba00;
  }
  .news-status_soon{
    background-color: #9b9b9b;
  }
  .news-status_draft{
    background-color: #ffba00;
  }
  &:last-child{
    border-bottom: none;
  }
}

.news-title{
  font-size: 20px;
  font-weight: 600;
  color: #404040;
  margin-top: 15px;
  margin-bottom: 20px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  &:hover{
    color: #2ea1f8;
  }
}
.news-date{
  font-size: 16px;
  margin-right: 10px;
  vertical-align: middle;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.8rem;
  height: 5.4rem;
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 5px;
    background-color: #fff;
    width: 50.6%;
  }
  * {
    margin: 0;
    padding: 0;
  }
  img {
    display: none;
  }
  h1, h2, h3, h4, h5, h6 {
    font-size: 18px;
    font-weight: normal;
  }
}
.news-date-status{
  margin-bottom: 10px;
  color: #646464;
}

.news-time-and-viewcounts {
  display: inline-block;
  color: #969696;
  position: absolute;
  bottom: 30px;
  .news-created-time {
    margin-right: 30px;
  }
  i {
    margin-right: 10px;
  }
}

.news-content {
  img {
    max-width: 100%;
  }
}

.news-detail-container {
  padding: 50px 208px 0;
  .news-image {
      background-position: center;
      background-size: cover;
      height: 400px;
  }
  .news-detail {
    .news-time-and-viewcounts {
      display: block;
      margin-top: 10px;
      position: static;
    }
    .news-body {
      padding-top: 20px;
      .news-content {
        padding: 20px 0;
        p {
          font-size: 18px;
          color: #646464;
        }
        figure {
          margin: 20px 0;
        }
      }
    }
  }
}

.news-tag{
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding: 0 30px;
  >span{
    margin-right: 15px;
  }
  .tag-list{
    >span{
      display: inline-block;
      padding: 5px 10px;
      border-radius: 2px;
      color: #999999;
      border: 1px solid $kb-color3;
      text-align: center;
      margin-right: 15px;
      cursor: pointer;
      &:hover{
        background-color: #2ea1f8;
        color: #ffffff;
        border-color: #2ea1f8;
      }
    }
  }
  .tag-active{
    background-color: #2ea1f8 !important;
    color: #ffffff !important;
    border-color: #2ea1f8 !important;
  }
}

.news-header{
  .news-status{
    display: inline-block;
    padding: 0 6px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    border-radius: 2px;
    margin-left: 15px;
    font-weight: normal;
    color: #ffffff;
    white-space: nowrap;
  }
  .news-status_published{
    background-color: #02C862;
  }
  .news-status_deleted{
    background-color: #ffba00;
  }
  .news-status_soon{
    background-color: #9b9b9b;
  }
  .news-status_draft{
    background-color: #ffba00;
  }
  &:last-child{
    border-bottom: none;
  }
}


.news-chart{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 150px;
  border-bottom: 1px solid $kb-color3;
}

.news-space_between {
  margin-bottom: 10px;
  color: #969696;
}

.news-form_date{
 .task-deg{
   float: right;
   margin-top: 6px;
 }
}

.news-stars{
  display: flex;
  align-items: center;
  >span{
    margin-right: 5px;
    color: #d8d8d8;
  }
  .star_active{
    color: #FFBA00;
  }
}
