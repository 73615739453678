
.visit-setting-block {
    padding: 24px;
    border: 1px solid #dcdcdc;
    margin-bottom: 24px;
    float: left;
    width: 100%;
    position: relative;
    .title-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 30px;
        .title-and-des {
            text-align: left;
            .title {
                font-size: 16px;
                color: #333;
            }
            .des {
                margin-top: 10px;
                font-size: 12px;
                color: #666666;
            }
        }
    }
    .delete-opt {
        margin-left: 30px;
        cursor: pointer;
        &:hover {
            color: #55CE6B;
        }
    }
}

.visit-type-block {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .type-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f9f9f9;
        border: 1px solid #dbdbdb;
        padding: 15px 24px;
        text-align: left;
        .name {
            color: #333;
            margin-bottom: 5px;
        }
    }
    .type-sort {
        display: flex;
        justify-content: space-between;
        width: 100px;
        padding: 0 24px;
        i {
            color: #333;
            cursor: pointer;
            &:hover {
                color: #55CE6B;
            }
        }
        .arrow-up {
            transform: rotate(180deg);
        }
    }

    .field_options {
        padding-left: 50px;
        i {
            cursor: pointer;
            &:hover {
                color: #55CE6B;
            }
        }
    }

    .tip-msg {
        width: 100%;
        padding: 15px 15px 15px 64px;
        position: relative;
        border-radius: 4px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 1.5;
        border: 1px solid #91d5ff;
        background-color: #e6f7ff;
        .icon-hint {
            color: #1890ff;
            position: absolute;
            top: 16px;
            left: 24px;
            font-size: 24px;
        }
        .title {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            display: block;
            margin-bottom: 4px;
        }
        .text {
            font-size: 14px;
            line-height: 22px;
        }
    }
}

.template-img {
    max-width: 520px;
}

.flex-align-ends-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.visit-pad-add-tip {
    width: 280px;
    padding: 20px;
    text-align: left;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.65);
    border: 1px solid #91d5ff;
    background-color: #e6f7ff;
    float: right;
    .icon {
        font-size: 20px;
        color: #1890ff;
    }
    .msg {
        font-size: 14px;
        margin-top: 15px;
        margin-bottom: 25px;
    }
}

.six-digit-input {
    height: 60px !important;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 15px;
    border-radius: 5px;
}

.face-img-in-table {
	display: inline-block;
	width: 80px;
	height: 80px;
	border: 1px solid #eee;
	border-radius: 4px;
	&:hover {
		cursor: zoom-in;
	}
}

.face-img-in-detail {
  display: block;
  overflow: hidden;
	width: 250px;
	height: 250px;
	border: 1px solid #eee;
	border-radius: 5px;
	&:hover {
		cursor: zoom-in;
	}
}
